import React, { Component }                         from 'react';
import PT                                           from 'prop-types';
import cx                                           from 'classnames';
import { connect }                                  from 'react-redux';
import _                                            from 'underscore';
import deep                                         from 'deep-diff';
import { withRouter }                               from 'react-router';
import { fetchEducator, editEducator, addEducator } from 'modules/educators/actions';
import { fetchUnits }                               from 'modules/units/actions';
import { fetchProject }                             from 'modules/projects/actions';
import { fetchFiles }                               from 'modules/files/actions';
import { showToast }                                from 'theme/actions';
import Header                                       from 'theme/Header';
import Navbar                                       from 'theme/Navbar';
import Footer                                       from 'theme/Footer';


import 'theme/common-views.sass';

import EducatorForm                                 from './EducatorForm'


class EducatorDetails extends Component {

    componentDidMount() {
      const {projectId, educatorId} = this.props.match.params;
      if(projectId && educatorId) {
        this.props.fetchEducator(projectId, educatorId);
      }
      this.props.fetchUnits(projectId);
    }

    render() {
      const {isFetching, editEducator, addEducator, showToast, match, educator, user, units, activeProject} = this.props;
      const {projectId, educatorId} = match.params;
      const edit = educatorId != null;
      const initialForm = activeProject.description ? {from: activeProject.description.from, to: activeProject.description.to} : null;


      const submit = (values) => {
        const diff = edit && _.pluck(deep.diff(educator, values), 'path');
        const vals = diff ? _.pick(values, ..._.map(diff, _.first)) : values;
        const func = edit ? editEducator : addEducator;
        return func({projectId, id: educatorId}, vals)
        .then(() => {
          const message = <div>Zapisano wychowawcę</div>
          showToast({message});
        })
        .catch(() => {
          const message = (
            <div>
              Nie udało się zapisać wychowawcy. Spróbuj ponownie później
            </div>
          )
          showToast({message, error: true});
        })
      }

      return (
        <div className="Educator">
          <Header />
          <Navbar />
          <div className={cx("Details__container", {blur: isFetching})}>

            <div className="Details__heading">
              <h3>Wychowawca</h3>
              <p>
                {edit
                  ? 'Edycja wychowawcy'
                  : 'Nowy wychowawca'
                }
              </p>
            </div>

            <EducatorForm
              initialValues={edit ? educator : initialForm}
              onSubmit={submit}
              loading={isFetching}
              user={user}
              units={units}
              disabled={activeProject.state != 'Open'}
            />
          </div>
          <Footer />
        </div>
      )
    }
}

EducatorDetails.propTypes = {
  educator: PT.shape({
    id: PT.string
  }),
  fetchEducator: PT.func,
  editEducator: PT.func,
  addEducator: PT.func,
  showToast: PT.func.isRequired
}

const mapStateToProps = ({data: {educator, files, units, activeProject}, isFetching, auth: {user}}) => ({
  educator,
  files,
  isFetching: isFetching.global,
  user,
  units,
  activeProject: activeProject || {}
})

export default withRouter(connect(
  mapStateToProps,
  { fetchEducator, editEducator, addEducator, showToast, fetchFiles, fetchUnits, fetchProject }
)(EducatorDetails))
