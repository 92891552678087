import _ from 'underscore';
import * as types from 'types';
import auth from 'modules/auth/reducers';
import users from 'modules/users/reducers';
import participants from 'modules/participants/reducers';
import * as theme from 'theme/reducers';


const data = (state = {}, action) => {
  switch(action.type) {
    case types.REQUEST_SUCCESS:
      if(action.data)
        return {
          ...state,
          [action.name]: action.data,
        }
      else
        return state;
    case types.CREATE_REQUEST:
      return state;
    case types.REQUEST_FAILURE:
    if(action.error) {
      return {
        ...state,
        error: action.error,
        message: true
      } }
      else {
        return state;
      }
    case types.CLOSE_MESSAGE:
      return {
        ...state,
        error: false,
        message: false
      }
    case types.PAGE_CLEANUP:
      return {};
    default:
      return state;
  }
}

const isFetching = (
  state = {
    global: false, //is page loading
    names: [],
    name: null,
    ids: [],
    objects: {}
  },
  action
) => {
  const {type, name, global, id, ...rest} = action;
  switch (type) {
    case types.CREATE_REQUEST:
      return {
        ...state,
        global: !id && name ? true : global == undefined ? (!id && !name) : global,
        name: !id && name,
        names: state.names ? _.uniq([...state.names, name]) : [name],
        ids: id ? [...state.ids, id] : state.ids,
        objects: id ? {
          ...state.objects,
          [id]: {name, ...rest}
        } : state.objects
      }
    case types.REQUEST_SUCCESS:
    case types.REQUEST_FAILURE:
      return {
        ...state,
        global: state.global ? !state.global : state.global,
        names: _.without(state.names, name),
        ids: state.ids && state.ids.filter(i => i != id) || [],
        objects: _.omit(state.objects, id)
      }
    default:
      return state;
  }
};

const noNetwork = (state = false, action) => {
  switch (action.type) {
    case types.NO_CONNECTION:
      return true;
    case types.CONNECTED:
      return false;
    default:
      return state;
  }
};

// Combine reducers with routerReducer which keeps track of
// router state
export default {
  isFetching,
  auth,
  data,
  users,
  participants,
  noNetwork,
  ...theme
}
