import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { showToast } from 'actions';
import Loader from 'modules/theme/Loader';


export default connect(null, {showToast})(
  class extends React.Component {
    constructor ({checked}) {
      super();
      this.state = {checked, loading: false};
      this.change = this.change.bind(this);
    }

    change (value) {
      const {onChange, name, showToast} = this.props;
      this.setState({checked: value, loading: true});
      return onChange({[name]: value})
      .then(() => this.setState({loading: false}))
      .catch(() => {
        showToast({message: <div>Nie można zapisać pola. Spróbuj ponownie później</div>, error: true});
        this.setState({checked: null, loading: false});
      })
    }


    render () {
      const {options, disabled} = this.props;
      const checked = this.props.checked != this.state.checked ? this.state.checked : this.props.checked;
      return (
        <div className="Accounting__radio">
          {options.map(({value, label}) => (
            <button
              key={value}
              className={cx({checked: checked == value})}
              onClick={() => this.change(value)}
              disabled={disabled || this.state.loading || checked == value}
            >
              <span>{label}</span>
            </button>
          ))}
          <Loader loading={this.state.loading} />
        </div>
      )
    }
  }
)
