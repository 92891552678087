import * as auth from 'modules/auth/actions';
import * as projects from 'modules/projects/actions';
import * as users from 'modules/users/actions';
import * as commands from 'modules/commands/actions';
import * as places from 'modules/places/actions';
import * as units from 'modules/units/actions';
import * as quartermasters from 'modules/quartermasters/actions';
import * as educators from 'modules/educators/actions';
import * as healthCarers from 'modules/healthCarers/actions';
import * as lifeGuards from 'modules/lifeGuards/actions';
import * as cooks from 'modules/cooks/actions';
import * as drivers from 'modules/drivers/actions';
import * as accounting from 'modules/accounting/actions';
import * as theme from 'theme/actions';

const actions = {
  ...auth,
  ...projects,
  ...places,
  ...units,
  ...theme,
  ...users,
  ...commands,
  ...quartermasters,
  ...educators,
  ...healthCarers,
  ...lifeGuards,
  ...cooks,
  ...drivers,
  ...accounting
};

module.exports = actions;
