import React, { Component } from 'react';
import PT from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import _ from 'underscore';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { fetchCommands, deleteCommand, toggleAcceptCommand, commentCommand } from 'modules/commands/actions';
import { closeModal, showToast } from 'theme/actions';
import Header from 'theme/Header';
import Navbar from 'theme/Navbar';
import Table, { AcceptCommentCell, Row, ActionsCell, openModal, accept, comment } from 'theme/Table';
import Loader from 'theme/Loader';
import Footer from 'theme/Footer';
import Modal from 'theme/Modal';
import DownloadButton from 'theme/DownloadButton';
import roles from 'modules/commands/roles';

import 'theme/common-views.sass';

const rankOptions = {
  Och: 'Ochotniczka / Młodzik',
  Trop: 'Tropicielka / Wywiadowca',
  Sam: 'Samarytanka / Ćwik',
  Wed: 'Wędrowniczka / Harcerz Orli',
  HR: 'Harcerka / Harcerz Rzeczypospoitej'
}

const instructorRankOptions = {
  Pwd: 'Przew.',
  Phm: 'Podharcm.',
  Hm: 'Harcm.',
  None: 'Brak'
}

const certsProps = [
  {key: 'leaderCertificate', label: 'Kurs kierowników'},
  {key: 'internshipCertificate', label: 'Staż wychow.'},
  {key: 'phmInstructorCertificate', label: 'Czynny instruktor ZHR'},
  {key: 'KRKCertificate', label: 'KRK'},
  {key: 'medicalCertificate', label: 'Zaśw. lekarskie'},
  {key: 'DDStatement', label: 'DD'},
  {key: 'ZMStatement', label: 'ZM'}
];

const HEADINGS = [
  {key: 'lastName', label: 'Nazwisko'},
  {key: 'firstName', label: 'Imię'},
  {key: 'from', label: 'Od'},
  {key: 'to', label: 'Do'},
  {key: 'rank', label: 'St. harcerski'},
  {key: 'instructorRank', label: 'St. instruktorski'},
  {key: 'paidDues', label: 'Opłacone składki'},
  ...certsProps,
  {key: 'meta', label: ''}
]


class Commands extends Component {

    componentDidMount() {
      this.props.fetchCommands(this.props.match.params.projectId);
      this.roles = Object.keys(roles)
      .map( key => roles[key].filledBy)
      .reduce( (a, b) => a.concat(b));

      const rolesCount = this.roles
      .reduce( (sum, role) => ({...sum, [role]: (sum[role] || 0) + 1}), {});

      this.deleteRole = Object.keys(rolesCount)
      .reduce( (max, role) => rolesCount[max] >= rolesCount[role] ? max : role);
    }

    render() {
      const {
        isFetching,
        selectedCommand,
        showToast,
        loadingActions,
        closeModal,
        commands,
        toggleAcceptCommand,
        commentCommand,
        user,
        activeProject
      } = this.props;
      const projectId = this.props.match.params.projectId;
      const deleteCommand = (...args) => () => {
        this.props.deleteCommand(...args)
        .then(closeModal)
        .catch(() => {
          const message = (
            <div>
              Nie udało się usunąć komendanta. Spróbuj ponownie później
            </div>
          )
          showToast({message, error: true});
        })
      };

      const editable = activeProject.state == 'Open';

      const canEdit = user.isAdmin || this.roles && this.roles.includes(user.role.name);
      const canDelete = editable && (user.isAdmin || this.deleteRole == user.role.name);

      return (
        <div className="List">
          <Header />
          <Navbar />
          <div className="List__container">

            <div className="List__heading">
              <h3>Komendant</h3>
              {canEdit && editable && user.role.name != 'Office' &&
                <Link
                  to={`/projects/${projectId}/staff/commands/new`}
                  className="List__add"
                >Dodaj</Link>
              }
            </div>


            <div className="List__card">
              <Loader loading={isFetching} />
              <div className={cx("List__cardContainer", {blur: isFetching})}>
                {commands.length == 0
                  ? <h4>Do tego projektu nie dodano jeszcze żadnych komendantów.</h4>
                  :
                  <Table
                    headings={HEADINGS}
                    accept={editable && accept(toggleAcceptCommand, projectId, HEADINGS, _.pluck(certsProps, 'key'))}
                    comment={comment(commentCommand, projectId)}
                    permissions={roles}
                  >
                    {commands && commands.map( command => {
                      const loading = loadingActions[command.id] && ['AcceptCommand', 'DeleteCommand'].includes(loadingActions[command.id].name);
                      return (
                        <Row
                          key={command.id}
                          loading={loading}
                        >
                          <AcceptCommentCell data={command}>
                            {command.lastName}
                          </AcceptCommentCell>
                          <AcceptCommentCell data={command}>
                            {command.firstName}
                          </AcceptCommentCell>
                          <AcceptCommentCell data={command}>
                            {command.from && moment(command.from).format('DD.MM.YYYY')}
                          </AcceptCommentCell>
                          <AcceptCommentCell data={command}>
                            {command.to && moment(command.to).format('DD.MM.YYYY')}
                          </AcceptCommentCell>
                          <AcceptCommentCell data={command}>
                            {rankOptions[command.rank]}
                          </AcceptCommentCell>
                          <AcceptCommentCell data={command}>
                            {instructorRankOptions[command.instructorRank]}
                          </AcceptCommentCell>
                          <AcceptCommentCell className="List__dues" data={command}>
                            <span
                              className={cx(
                                "List__check",
                                {"fa fa-check": command.paidDues}
                              )}
                            />
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={command}
                            commentsPosition="left"
                          >
                            {command.leaderCertificate && command.leaderCertificate.fileId &&
                              <DownloadButton onlyIcon file={command.leaderCertificate} />
                            }
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={command}
                            commentsPosition="left"
                          >
                            {command.internshipCertificate && command.internshipCertificate.fileId &&
                              <DownloadButton onlyIcon file={command.internshipCertificate} />
                            }
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={command}
                            commentsPosition="left"
                          >
                            {command.phmInstructorCertificate && command.phmInstructorCertificate.fileId &&
                              <DownloadButton onlyIcon file={command.phmInstructorCertificate} />
                            }
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={command}
                            commentsPosition="left"
                          >
                            {command.KRKCertificate && command.KRKCertificate.fileId &&
                              <DownloadButton onlyIcon file={command.KRKCertificate} />
                            }
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={command}
                            commentsPosition="left"
                          >
                            {command.medicalCertificate && command.medicalCertificate.fileId &&
                              <DownloadButton onlyIcon file={command.medicalCertificate} />
                            }
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={command}
                            commentsPosition="left"
                          >
                            {command.DDStatement && command.DDStatement.fileId &&
                              <DownloadButton onlyIcon file={command.DDStatement} />
                            }
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={command}
                            commentsPosition="left"
                          >
                            {command.ZMStatement && command.ZMStatement.fileId &&
                              <DownloadButton onlyIcon file={command.ZMStatement} />
                            }
                          </AcceptCommentCell>
                          <ActionsCell
                            data={command}
                            del={canDelete && openModal('deleteCommand')}
                            editPath={`/projects/${projectId}/staff/commands/${command.id}`}
                            acceptable={editable && user.isAdmin}
                          />
                        </Row>
                      )
                    })}
                  </Table>
                }
              </div>
            </div>
          </div>
          <Modal
            className="List__modal"
            header={<h4>Uwaga!</h4>}
          >
            <p>
              Czy na pewno chcesz usunąć komendanta <b>{selectedCommand.firstName} {selectedCommand.lastName}</b>?
            </p>
            <div className="List__modalActions">
              <button
                className="List__modalDiscard"
                onClick={closeModal}
              >Anuluj</button>
              <button
                className="List__modalOk"
                onClick={deleteCommand(projectId, selectedCommand.id)}
              >Usuń</button>
            </div>
          </Modal>
          <Footer />
        </div>
      )
    }
}

Commands.propTypes = {
  commands: PT.arrayOf(PT.shape({
    id: PT.string
  })),
  fetchCommands: PT.func,
  isFetching: PT.bool,
  deleteCommand: PT.func.isRequired,
  closeModal: PT.func.isRequired,
  showToast: PT.func.isRequired
}

const mapStateToProps = ({data: {commands, activeProject}, isFetching, modal, auth}) => ({
  commands: commands || [],
  isFetching: isFetching.global,
  selectedCommand: modal.payload || {},
  loadingActions: isFetching.objects,
  user: auth.user,
  activeProject: activeProject || {}
})

export default withRouter(connect(
  mapStateToProps,
  { fetchCommands, deleteCommand, showToast, closeModal, toggleAcceptCommand, commentCommand }
)(Commands))
