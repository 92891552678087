import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { reduxForm, Field} from 'redux-form';
import Form, { Input, Datepicker, FileInput, Search, Group, SaveButton } from 'theme/Form';
import Loader from 'theme/Loader';
import roles from 'modules/quartermasters/roles';

import 'react-datepicker/dist/react-datepicker.css';

const rankOptions = [
  {label: 'Ochotniczka / Młodzik', value: 'Och'},
  {label: 'Tropicielka / Wywiadowca', value: 'Trop'},
  {label: 'Samarytanka / Ćwik', value: 'Sam'},
  {label: 'Wędrowniczka / Harcerz Orli', value: 'Wed'},
  {label: 'Harcerka / Harcerz Rzeczypospoitej', value: 'HR'}
]

const instructorRankOptions = [
  {label: 'Przewodnik', value: 'Pwd'},
  {label: 'Podharcmistrz', value: 'Phm'},
  {label: 'Harcmistrz', value: 'Hm'},
  {label: 'Brak', value: 'None'}
]


export default withRouter(reduxForm({
  form: 'QuartermasterForm',
  enableReinitialize: true
})(
  ({handleSubmit, pristine, submitting, match, loading, user, disabled}) => {
    const {projectId} = match.params;

    return (
      <Form
        className="Details__form"
        onSubmit={handleSubmit}
      >
        <div className="Details__row">
          <div className="Details__card">
            <Loader loading={loading} />
            <div className={cx("Details__cardContainer", {blur: loading})}>
              <Field
                component={Input}
                name="firstName"
                label="Imię"
                disabled={disabled || !user.isAdmin && !roles.firstName.filledBy.includes(user.role.name)}
              />
              <Field
                component={Input}
                name="lastName"
                label="Nazwisko"
                disabled={disabled || !user.isAdmin && !roles.lastName.filledBy.includes(user.role.name)}
              />
              <Field
                component={Input}
                name="PESEL"
                label="Pesel"
                disabled={disabled || !user.isAdmin && !roles.PESEL.filledBy.includes(user.role.name)}
              />
              <Field
                component={Input}
                name="phone"
                label="Telefon"
                disabled={disabled || !user.isAdmin && !roles.phone.filledBy.includes(user.role.name)}
              />
              <Field
                component={Input}
                name="email"
                label="Email"
                disabled={disabled || !user.isAdmin && !roles.email.filledBy.includes(user.role.name)}
              />
              <h4>Adres zamieszkania</h4>
              <Field
                component={Input}
                name="address"
                label="Ulica i numer domu"
                disabled={disabled || !user.isAdmin && !roles.address.filledBy.includes(user.role.name)}
              />
              <div className="Details__row">
                <Field
                  component={Input}
                  name="zipCode"
                  label="Kod pocztowy"
                  className="Details__code"
                  disabled={disabled || !user.isAdmin && !roles.zipCode.filledBy.includes(user.role.name)}
                />
                <Field
                  component={Input}
                  name="city"
                  label="Miejscowość"
                  disabled={disabled || !user.isAdmin && !roles.city.filledBy.includes(user.role.name)}
                />
              </div>
              <h4>Dane obozowe</h4>
              <Field
                component={Search}
                name="rank"
                label="Stopień harcerski"
                options={rankOptions}
                disabled={disabled || !user.isAdmin && !roles.rank.filledBy.includes(user.role.name)}
              />
              <Field
                component={Search}
                name="instructorRank"
                label="Stopień instruktorski"
                options={instructorRankOptions}
                disabled={disabled || !user.isAdmin && !roles.instructorRank.filledBy.includes(user.role.name)}
              />
              <Group>
                <div className="Details__row">
                  <span>Termin pełnienia funkcji</span>
                  <Field
                    component={Datepicker}
                    name="from"
                    className="Details__formDatepicker"
                    disabled={disabled || !user.isAdmin && !roles.from.filledBy.includes(user.role.name)}
                  />
                  <Field
                    component={Datepicker}
                    name="to"
                    className="Details__formDatepicker"
                    disabled={disabled || !user.isAdmin && !roles.to.filledBy.includes(user.role.name)}
                  />
                </div>
              </Group>
            </div>
          </div>
          <div className="Details__card">
            <Loader loading={loading} />
            <div className={cx("Details__cardContainer", {blur: loading})}>
              <Field
                component={FileInput}
                name="sanepidBook"
                label="Książeczka sanepidowska"
                disabled={disabled || !user.isAdmin && !roles.sanepidBook.filledBy.includes(user.role.name)}
                info="Załącz plik"
              />
              <Field
                component={FileInput}
                name="carrierTest"
                label="Badania na nosicielstwo"
                disabled={disabled || !user.isAdmin && !roles.carrierTest.filledBy.includes(user.role.name)}
                info="Załącz plik"
              />
              <Field
                component={FileInput}
                name="ZMStatement"
                label="Oświadczenie ZM"
                disabled={disabled || !user.isAdmin && !roles.ZMStatement.filledBy.includes(user.role.name)}
                info="Załącz plik - oryginał należy donieść do Biura Okręgu/Obwodu"
              />
              <Field
                component={FileInput}
                name="voluntaryContract"
                label="Umowa wolontariacka"
                info="Załącz plik"
                disabled={disabled || !user.isAdmin && !roles.voluntaryContract.filledBy.includes(user.role.name)}
              />
              <Field
                component={Input}
                name="isEducator"
                label="Wpisany na listę wychowawców"
                type="checkbox"
                disabled={disabled || !user.isAdmin && !roles.isEducator.filledBy.includes(user.role.name)}
              />
              <h5>Wypełnia Obwód/Okręg</h5>
              <Field
                component={Input}
                name="passedQuatermasterCourse"
                label="Zdany z pozytywnym wynikiem kurs kwatermistrzowski HAL/HAZ"
                type="checkbox"
                disabled={disabled || !user.isAdmin && !roles.passedQuatermasterCourse.filledBy.includes(user.role.name)}
              />
              <Field
                component={Input}
                name="paidDues"
                label="Opłacone składki instruktorskie"
                type="checkbox"
                disabled={disabled || !user.isAdmin && !roles.paidDues.filledBy.includes(user.role.name)}
              />
            </div>
          </div>
          {!disabled &&
            <div className="Details__btn">
              <Link to={`/projects/${projectId}/staff/quartermasters`}>Anuluj</Link>
              <SaveButton
                disabled={pristine || submitting}
                saving={submitting}
              >Zapisz</SaveButton>
            </div>
          }
        </div>
      </Form>
    )
  }
))
