import React, { Component } from 'react';
import PT from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import _ from 'underscore';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { fetchLifeGuards, deleteLifeGuard, toggleAcceptLifeGuard, commentLifeGuard } from 'modules/lifeGuards/actions';
import { closeModal, showToast } from 'theme/actions';
import Header from 'theme/Header';
import Navbar from 'theme/Navbar';
import Table, { AcceptCommentCell, Row, ActionsCell, openModal, accept, comment } from 'theme/Table';
import Loader from 'theme/Loader';
import Modal from 'theme/Modal';
import DownloadButton from 'theme/DownloadButton';
import Footer from 'theme/Footer';
import roles from 'modules/lifeGuards/roles';

import 'theme/common-views.sass';

const certsProps = [
  {key: 'woprId', label: 'Poświadczenie uprawnień ratownika'},
  {key: 'voluntaryContract', label: 'Umowa wolotariacka'},
  {key: 'contractStatement', label: 'Oświadczenie do umowy zlecenie'}
];

const HEADINGS = [
  {key: 'lastName', label: 'Nazwisko'},
  {key: 'firstName', label: 'Imię'},
  {key: 'from', label: 'Od'},
  {key: 'to', label: 'Do'},
  ...certsProps,
  {key: 'meta', label: ''}
]


class LifeGuards extends Component {

    componentDidMount() {
      this.props.fetchLifeGuards(this.props.match.params.projectId);
      this.roles = Object.keys(roles)
      .map( key => roles[key].filledBy)
      .reduce( (a, b) => a.concat(b));

      const rolesCount = this.roles
      .reduce( (sum, role) => ({...sum, [role]: (sum[role] || 0) + 1}), {});

      this.deleteRole = Object.keys(rolesCount)
      .reduce( (max, role) => rolesCount[max] >= rolesCount[role] ? max : role);
    }

    render() {
      const {user, isFetching, selectedLifeGuard, showToast, loadingActions, closeModal, lifeGuards, toggleAcceptLifeGuard, commentLifeGuard, activeProject} = this.props;
      const projectId = this.props.match.params.projectId;
      const deleteLifeGuard = (...args) => () => {
        this.props.deleteLifeGuard(...args)
        .then(closeModal)
        .catch(() => {
          const message = (
            <div>
              Nie udało się usunąć ratownika. Spróbuj ponownie później
            </div>
          )
          showToast({message, error: true});
        })
      };

      const editable = activeProject.state == 'Open';

      const canEdit = user.isAdmin || this.roles && this.roles.includes(user.role.name);
      const canDelete = editable && (user.isAdmin || this.deleteRole == user.role.name);

      return (
        <div className="List">
          <Header />
          <Navbar />
          <div className="List__container">

            <div className="List__heading">
              <h3>Ratownicy wodni</h3>
              {canEdit && editable &&
                <Link
                  to={`/projects/${projectId}/staff/lifeGuards/new`}
                  className="List__add"
                >Dodaj</Link>
              }
            </div>


            <div className="List__card">
              <Loader loading={isFetching} />
              <div className={cx("List__cardContainer", {blur: isFetching})}>
                {lifeGuards.length == 0
                  ? <h4>Do tego projektu nie dodano jeszcze żadnych ratowników.</h4>
                  :
                  <Table
                    headings={HEADINGS}
                    accept={editable && accept(toggleAcceptLifeGuard, projectId, HEADINGS, _.pluck(certsProps, 'key'))}
                    comment={comment(commentLifeGuard, projectId)}
                    permissions={roles}
                  >
                    {lifeGuards && lifeGuards.map( lifeGuard => {
                      const loading = loadingActions[lifeGuard.id] && ['AcceptLifeGuard', 'DeleteLifeGuard'].includes(loadingActions[lifeGuard.id].name);
                      return (
                        <Row
                          key={lifeGuard.id}
                          loading={loading}
                        >
                          <AcceptCommentCell data={lifeGuard}>
                            {lifeGuard.lastName}
                          </AcceptCommentCell>
                          <AcceptCommentCell data={lifeGuard}>
                            {lifeGuard.firstName}
                          </AcceptCommentCell>
                          <AcceptCommentCell data={lifeGuard}>
                            {lifeGuard.from && moment(lifeGuard.from).format('DD.MM.YYYY')}
                          </AcceptCommentCell>
                          <AcceptCommentCell data={lifeGuard}>
                            {lifeGuard.to && moment(lifeGuard.to).format('DD.MM.YYYY')}
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={lifeGuard}
                            commentsPosition="left"
                          >
                            {lifeGuard.woprId && lifeGuard.woprId.fileId &&
                              <DownloadButton onlyIcon file={lifeGuard.woprId} />
                            }
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={lifeGuard}
                            commentsPosition="left"
                          >
                            {lifeGuard.voluntaryContract && lifeGuard.voluntaryContract.fileId &&
                              <DownloadButton onlyIcon file={lifeGuard.voluntaryContract} />
                            }
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={lifeGuard}
                            commentsPosition="left"
                          >
                            {lifeGuard.contractStatement && lifeGuard.contractStatement.fileId &&
                              <DownloadButton onlyIcon file={lifeGuard.contractStatement} />
                            }
                          </AcceptCommentCell>
                          <ActionsCell
                            data={lifeGuard}
                            del={canDelete && openModal('deleteLifeGuard')}
                            editPath={`/projects/${projectId}/staff/lifeGuards/${lifeGuard.id}`}
                            acceptable={editable && user.isAdmin}
                          />
                        </Row>
                      )
                    })}
                  </Table>
                }
              </div>
            </div>
          </div>
          <Modal
            className="List__modal"
            header={<h4>Uwaga!</h4>}
          >
            <p>
              Czy na pewno chcesz usunąć ratownika <b>{selectedLifeGuard.firstName} {selectedLifeGuard.lastName}</b>?
            </p>
            <div className="List__modalActions">
              <button
                className="List__modalDiscard"
                onClick={closeModal}
              >Anuluj</button>
              <button
                className="List__modalOk"
                onClick={deleteLifeGuard(projectId, selectedLifeGuard.id)}
              >Usuń</button>
            </div>
          </Modal>
          <Footer />
        </div>
      )
    }
}

LifeGuards.propTypes = {
  lifeGuards: PT.arrayOf(PT.shape({
    id: PT.string
  })),
  fetchLifeGuards: PT.func,
  isFetching: PT.bool,
  selectedLifeGuard: PT.shape({
    id: PT.string,
    name: PT.string
  }),
  deleteLifeGuard: PT.func.isRequired,
  closeModal: PT.func.isRequired,
  showToast: PT.func.isRequired
}

const mapStateToProps = ({data: {lifeGuards, activeProject}, isFetching, modal, auth: {user}}) => ({
  lifeGuards: lifeGuards || [],
  isFetching: isFetching.global,
  selectedLifeGuard: modal.payload || {},
  loadingActions: isFetching.objects,
  user,
  activeProject: activeProject || {}
})

export default withRouter(connect(
  mapStateToProps,
  { fetchLifeGuards, deleteLifeGuard, showToast, closeModal, toggleAcceptLifeGuard, commentLifeGuard }
)(LifeGuards))
