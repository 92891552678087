import React                from 'react';
import cx                   from 'classnames';
import { reduxForm, Field } from 'redux-form';
import Loader               from 'theme/Loader';
import roles                from 'modules/accounting/roles';
import Comments             from 'theme/Comments';
import Form,
      {
        Input,
        SaveButton,
        CommentableField
      }                     from 'theme/Form';

const fields = [
	'cashBook',
	'lastCashBookPosition',
	'bankBook',
	'lastBankBookPosition',
	'receiptBooks',
	'receiptBooks',
	'lastReceiptBookPosition',
	'paymentsList',
	'returnsList'
]


const FinancesForm = reduxForm({
  enableReinitialize: true,
  form: 'AccountingFinancesForm'
})(({handleSubmit, fetching, commentsProps, showComments, user, pristine, submitting, accounting, disabled}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <div className="Details__card">
        <Loader loading={fetching} />
        <div className={cx("Details__cardContainer", {blur: fetching})}>
          <div className="Details__row">
            <div className="Accounting__column">
              <CommentableField
                commentsProps={commentsProps}
                showComments={showComments}
                prop="cashBook"
                data={accounting}
              >
                <Field
                  component={Input}
                  name="cashBook"
                  label="Książka kasowa"
                  type="checkbox"
                  disabled={disabled || !user.isAdmin && !roles.cashBook.filledBy.includes(user.role.name)}
                />
              </CommentableField>
              <CommentableField
                commentsProps={commentsProps}
                showComments={showComments}
                prop="bankBook"
                data={accounting}
              >
                <Field
                  component={Input}
                  name="bankBook"
                  label="Książka bankowa"
                  type="checkbox"
                  disabled={disabled || !user.isAdmin && !roles.bankBook.filledBy.includes(user.role.name)}
                />
              </CommentableField>
              <CommentableField
                commentsProps={commentsProps}
                showComments={showComments}
                prop="receiptBooks"
                data={accounting}
              >
                <Field
                  component={Input}
                  name="receiptBooks"
                  label="Kwitariusz KP"
                  type="checkbox"
                  disabled={disabled || !user.isAdmin && !roles.receiptBooks.filledBy.includes(user.role.name)}
                />
              </CommentableField>
              <CommentableField
                commentsProps={commentsProps}
                showComments={showComments}
                prop="paymentsList"
                data={accounting}
              >
                <Field
                  component={Input}
                  name="paymentsList"
                  label="Lista odpłatności"
                  type="checkbox"
                  disabled={disabled || !user.isAdmin && !roles.paymentsList.filledBy.includes(user.role.name)}
                />
              </CommentableField>
              <CommentableField
                commentsProps={commentsProps}
                showComments={showComments}
                prop="returnsList"
                data={accounting}
              >
                <Field
                  component={Input}
                  name="returnsList"
                  label="Lista zwrotów"
                  type="checkbox"
                  disabled={disabled || !user.isAdmin && !roles.returnsList.filledBy.includes(user.role.name)}
                />
              </CommentableField>
            </div>
            <div className="Accounting__column">
              <CommentableField
                commentsProps={commentsProps}
                showComments={showComments}
                prop="lastCashBookPosition"
                data={accounting}
              >
                <Field
                  component={Input}
                  name="lastCashBookPosition"
                  label="Numer ostatniej pozycji w książce kasowej"
                  disabled={disabled || !user.isAdmin && !roles.lastCashBookPosition.filledBy.includes(user.role.name)}
                />
              </CommentableField>
              <CommentableField
                commentsProps={commentsProps}
                showComments={showComments}
                prop="lastBankBookPosition"
                data={accounting}
              >
                <Field
                  component={Input}
                  name="lastBankBookPosition"
                  label="Numer ostatniej pozycji w książce bankowej"
                  disabled={disabled || !user.isAdmin && !roles.lastBankBookPosition.filledBy.includes(user.role.name)}
                />
              </CommentableField>
              <CommentableField
                commentsProps={commentsProps}
                showComments={showComments}
                prop="lastReceiptBookPosition"
                data={accounting}
              >
                <Field
                  component={Input}
                  name="lastReceiptBookPosition"
                  label="Numer ostatniego kwitu KP"
                  disabled={disabled || !user.isAdmin && !roles.lastReceiptBookPosition.filledBy.includes(user.role.name)}
                />
              </CommentableField>
            </div>
          </div>
        </div>
      </div>
      {!disabled &&
        <div className="Accounting__btn">
          <SaveButton
            disabled={pristine || submitting}
            saving={submitting}
          >Zapisz</SaveButton>
        </div>
      }
    </Form>
  )
})

export default ({comment: commentAcocunting, accounting, commentsProps, ...rest}) => {
  const {visible, prop} = commentsProps;
  const comment = (prop) => (values) => commentAcocunting(prop, values);
  return (
    <div>
      <FinancesForm
        {...rest}
        commentsProps={commentsProps}
        initialValues={accounting}
        accounting={accounting}
      />
      {visible && prop && fields.includes(prop) &&
        <Comments
          comment={comment(prop)}
          comments={accounting && accounting.meta && accounting.meta[prop] && accounting.meta[prop].comments}
          className="Accounting__commentBox"
          hideArrow
        />
      }
    </div>
  )
}
