import React from 'react';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import cx from 'classnames';
import { closeModal } from 'theme/actions';

import './Modal.sass'

ReactModal.setAppElement('#app');


const mapStateToProps = ({modal}) => ({...modal})

export default connect(
  mapStateToProps,
  {closeModal}
)(({visible, closeModal, children, className, header}) => (
  <ReactModal
    isOpen={visible}
    onRequestClose={closeModal}
    closeTimeoutMS={300}
    contentLabel="Modal"
    className={cx("Modal__content", className)}
    overlayClassName="Modal__overlay"
  >
    <div className="Modal__header">
      {header}
      <button
        className="Modal__close"
        onClick={closeModal}
      ><span className="fa fa-times" /></button>
    </div>
    <div className="Modal__contentContainer">
      {children}
    </div>
  </ReactModal>
))
