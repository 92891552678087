import api, { pickAllowedProps } from 'api';
import { pick, omit, intersection } from 'underscore';
import { push } from 'react-router-redux';
import { requestSuccess, createRequest, handleError } from 'actions/requests';
import { saveFiles, matchFiles } from 'modules/files/actions';
import roles from './roles';

const FILE_FIELDS = [
  'leaderCertificate',
  'internshipCertificate',
  'phmInstructorCertificate',
  'KRKCertificate',
  'medicalCertificate',
  'DDStatement',
  'ZMStatement'
];


export function fetchCommands(projectId, opts = {loader: true}) {
  return (dispatch) => {
    opts && opts.loader && dispatch(createRequest({global: true, name: 'commands'}));
    return api({
      method: 'get',
      url: `/projects/${projectId}/commands`
    })
    .then((response) => {
			dispatch(requestSuccess(response, 'commands', opts.id));
			return response.data;
		})
    .catch(handleError(dispatch));
  }
}

export function fetchCommand(projectId, id) {
  return (dispatch) => {
    dispatch(createRequest());
    return api({
      method: 'get',
      url: `/projects/${projectId}/commands/${id}`
    })
    .then((response) => dispatch(matchFiles(response, FILE_FIELDS, 'command')))
    .catch(handleError(dispatch));
  }
}

export function addCommand({projectId}, command) {
  const allowedProps = pickAllowedProps(roles);
  return (dispatch) => {
  dispatch(createRequest());
    return saveFiles(command, intersection(FILE_FIELDS, allowedProps))
    .then( filesObj => {
      const req = pick(omit(command, FILE_FIELDS), allowedProps);
      return api({
        method: 'post',
        url: `/projects/${projectId}/commands`,
        data: {
          ...req,
          ...filesObj
        }
      })
      .then((response) => {
        dispatch(matchFiles(response, FILE_FIELDS, 'command'));
        dispatch(push(`/projects/${projectId}/staff/commands`));
      })
    })
    .catch(handleError(dispatch));
  }
}

export function editCommand({projectId, id, disableRedirect}, command) {
  const allowedProps = pickAllowedProps(roles);
  return (dispatch) => {
    dispatch(createRequest({name: 'editCommand'}));
    return saveFiles(command, intersection(FILE_FIELDS, allowedProps))
    .then( filesObj => {
      const req = pick(omit(command, FILE_FIELDS), allowedProps);
      return api({
        method: 'patch',
        url: `/projects/${projectId}/commands/${id}`,
        data: {
          ...req,
          ...filesObj
        }
      })
      .then((response) => {
        dispatch(matchFiles(response, FILE_FIELDS, 'command'))
        !disableRedirect && dispatch(push(`/projects/${projectId}/staff/commands`));
      })
    })
    .catch(handleError(dispatch));
  }
}

export function deleteCommand(projectId, id) {
  return (dispatch) => {
    dispatch(createRequest({name: 'DeleteCommand', id}));
    return api({
      method: 'delete',
      url: `/projects/${projectId}/commands/${id}`
    })
    .then(() => dispatch(fetchCommands(projectId, {loader: false, id})))
    .catch(handleError(dispatch, id));
  }
}

export function toggleAcceptCommand(projectId, command, state, ...props) {
  return (dispatch) => {
    dispatch(createRequest({name: 'AcceptCommand', id: command.id}));
    const prom = props.map( prop => api({
      method: 'put',
      url: `/projects/${projectId}/commands/${command.id}/${prop}/state`,
      data: {state}
    }))
    return Promise.all(prom)
    .then( () => dispatch(fetchCommands(projectId, {loader: false, id: command.id})))
    .catch(handleError(dispatch, command.id));
  }
}

export function commentCommand(projectId, command, prop, data) {
  return (dispatch) => {
    dispatch(createRequest({name: 'CommentCommand', id: command.id}));
    return api({
      method: 'post',
      url: `/projects/${projectId}/commands/${command.id}/${prop}/comments`,
      data
    })
    .then( () => dispatch(fetchCommands(projectId, {loader: false, id: command.id})))
    .catch(handleError(dispatch, command.id));
  }
}
