import * as types from 'types'

const auth = (state = {}, action) => {
    switch (action.type) {
      case types.LOGIN_ATTEMPT:
        return {
          ...state,
          loading: true
        }
      case types.LOGGED_FAILED:
        return {
          ...state,
          error: action.error,
          loading: false
        };
      case types.LOGGED_SUCCESSFULLY:
        return {
          ...state,
          error: null,
          loading: false,
          ...action.auth,
        };
      case types.LOGGED_OUT:
        return {};
      default:
        return state;
    }
}

export default auth;
