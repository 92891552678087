import _ from 'underscore';
import { openModal as open } from 'theme/actions';
import store from 'store';

export const openModal = (modalName) => (data) => store.dispatch(open(modalName, data));

export const accept = (toggleFunction, projectId, headings, filesProps) => (data, state, ...names) => {
  const props = names.length == 0
  ? _.without(_.pluck(headings, 'key'), 'meta')
  : names;
  const propsWithFiles = filesProps
  ? _.flatten(props.map( prop => prop == '' ? filesProps : prop))
  : props;
  return toggleFunction(projectId, data, state, ...propsWithFiles)
}
export const comment = (commentFunction, projectId) =>
  (data, name) => (values) => commentFunction(projectId, data, name, values);
