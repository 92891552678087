import  React,
        { Component }               from 'react';
import  PT                          from 'prop-types';
import  cx                          from 'classnames';
import  { connect }                 from 'react-redux'
import {pluck, pick, map, first}    from 'underscore';
import deep                         from 'deep-diff';
import  { withRouter }              from 'react-router'
import  { fetchApproval,
          editApproval,
          addApproval,
          commentApproval }         from 'modules/approval/actions';
import  { fetchUnits }              from 'modules/units/actions';
import  { fetchFiles }              from 'modules/files/actions';
import  { showToast, showComments } from 'theme/actions';
import Header                       from 'theme/Header';
import Navbar                       from 'theme/Navbar';
import Comments                     from 'theme/Comments';
import Footer                       from 'theme/Footer';
import ApprovalForm                 from './ApprovalForm'

import './Approval.sass';


class ApprovalDetails extends Component {

    componentDidMount() {
      this.props.fetchApproval(this.props.match.params.projectId);
      this.props.fetchUnits(this.props.match.params.projectId);
    }

    render() {
      const {isFetching, match, approval, editApproval, commentsProps, showComments, commentApproval, user, units, activeProject} = this.props;
      const {projectId} = match.params;
      const {visible, prop} = commentsProps;
      const leftCommentPositionsProps = [
        'mazovianGuideApproval',
        'mazovianScoutApproval',
        'northEastGuideApproval',
        'northEastScoutApproval'
      ]

      const submit = (values) => {
        const diff = pluck(deep.diff(approval, values), 'path');
        const vals = diff ? pick(values, ...map(diff, first)) : values;
        editApproval({projectId}, vals)
        .then(() => {
          const message = <div>Zapisano</div>
          showToast({message});
        })
        .catch(() => {
          const message = (
            <div>
              Coś poszło nie tak. Spróbuj ponownie później
            </div>
          )
          showToast({message, error: true});
        })
      }

      const comment = (prop) => (values) => commentApproval(projectId, prop, values);

      return (
        <div className="Approval">
          <Header />
          <Navbar />
          <div className="Approval__container">

            <div className="Approval__heading">
              <h3>Zatwierdzanie</h3>
            </div>

            <ApprovalForm
              initialValues={approval}
              onSubmit={submit}
              loading={isFetching}
              commentsProps={commentsProps}
              approval={approval}
              showComments={showComments}
              user={user}
              units={units}
              disabled={activeProject.state != 'Open'}
            />
            {visible && prop &&
              <Comments
                comment={comment(prop)}
                comments={approval && approval.meta && approval.meta[prop] && approval.meta[prop].comments}
                position={leftCommentPositionsProps.includes(prop) && 'left'}
                className={cx("Approval__commentBox", prop)}
                hideArrow
              />
            }
          </div>
          <Footer />
        </div>
      )
    }
}

ApprovalDetails.propTypes = {
  approval: PT.shape({
    id: PT.string
  }),
  fetchApproval: PT.func,
}

const mapStateToProps = ({data: {approval, units, activeProject}, isFetching, comments, auth}) => ({
  approval,
  isFetching: isFetching.global,
  commentsProps: comments,
  user: auth.user,
  units,
  activeProject: activeProject || {}
})

export default withRouter(connect(
  mapStateToProps,
  { fetchApproval, editApproval, addApproval, showToast, fetchFiles, commentApproval, showComments, fetchUnits }
)(ApprovalDetails))
