import React                        from 'react';
import cx                           from 'classnames';
import {labels, paths} from 'modules/summary/fields';


export default class extends React.Component {
  constructor () {
    super();
    this.state = {expanded: false};
    this.toggleExpand = this.toggleExpand.bind(this);
    this.click = this.click.bind(this);
  }

  toggleExpand (e) {
    e.stopPropagation();
    const {expanded} = this.state;
    this.setState({expanded: !expanded});
  }

  click () {
    const {projectId, history, redirect, item} = this.props;
    const {elementType, id} = item;

    if (redirect)
      history.push(`/projects/${projectId}${paths[elementType]}/${id}`)
    else
      history.push(`/projects/${projectId}${paths[elementType]}#${id}`)
  }

  render () {
    const {item, download} = this.props;
    const {expanded} = this.state
    const {unfilled, unapproved, files} = item;
    return (
      <div
        className={cx("Summary__listItem", {expanded})}
        onClick={this.click}
      >
        <div className="Summary__listItemCell">
          <div className="Summary__listItemCellContent">
            {unfilled.map(u => (
              <div key={u}>
                {labels[u]}
              </div>
            ))}
          </div>
          {unfilled.length > 0 &&
            <div className="Summary__listItemCellCount">{unfilled.length}</div>
          }
        </div>
        <div className="Summary__listItemCell">
          <div className="Summary__listItemCellContent">
            {unapproved.map(u => (
              <div key={u}>
                {labels[u]}
              </div>
            ))}
          </div>
          {unapproved.length > 0 &&
            <div className="Summary__listItemCellCount">{unapproved.length}</div>
          }
        </div>
        <div className="Summary__listItemCell">
          <div className="Summary__listItemCellContent">
            {files.length > 0 &&
              <button
                onClick={download(files)}
              >
                <span className="fa fa-download" />
                Pobierz
              </button>
            }
          </div>
          {files.length > 0 &&
            <div className="Summary__listItemCellCount">{files.length}</div>
          }
        </div>
        {(unapproved.length > 2 || unfilled.length > 2) &&
          <div className="Summary__expand">
            <button
              onClick={this.toggleExpand}
            >
              <span className={cx({'fa fa-chevron-down': !expanded, 'fa fa-chevron-up': expanded})} />
              {expanded ? 'Zwiń' : 'Rozwiń'}
            </button>
          </div>
        }
      </div>
    )
  }
}