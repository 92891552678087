import React, { Component }               from 'react';
import cx                                 from 'classnames';
import { connect }                        from 'react-redux'
import { withRouter }                     from 'react-router'
import {  fetchCommands,
          fetchQuartermasters,
          fetchEducators,
          fetchHealthCarers,
          fetchLifeGuards,
          fetchCooks,
          fetchDrivers }                  from 'actions';
import Header                             from 'theme/Header';
import Navbar                             from 'theme/Navbar';
import Table, { AcceptCommentCell, Row }  from 'theme/Table';
import Loader                             from 'theme/Loader';
import Footer                             from 'theme/Footer';


import 'theme/common-views.sass';
import './StaffSummary.sass';


const commandCerts = [
  'KRKCertificate',
  'medicalCertificate',
  'DDStatement',
  'ZMStatement'
];

const quartermasterCerts = [
  'sanepidBook',
  'carrierTest',
  'voluntaryContract',
  'ZMStatement'
];

const educatorCerts = [
  'KRKCertificate',
  'medicalCertificate',
  'voluntaryContract',
  'DDStatement',
];

const healthCarerCerts = [
  'sanepidBook',
  'carrierTest',
  'practicePermit',
];

const lifeGuardCerts = [
  'woprId',
];

const cookDriverCerts = [
  'sanepidBook',
  'carrierTest',
];

const FUNCTIONS = {
  command: 'Komendant',
  quartermaster: 'Kwatermistrz',
  educator: 'Wychowawca',
  healthCarer: 'Opieka medyczna',
  lifeGuard: 'Ratownik wodny',
  cook: 'Kucharz',
  driver: 'Kierowca'
}

const CARER_TYPES = {
  Nurse: 'pielęgniarka',
  Paramedic: 'ratownik medyczny',
  Doctor: 'lekarz'
}

const HEADINGS = [
  {key: 'func', label: 'Funkcja'},
  {key: 'firstName', label: 'Imię'},
  {key: 'lastName', label: 'Nazwisko'},
  {key: 'paidDues', label: 'Opłacone składki'},
  {key: 'attachments', label: 'Komplet załączników'}
]

const checkCommandCerts = (command) =>{
  return Boolean(
    ( (command.leaderCertificate && command.internshipCertificate &&
        command.leaderCertificate.fileId && command.internshipCertificate.fileId
      )
      ||
      (command.phmInstructorCertificate && command.phmInstructorCertificate.fileId)
    )
    &&
    commandCerts.reduce((a, b) => a && command[b] && command[b].fileId, true)
  )
}

const checkEducatorCerts = (educator) => {
  return Boolean(
    ( (educator.educatorCertificate && educator.educatorCertificate.fileId)
      ||
      (educator.instructorCertificate && educator.instructorCertificate.fileId)
    )
    &&
    educatorCerts.reduce((a, b) => a && educator[b] && educator[b].fileId, true)
  )
}

const checkQuartermastersCerts = (quartermaster) => {
  return Boolean(
    quartermasterCerts.reduce((a, b) => a && quartermaster[b] && quartermaster[b].fileId, true)
  )
}

const checkDefaultCerts = (member, name) => {
  const certs =
    ((name == 'driver' || name == 'cook') && cookDriverCerts) ||
    (name == 'healthCarer' && healthCarerCerts) ||
    (name == 'lifeGuard' && lifeGuardCerts);
  return Boolean(
    ( (member.voluntaryContract && member.voluntaryContract.fileId)
      ||
      (member.contractStatement && member.contractStatement.fileId)
    )
    &&
    certs.reduce((a, b) => a && member[b] && member[b].fileId, true)
  )
}


const CHECK_FUNCTIONS = {
  command: checkCommandCerts,
  educator: checkEducatorCerts,
  quartermaster: checkQuartermastersCerts,
  default: checkDefaultCerts
}

class Commands extends Component {
    constructor () {
      super();
      this.isFetching = true;
      this.staff = [];
      this.educatorStaff = [];
    }

    componentDidMount() {
      const requests = [
        this.props.fetchCommands(this.props.match.params.projectId),
        this.props.fetchEducators(this.props.match.params.projectId),
        this.props.fetchQuartermasters(this.props.match.params.projectId),
        this.props.fetchCooks(this.props.match.params.projectId),
        this.props.fetchDrivers(this.props.match.params.projectId),
        this.props.fetchHealthCarers(this.props.match.params.projectId),
        this.props.fetchLifeGuards(this.props.match.params.projectId)
      ];
      Promise.all(requests)
      .then( ([commands, educators, quartermasters, cooks, drivers, healthCarers, lifeGuards]) => {
        const mappedCommands = this.mapStaff(commands, 'command');
        const mappedQuartermasters = this.mapStaff(quartermasters, 'quartermaster');
        const mappedEducators = this.mapStaff(educators, 'educator');
        const mappedHealthCarers = this.mapStaff(healthCarers, 'healthCarer');
        const mappedLifeGuards = this.mapStaff(lifeGuards, 'lifeGuard');
        const mappedCooks = this.mapStaff(cooks, 'cook');
        const mappedDrivers = this.mapStaff(drivers, 'driver');
        this.educatorStaff = [
          ...mappedCommands,
          ...mappedEducators
        ]
        this.staff = [
          ...mappedQuartermasters,
          ...mappedHealthCarers,
          ...mappedLifeGuards,
          ...mappedCooks,
          ...mappedDrivers
        ];
        this.isFetching = false;
        this.forceUpdate();
      })
    }


    mapStaff (staff, name) {
      return staff.map( s => {
        return {
          ...s,
          func: name,
          neededCerts:
            CHECK_FUNCTIONS[name] ? CHECK_FUNCTIONS[name](s) : CHECK_FUNCTIONS.default(s, name)
        }
      })
    }

    render() {
      const staff = this.staff;
      const educatorStaff = this.educatorStaff;

      return (
        <div className="List StaffSummary">
          <Header />
          <Navbar />
          <div className="List__container">
            <div className="StaffSummary__list">

              <div className="List__heading">
                <h3>Kadra wychowawcza</h3>
              </div>

              <div className="List__card">
                <Loader loading={this.isFetching} />
                <div className={cx("List__cardContainer", {blur: this.isFetching})}>
                  {educatorStaff.length == 0
                    ? <h4>Do tego projektu nie dodano jeszcze żadnych komendantów ani wychowawców.</h4>
                    :
                    <Table
                      headings={HEADINGS}
                    >
                      {educatorStaff && educatorStaff.map( s => {
                        return (
                          <Row
                            key={s.id}
                            className="StaffSummary__row"
                          >
                            <AcceptCommentCell data={s}>
                              {FUNCTIONS[s.func]}
                              <span className="StaffSummary__type">
                                {s.func == 'healthCarer' && `(${CARER_TYPES[s.type]})`}
                              </span>
                            </AcceptCommentCell>
                            <AcceptCommentCell data={s}>
                              {s.lastName}
                            </AcceptCommentCell>
                            <AcceptCommentCell data={s}>
                              {s.firstName}
                            </AcceptCommentCell>
                            <AcceptCommentCell data={s} className="List__dues">
                              <span
                                className={cx(
                                  "List__check",
                                  {"fa fa-check": s.paidDues}
                                )}
                              />
                            </AcceptCommentCell>
                            <AcceptCommentCell data={s} className="List__dues">
                              <span
                                className={cx(
                                  "List__check",
                                  {"fa fa-check": s.neededCerts}
                                )}
                              />
                            </AcceptCommentCell>
                          </Row>
                        )
                      })}
                    </Table>
                  }
                </div>
              </div>
            </div>

            <div className="StaffSummary__list">

              <div className="List__heading">
                <h3>Kadra pomocnicza</h3>
              </div>

              <div className="List__card">
                <Loader loading={this.isFetching} />
                <div className={cx("List__cardContainer", {blur: this.isFetching})}>
                  {staff.length == 0
                    ? <h4>Do tego projektu nie dodano jeszcze nikogo do kadry pomocniczej.</h4>
                    :
                    <Table
                      headings={HEADINGS}
                    >
                      {staff && staff.map( s => {
                        return (
                          <Row
                            key={s.id}
                            className="StaffSummary__row"
                          >
                            <AcceptCommentCell data={s}>
                              {FUNCTIONS[s.func]}
                              <span className="StaffSummary__type">
                                {s.func == 'healthCarer' && `(${CARER_TYPES[s.type]})`}
                              </span>
                            </AcceptCommentCell>
                            <AcceptCommentCell data={s}>
                              {s.lastName}
                            </AcceptCommentCell>
                            <AcceptCommentCell data={s}>
                              {s.firstName}
                            </AcceptCommentCell>
                            <AcceptCommentCell data={s} className="List__dues">
                              <span
                                className={cx(
                                  "List__check",
                                  {"fa fa-check": s.paidDues}
                                )}
                              />
                            </AcceptCommentCell>
                            <AcceptCommentCell data={s} className="List__dues">
                              <span
                                className={cx(
                                  "List__check",
                                  {"fa fa-check": s.neededCerts}
                                )}
                              />
                            </AcceptCommentCell>
                          </Row>
                        )
                      })}
                    </Table>
                  }
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      )
    }
}

export default withRouter(connect(
  null,
  { fetchCommands, fetchCooks, fetchDrivers, fetchEducators, fetchHealthCarers, fetchLifeGuards, fetchQuartermasters}
)(Commands))
