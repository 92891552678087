import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PT from 'prop-types';
import moment from 'moment';
import cx from 'classnames';
import { reduxForm, Field, formValueSelector, FieldArray } from 'redux-form';
import Table from 'theme/Table';
import Loader from 'theme/Loader';
import Form, { Input, Textarea, Datepicker, Search, SaveButton } from 'theme/Form';
import { editUser } from 'modules/projects/actions';

import 'react-datepicker/dist/react-datepicker.css';

const TYPES = [
  {value: 'Region', label: 'Obwód'},
  {value: 'Office', label: 'Biuro okręgu'},
  {value: 'Banner', label: 'Chorągiew'},
  {value: 'Detachment', label: 'Hufcowy'},
  {value: 'CampCommander', label: 'Komendant obozu'}
]

const validate = values => {
  return {
    name: !values.name && 'Wymagane',
    users: Object.assign(
      values.users && values.users.map( user => (
        {
          userId: !user.userId && 'Wymagane',
          role: !user.role && 'Wymagane'
        })) || [],
    )
  }
}

const Users = ({fields, users, meta: {error, submitFailed}}) => {
  const nameOptions = users && users.map( u => ({value: u.id, label: u.name}));

  return (
    <div>
      <Table
        headings={fields.length != 0 && ['Nazwa', 'Rola', ' '] || []}
        className="Project__usersTable"
      >
        {fields.map( (field, i) => {
          return (
            <tr key={i}>
              <td className="Project__usersName">
                <Field
                  name={`${field}.userId`}
                  component={Search}
                  options={nameOptions || []}
                />
              </td>
              <td className="Project__usersType">
                <Field
                  name={`${field}.role`}
                  component={Search}
                  options={TYPES}
                />
              </td>
              <td className="Project__usersActions">
                <button
                  type="button"
                  onClick={() => fields.remove(i)}
                ><span className="fa fa-trash" />Usuń</button>
              </td>
            </tr>
          )
        })}
        <tr>
          <td
            className={cx("Project__usersInfo", {error: submitFailed && error})}
            colSpan="2"
          >
            <p>
              {submitFailed
                ? error
                : fields.length == 0 && 'Dodaj użytkowników oraz określ ich uprawnienia'
              }
            </p>
          </td>
          <td className="Project__usersAdd Project__usersActions">
            <button
              type="button"
              onClick={() => fields.push({role: 'Region'})}
            ><span className="fa fa-plus" />Dodaj</button>
          </td>
        </tr>
      </Table>
    </div>
  )
}

Users.propTypes = {
  fields: PT.shape({
    forEach: PT.func
  }),
  users: PT.arrayOf(PT.shape({
    id: PT.string
  }))
}

export default connect(
  (state) => {
    const selector = formValueSelector('ProjectForm');
    return {
      to: selector(state, 'description.to'),
      from: selector(state, 'description.from'),
      isFetching: state.isFetching.global,
      user: state.auth.user
    }
  },
  { editUser }
)(reduxForm({
  form: 'ProjectForm',
  enableReinitialize: true,
  validate
})(
  ({handleSubmit, from, to, users, editUser, pristine, submitting, isFetching, user}) => {
    const momentFrom = moment(from, 'YYYY-MM-DD');
    const momentTo = moment(to, 'YYYY-MM-DD');

    return (
      <Form
        className="Project__form"
        onSubmit={handleSubmit}
      >
        <div className="Project__row">
          <div className="Project__card">
            <Loader loading={isFetching} />
            <div className={cx("Project__cardContainer", {blur: isFetching})}>
              <Field
                component={Input}
                name="name"
                label="Nazwa robocza"
                disabled={!user.isAdmin}
              />
              <Field
                component={Textarea}
                name="description.units"
                label="Jednostki organizujące"
              />
              <Field
                component={Input}
                name="description.type"
                label="Rodzaj"
              />
              <Field
                component={Input}
                name="description.address"
                label="Miejsce / trasa"
              />
              <div className="Project__row">
                <Field
                  component={Datepicker}
                  name="description.from"
                  label="Data rozpoczęcia"
                  className="Project__formDatepicker"
                />
                <Field
                  component={Datepicker}
                  name="description.to"
                  label="Data zakończenia"
                  className="Project__formDatepicker"
                />
                {to && from &&
                  <div className="Project__formTime">
                    <div className="Project__formLabel">
                      Czas trwania: <b>{momentFrom.from(momentTo, true)}</b>
                    </div>
                    <div className="Project__formLabel">
                      Do rozpoczęcia: <b>{moment().to(momentFrom, true)}</b>
                    </div>
                  </div>
                }
              </div>
              <Field
                component={Textarea}
                name="description.comment"
                label="Uwagi"
              />
            </div>
          </div>
          {user.isAdmin &&
            <div className="Project__card">
              <Loader loading={isFetching} />
              <div className={cx("Project__cardContainer", {blur: isFetching})}>
                <h4>Użytkownicy</h4>
                <FieldArray
                  component={Users}
                  name="users"
                  edit={editUser}
                  users={users}
                />
              </div>
            </div>
          }
          <div className="Project__btn">
            <Link to="/projects">Anuluj</Link>
            <SaveButton
              disabled={pristine || submitting}
              saving={submitting}
            >Zapisz</SaveButton>
          </div>
        </div>
      </Form>
    )
  }
))
