import  React,
        { Component }         from 'react';
import  PT                    from 'prop-types';
import  cx                    from 'classnames';
import  { connect }           from 'react-redux'
import  { withRouter }        from 'react-router'
import  { Link }              from 'react-router-dom';
import  moment                from 'moment';
import  { fetchProjects,
          deleteProject,
          toggleAcceptProject,
          commentProject }    from 'modules/projects/actions';
import  Header                from 'theme/Header';
import Footer                 from 'theme/Footer';
import  Table,
        { Row,
          AcceptCommentCell,
          ActionsCell}        from 'theme/Table';
import  Loader                from 'theme/Loader';
import  Modal                 from 'theme/Modal';
import  { openModal,
          closeModal,
          showToast }         from 'theme/actions';

import './Projects.sass';

const HEADINGS = [
  {key: 'name', label:'Nazwa'},
  {key: 'type', label: 'Rodzaj'},
  {key: 'date', label: 'Termin'},
  {key: 'comment', label: 'Uwagi'},
  {key: 'toStart', label: 'Do rozpoczęcia'},
  {key: 'dateUpdated', label: 'Zmodyfikowano'},
  {key: 'state', label: 'Status'},
  {key: 'meta', label: ''}
]

const STATES = {
  Open: 'Otwarty',
  PendingAccounting: 'W trakcie rozliczania',
  Accounted: 'Rozliczony'
}

class ProjectsList extends Component {

    componentDidMount() {
      this.props.fetchProjects();
    }

    render() {
      const {
        projects,
        isFetching,
        history,
        selectedProject,
        showToast,
        closeModal,
        user,
        deletingProject,
      } = this.props;


      const openModal = (name) => (project) => {
        this.props.openModal(name, project);
      }
      const deleteProject = (...args) => () => {
        closeModal();
        this.props.deleteProject(...args)
        .catch( () => {
          const message = (
            <div>
              Nie udało się usunąć projektu. Spróbuj ponownie później
            </div>
          )
          showToast({message, error: true});
        })
      }

      const visibleHeadings = user.isAdmin || projects.some(
        project => project.users.some(
          u => u.userId == user.id && u.role == 'CampCommander'
        )
      )

      return (
        <div className="Projects">
          <Header />
          <div className="List__container">

            <div className="List__heading">
              <div>
                <h3>Projekty</h3>
                <p>Zarządzaj projektami</p>
              </div>
              {user && user.isAdmin &&
                <Link
                  to="/projects/new"
                  className="Projects__add"
                >Nowy</Link>
              }
            </div>

            <div className="Projects__card">
              <Loader loading={isFetching} />
              <div className={cx("Projects__cardContainer", {blur: isFetching})}>
                {!projects.length
                  ? <h4>Nie masz jeszcze żadnych projektów.</h4>
                  :
                  <Table
                    headings={visibleHeadings ? HEADINGS : HEADINGS.slice(0, -1)}
                  >
                    {projects
                      .sort((a, b) =>
                        moment(b.description.from, 'YYYY-MM-DD').diff(moment()) - moment(a.description.from, 'YYYY-MM-DD').diff(moment())
                      )
                      .map( project => {
                        const canEdit = user.isAdmin || project.users.some(u => u.userId == user.id && u.role == 'CampCommander');
                        return (
                          <Row
                            key={project.id}
                            onClick={() => history.push(`/projects/${project.id}/places`)}
                            loading={deletingProject == project.id}
                          >
                            <AcceptCommentCell hideIcons className="Projects__name">
                              {project.name}
                            </AcceptCommentCell>
                            <AcceptCommentCell hideIcons>
                              {project.description.type}
                            </AcceptCommentCell>
                            <AcceptCommentCell hideIcons>
                              {project.description.from &&
                                project.description.from && moment(project.description.from).format('DD.MM.YYYY')
                              } - {project.description.to &&
                                project.description.to && moment(project.description.to).format('DD.MM.YYYY')
                              }
                            </AcceptCommentCell>
                            <AcceptCommentCell hideIcons className="Projects__comment">
                              {project.description.comment}
                            </AcceptCommentCell>
                            <AcceptCommentCell hideIcons className="Projects__toStart">
                              {project.description.from && project.description.to &&
                                moment().to(moment(project.description.from, 'YYYY-MM-DD'), true)
                              }
                            </AcceptCommentCell>
                            <AcceptCommentCell hideIcons className="Projects__updated">
                              {moment.utc(project.updatedAt, 'YYYY-MM-DDTHH:mm:ss').local().format('DD.MM.YYYY HH:mm')}
                            </AcceptCommentCell>
                            <AcceptCommentCell
                              hideIcons
                              className={cx("Projects__state", project.state)}
                            >
                              {STATES[project.state]}
                            </AcceptCommentCell>
                            {canEdit &&
                              <ActionsCell
                                className="Projects__actions"
                                data={project}
                                del={user.isAdmin && openModal('deleteProject')}
                                editPath={`/projects/${project.id}`}
                              />
                              || <td />
                            }
                          </Row>
                        )
                      })
                    }
                  </Table>
                }
              </div>
            </div>
          </div>
          <Modal
            className="Projects__modal"
            header={<h4>Uwaga!</h4>}
          >
            <p>
              Czy na pewno chcesz usunąć projekt <b>{selectedProject.name}</b>?
            </p>
            <div className="Projects__modalActions">
              <button
                className="Projects__modalDiscard"
                onClick={closeModal}
              >Anuluj</button>
              <button
                className="Projects__modalOk"
                onClick={deleteProject(selectedProject.id)}
              >Usuń</button>
            </div>
          </Modal>
          <Footer />
        </div>
      )
    }
}

ProjectsList.propTypes = {
  projects: PT.arrayOf(PT.shape({
    id: PT.string,
    name: PT.string
  })),
  fetchProjects: PT.func,
  isFetching: PT.bool,
  selectedProject: PT.shape({
    id: PT.string,
    name: PT.string
  }),
  deleteProject: PT.func.isRequired,
  openModal: PT.func.isRequired,
  closeModal: PT.func.isRequired,
  showToast: PT.func.isRequired
}

const mapStateToProps = ({data, isFetching, modal, auth}) => ({
  user: auth.user,
  projects: data.projects || [],
  isFetching: isFetching.global,
  selectedProject: modal.payload || {},
  deletingProject: isFetching.name == 'deleteProject' && isFetching.id
})

export default withRouter(connect(
  mapStateToProps,
  { fetchProjects, deleteProject, openModal, showToast, closeModal, toggleAcceptProject, commentProject }
)(ProjectsList))
