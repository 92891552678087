import api from 'api';
import { requestSuccess, createRequest, handleError } from 'actions/requests';

export function fetchUsers(opts = {loader: true}) {
  return (dispatch) => {
    opts && opts.loader && dispatch(createRequest({global: true}));
    return api({
      method: 'get',
      url: '/users'
    })
    .then((response) => {
			dispatch(requestSuccess(response, 'users', opts.id));
			return response.data;
		})
    .catch(handleError(dispatch));
  }
}

export function fetchUser(id) {
  return (dispatch) => {
    dispatch(createRequest());
    return api({
      method: 'get',
      url: `/users/${id}`
    })
    .then((response) => dispatch(requestSuccess(response, 'user')))
    .catch(handleError(dispatch));
  }
}

export function addUser(user) {
  return (dispatch) => {
    dispatch(createRequest({global: false}));
    return api({
      method: 'post',
      url: `/users`,
      data: {
        ...user,
        loginUrl: `${location.origin}/signin`,
        isAdmin: user.isAdmin == '' ? false : user.isAdmin
      }
    })
    .then(() => dispatch(fetchUsers({loader: false})))
    .catch(handleError(dispatch));
  }
}

export function editUser(user, id) {
  return (dispatch) => {
    dispatch(createRequest({id, name: 'EditUser'}));
    return api({
      method: 'patch',
      url: `/users/${id}`,
      data: {
        ...user,
        isAdmin: user.isAdmin == '' ? false : user.isAdmin
      }
    })
    .then(() => dispatch(fetchUsers({loader: false, id})))
    .catch(handleError(dispatch, id));
  }
}

export function deleteUser(id) {
  return (dispatch) => {
    dispatch(createRequest({id, name: 'DeleteUser'}));
    return api({
      method: 'delete',
      url: `/users/${id}`
    })
    .then(() => dispatch(fetchUsers({loader: false, id})))
    .catch(handleError(dispatch, id));
  }
}
