import React, { Component }       from 'react';
import { connect }                from 'react-redux';
import { withRouter }             from 'react-router';
import deep                       from 'deep-diff';
import {pluck, pick, map, first } from 'underscore';
import {
        fetchPlaces,
        fetchUnits,
        fetchCommands,
        fetchEducators,
        fetchHealthCarers,
        fetchLifeGuards,
        fetchCooks,
        fetchDrivers,
        fetchQuartermasters,
        fetchAccounting,
        commentPlace,
        commentUnit,
        commentCommand,
        commentCook,
        commentDriver,
        commentEducator,
        commentHealthCarer,
        commentLifeGuard,
        commentQuartermaster,
        editPlace,
        editUnit,
        editAccounting,
        editCommand,
        editCook,
        editDriver,
        editEducator,
        editHealthCarer,
        editLifeGuard,
        editQuartermaster,
        commentAccounting,
        showComments,
        showToast
       }                          from 'actions';
import Header                     from 'theme/Header';
import Navbar                     from 'theme/Navbar';
import { comment }                from 'theme/Table';
import Footer                     from 'theme/Footer';

import 'theme/common-views.sass';

import Places                     from './Places';
import Units                      from './Units';
import EducatorStaff              from './EducatorStaff';
import Staff                      from './Staff';
import Participants               from './Participants';
import Finances                   from './Finances';
import Documents                  from './Documents';
import Dropbox                    from './Dropbox';

import './Accounting.sass'


class Accounting extends Component {
  constructor(props) {
    const {
      commentCommand,
      commentEducator,
      commentQuartermaster,
      commentHealthCarer,
      commentLifeGuard,
      commentCook,
      commentDriver,
      editCommand,
      editEducator,
      editQuartermaster,
      editHealthCarer,
      editLifeGuard,
      editCook,
      editDriver
    } = props;
    super();
    this.commentStaffFunctions = {
      command: commentCommand,
      educator: commentEducator,
      quartermaster: commentQuartermaster,
      healthCarer: commentHealthCarer,
      lifeGuard: commentLifeGuard,
      cook: commentCook,
      driver: commentDriver
    }
    this.editStaffFunctions = {
      command: editCommand,
      educator: editEducator,
      quartermaster: editQuartermaster,
      healthCarer: editHealthCarer,
      lifeGuard: editLifeGuard,
      cook: editCook,
      driver: editDriver
    }
    this.fetchEducatorStaff = this.fetchEducatorStaff.bind(this);
    this.fetchStaff = this.fetchStaff.bind(this);
    this.commentStaff = this.commentStaff.bind(this);
    this.commentAccounting = this.commentAccounting.bind(this);
    this.editPlace = this.editPlace.bind(this);
    this.editUnit = this.editUnit.bind(this);
    this.editAccounting = this.editAccounting.bind(this);
    this.editStaff = this.editStaff.bind(this);
  }

  componentDidMount() {
    const {projectId, card} = this.props.match.params;
    this.props.fetchPlaces(projectId);
    this.props.fetchUnits(projectId);
    this.fetchEducatorStaff(projectId);
    this.fetchStaff(projectId);
    this.props.fetchAccounting(projectId);

    this[card].scrollIntoView({block: 'start'});
  }

  componentDidUpdate (prevProps) {
    const prevCard = prevProps.match.params.card;
    const nextCard = this.props.match.params.card;
    if (prevCard != nextCard)
      this[nextCard].scrollIntoView({block: 'start', inline: 'nearest', behavior: 'smooth'});
  }

  fetchEducatorStaff (projectId, ...rest) {
    this.props.fetchEducators(projectId, ...rest);
    this.props.fetchCommands(projectId, ...rest);
  }

  fetchStaff (projectId, ...rest) {
    this.props.fetchQuartermasters(projectId, ...rest);
    this.props.fetchDrivers(projectId, ...rest);
    this.props.fetchCooks(projectId, ...rest);
    this.props.fetchHealthCarers(projectId, ...rest);
    this.props.fetchLifeGuards(projectId, ...rest);
  }

  commentStaff (func) {
    return comment(this.commentStaffFunctions[func], this.props.match.params.projectId);
  }

  editPlace (placeId) {
    return (vals) => {
      const {projectId} = this.props.match.params;
      return this.props.editPlace({projectId, placeId, disableRedirect: true}, vals)
      .then(() => this.props.fetchPlaces(projectId, {loader: false}));
    }
  }

  editUnit (unitId) {
    return (vals) => {
      const {projectId} = this.props.match.params;
      return this.props.editUnit({projectId, unitId, disableRedirect: true}, vals)
      .then(() => this.props.fetchUnits(projectId, {loader: false}));
    }
  }

  editAccounting (values) {
    const {projectId} = this.props.match.params;
    const diff = pluck(deep.diff(this.props.accounting, values), 'path');
    const vals = diff ? pick(values, ...map(diff, first)) : values;
    return this.props.editAccounting({projectId}, vals)
    .catch(() =>
      this.props.showToast({message: <div>Nie udało się zapisać danych. Spróbuj ponownie poźniej</div>, error: true})
    );
  }

  editStaff (id, func, educators) {
    return (vals) => {
      const {projectId} = this.props.match.params;
      return this.editStaffFunctions[func]({projectId, id, disableRedirect: true}, vals)
      .then(() =>
        educators ? this.fetchEducatorStaff(projectId, {loader: false}) : this.fetchStaff(projectId, {loader: false})
      );
    }
  }

  commentAccounting (prop, values) {
    const {projectId} = this.props.match.params;
    return this.props.commentAccounting(projectId, prop, values);
  }

  render() {
    const {
      fetching,
      places,
      units,
      commands,
      educators,
      quartermasters,
      healthCarers,
      lifeGuards,
      cooks,
      drivers,
      accounting,
      commentPlace,
      commentUnit,
      match,
      commentsProps,
      user,
      showComments,
      activeProject
    } = this.props;

    const projectId = match.params.projectId;
    const disabled = activeProject.state != 'PendingAccounting';

    const fetchingPlaces = fetching.includes('places');
    const fetchingUnits = fetching.includes('units');
    const fetchingEducatorStaff = fetching.includes('commands') && fetching.includes('educators');
    const fetchingStaff =
      fetching.includes('quartermasters') &&
      fetching.includes('healthCarers') &&
      fetching.includes('lifeGuards') &&
      fetching.includes('cooks') &&
      fetching.includes('drivers');
    const fetchingAccounting = fetching.includes('accounting');

    const educatorStaff = [].concat(
      commands.map(s => ({...s, func: 'command'})),
      educators.map(s => ({...s, func: 'educator'}))
    );
    const staff = [].concat(
      quartermasters.map(s => ({...s, func: 'quartermaster'})),
      healthCarers.map(s => ({...s, func: 'healthCarer'})),
      lifeGuards.map(s => ({...s, func: 'lifeGuard'})),
      cooks.map(s => ({...s, func: 'cook'})),
      drivers.map(s => ({...s, func: 'driver'}))
    )

    return (
      <div className="List Accounting">
        <Header />
        <Navbar />

        <div className="List__container" ref={obj => this.places = obj}>
          <div className="List__heading">
            <h3>Miejsca</h3>
          </div>
          <Places
            fetching={fetchingPlaces}
            places={places}
            comment={comment(commentPlace, projectId)}
            edit={this.editPlace}
            disabled={disabled}
          />
        </div>

        <div className="List__container"  ref={obj => this.units = obj}>
          <div className="List__heading">
            <h3>Jednostki</h3>
          </div>

          <Units
            fetching={fetchingUnits}
            units={units}
            comment={comment(commentUnit, projectId)}
            edit={this.editUnit}
            disabled={disabled}
          />
        </div>

        <div className="List__container" ref={obj => this.participants = obj}>
          <div className="List__heading">
            <h3>Uczestnicy</h3>
            <p>Rozliczenie list i kart</p>
          </div>

          <Participants
            comment={this.commentAccounting}
            accounting={accounting}
            commentsProps={commentsProps}
            user={user}
            showComments={showComments}
            fetching={fetchingAccounting}
            onSubmit={this.editAccounting}
            disabled={disabled}
          />
        </div>

        <div className="List__container" ref={obj => this.staff = obj}>
          <div className="List__heading">
            <h3>Kadra wychowawcza</h3>
          </div>

          <EducatorStaff
            fetching={fetchingEducatorStaff}
            educatorStaff={educatorStaff}
            comment={this.commentStaff}
            edit={this.editStaff}
            disabled={disabled}
          />
        </div>

        <div className="List__container">
          <div className="List__heading">
            <h3>Kadra pomocnicza</h3>
          </div>

          <Staff
            fetching={fetchingStaff}
            staff={staff}
            comment={this.commentStaff}
            edit={this.editStaff}
            disabled={disabled}
          />
        </div>

        <div className="List__container" ref={obj => this.financial = obj}>
          <div className="List__heading">
            <h3>Finanse</h3>
          </div>

          <Finances
            comment={this.commentAccounting}
            accounting={accounting}
            commentsProps={commentsProps}
            user={user}
            showComments={showComments}
            fetching={fetchingAccounting}
            onSubmit={this.editAccounting}
            disabled={disabled}
          />
        </div>

        <div className="List__container" ref={obj => this.documents = obj}>
          <div className="List__heading">
            <h3>Dodatkowe dokumenty</h3>
            <p>Dokumentacja merytoryczna</p>
          </div>

          <Documents
            comment={this.commentAccounting}
            accounting={accounting}
            commentsProps={commentsProps}
            user={user}
            showComments={showComments}
            fetching={fetchingAccounting}
            onSubmit={this.editAccounting}
            disabled={disabled}
          />
        </div>

        <div className="List__container" ref={obj => this.dropbox = obj}>
          <div className="List__heading">
            <h3>Dropbox</h3>
          </div>

          <Dropbox
            comment={this.commentAccounting}
            accounting={accounting}
            commentsProps={commentsProps}
            user={user}
            showComments={showComments}
            fetching={fetchingAccounting}
            onSubmit={this.editAccounting}
            disabled={disabled}
          />
        </div>
        <Footer />
      </div>
    )
  }
}

const mapStateToProps = ({
  data: {
    places,
    units,
    commands,
    educators,
    quartermasters,
    healthCarers,
    lifeGuards,
    cooks,
    drivers,
    accounting,
    activeProject
  },
  isFetching, modal, auth, comments
}) => ({
  places: places || [],
  units: units || [],
  accounting: accounting || {},
  commands: commands || [],
  educators: educators || [],
  quartermasters: quartermasters || [],
  healthCarers: healthCarers || [],
  lifeGuards: lifeGuards || [],
  cooks: cooks || [],
  drivers: drivers || [],
  fetching: isFetching.names || [],
  selectedPlace: modal.payload || {},
  loadingActions: isFetching.objects,
  user: auth.user,
  commentsProps: comments,
  activeProject: activeProject || {}
})

export default withRouter(connect(
  mapStateToProps,
  {
    fetchPlaces,
    fetchUnits,
    fetchCommands,
    fetchEducators,
    fetchCooks,
    fetchDrivers,
    fetchHealthCarers,
    fetchLifeGuards,
    fetchQuartermasters,
    fetchAccounting,
    commentPlace,
    commentUnit,
    commentCommand,
    commentCook,
    commentDriver,
    commentEducator,
    commentHealthCarer,
    commentLifeGuard,
    commentQuartermaster,
    commentAccounting,
    editPlace,
    editUnit,
    editAccounting,
    editCommand,
    editCook,
    editDriver,
    editEducator,
    editHealthCarer,
    editLifeGuard,
    editQuartermaster,
    showComments,
    showToast
  }
)(Accounting))
