import  React,
        { Component }               from 'react';
import  PT                          from 'prop-types';
import  cx                          from 'classnames';
import  _                           from 'underscore';
import  moment                      from 'moment';
import  { connect }                 from 'react-redux'
import  { withRouter }              from 'react-router'
import  Clipboard                   from 'clipboard';
import  Papa                        from 'papaparse';
import  { fetchParticipants,
          deleteParticipant,
          toggleAcceptParticipant,
          editParticipant,
          toggleAllParticipants,
          toggleParticipant,
          commentParticipant,
          addParticipants,
          clearCachedParticipants } from 'modules/participants/actions';
import  roles                       from 'modules/participants/roles';
import  { fetchUnits }              from 'modules/units/actions';
import  { fetchEducators }          from 'modules/educators/actions';
import  Header                      from 'theme/Header';
import  Navbar                      from 'theme/Navbar';
import  Table,
        { Row,
          AcceptCommentCell,
          accept,
          comment,
          openModal,
          ActionsCell }             from 'theme/Table';
import  { SaveButton }              from 'theme/Form'
import  Loader                      from 'theme/Loader';
import  Modal                       from 'theme/Modal';
import  Footer                      from 'theme/Footer';
import  { closeModal,
          showToast,
          clearSelection,
          endSelecting }            from 'theme/actions';

import './Participants.sass';


const HEADINGS = [
  {
    key: 'check'
  },
  {
    key: 'lastName',
    label: 'Nazwisko'
  },
  {
    key: 'firstName',
    label: 'Imię'
  },
  {
    key: 'address',
    label: 'Adres',
    className: 'Participants__tableAddress'
  },
  {
    key: 'PESEL',
    label: 'PESEL',
    className: 'Participants__tablePesel'
  },
  {
    key: 'unit',
    label: 'Drużyna'
  },
  {
    key: 'educator',
    label: 'Wychowawca'
  },
  {
    key: 'age',
    label: 'Wiek'
  },
  {
    key: 'meta',
    label: ''
  }
]

const AGE_MAP = {
  below10: 'Poniżej 10 lat',
  above10: 'Powyżej 10 lat',
  above18: 'Dorosły'
}


class ParticipantsList extends Component {  //eslint-disable-line
    constructor() {
      super();
      this.state = {
        isFetching: false
      }
    }

    componentWillUnmount() {
      this.props.clearCachedParticipants();
    }

    componentDidMount() {
      this.setState({isFetching: true});
      const requests = [
        this.props.fetchParticipants(this.props.match.params.projectId),
        this.props.fetchUnits(this.props.match.params.projectId),
        this.props.fetchEducators(this.props.match.params.projectId)
      ]
      Promise.all(requests)
      .then(() => {
        this.setState({isFetching: false});
      });
      if(this.button) {
        this.button.focus(); // hack to enable onpaste when page loads
        this.clipboard = new Clipboard(this.button);
      }
      this.roles = Object.keys(roles)
      .map( key => roles[key].filledBy)
      .reduce( (a, b) => a.concat(b));

      const rolesCount = this.roles
      .reduce( (sum, role) => ({...sum, [role]: (sum[role] || 0) + 1}), {});

      this.deleteRole = Object.keys(rolesCount)
      .reduce( (max, role) => rolesCount[max] >= rolesCount[role] ? max : role);
    }

    submitParticipant (participant) {
      const { projectId } = this.props.match.params;
      return this.props.editParticipant({projectId, participantId: participant.id}, participant)
      .catch((error) => {
        this.props.showToast({message: <div>Nie udało się zapisać pola. Spróbuj ponownie później.</div>, error: true});
        return Promise.reject(error);
      });
    }

    getEditableHeadings() {
      return _.without(_.pluck(HEADINGS, 'key'), 'check', 'meta');
    }

    convertPastedData (data) {
      const converted = Papa.parse(data);
      const headings = this.getEditableHeadings()
      if(converted.data && converted.data.length > 0) {
        if(this.pastedDataHasHeadings(data)) {
          const withHeadings = Papa.parse(data, {header: true});
          return withHeadings.data.map( p => _.pick(p, headings));
        }
        else
          return converted.data.map(c =>
            c.reduce((obj, p, i) => ({
              ...obj,
              [headings[i]]: p
            })
            , {})
          );
      }
    }

    getPastedData (e) {
      return e.clipboardData.getData('Text');
    }

    pastedDataHasHeadings (data) {
      const headings = this.getEditableHeadings();
      const converted = Papa.parse(data);
      return converted.data && converted.data[0].some(d => headings.includes(d));
    }

    paste (e) {
      this.setState({isFetching: true});
      const {projectId} = this.props.match.params;
      const pasted = this.getPastedData(e);
      const data = this.convertPastedData(pasted);

      this.props.addParticipants({projectId}, data)
      .then(() => this.setState({isFetching: false}))
      .catch( error => {
        this.setState({isFetching: false});
        this.props.showToast({
          message: <div>Nie udało się dodać uczestnika. Sprawdź poprawność danych lub spróbuj ponownie później</div>,
          error: true
        });
        return Promise.reject(error);
      });
    }

    pasteToCell (e) {
      const data = this.getPastedData(e);
      const hasHeadings = this.pastedDataHasHeadings(data);
      if(hasHeadings)
        e.preventDefault();
      else
        e.stopPropagation();
    }

    copy () {
      if (this.props.editableTable.selectedIds.length > 1 || this.props.editableTable.selectedProps.length > 1)
        this.button.click();
    }

    deleteParticipants () {
      const {deleteParticipant, match: {params: {projectId}}, showToast} = this.props;
      this.props.checkedParticipantsIds.map( id =>
        deleteParticipant(projectId, id)
        .catch((error) => {
          showToast({
            message: <div>Nie udało się usunąć uczestnika. Sprawdź poprawność danych lub spróbuj ponownie później</div>,
            error: true
          });
          return Promise.reject(error);
        })
      )
    }

    duplicateParticipants() {
      const {participants, checkedParticipantsIds, match, addParticipants, showToast} = this.props;
      const projectId = match.params.projectId;
      const participantsRequests = _.filter(participants, ({id}) => checkedParticipantsIds.includes(id));
      addParticipants({projectId}, participantsRequests)
      .catch((error) => {
        showToast({
          message: <div>Nie udało się dodać uczestnika. Spróbuj ponownie później</div>,
          error: true
        });
        return Promise.reject(error);
      });
    }

    formatSelectedData(participants, selectedProps, selectedIds) {
      const selected = participants
      .filter( ({id}) => selectedIds.includes(id))
      .map( p => selectedProps.reduce(
        (obj, prop) => ({...obj, [prop]: p[prop] || ''})
        , {}
      ));
      return Papa.unparse(
        selected,
        {delimiter: '\t'}
      );
    }

    render() {
      const {
        user,
        selectedParticipant,
        showToast,
        closeModal,
        toggleAcceptParticipant,
        toggleAllParticipants,
        toggleParticipant,
        checkedParticipantsIds,
        commentParticipant,
        addParticipants,
        loadingActions,
        endSelecting,
        editableTable,
        units,
        educators,
        activeProject
      } = this.props;
      const {isFetching} = this.state;
      const projectId = this.props.match.params.projectId;
      const editable = activeProject.state == 'Open';

      const deleteParticipant = (...args) => () => {
        this.props.deleteParticipant(...args)
        .then(closeModal)
        .catch(() => {
          const message = (
            <div>
              Nie udało się usunąć uczestnika. Spróbuj ponownie później
            </div>
          )
          showToast({message, error: true});
        })
      };

      const actions = _.pluck(_.values(loadingActions), 'name');

      const deletingParticipant = actions.includes('DeleteParticipant');
      const addingParticipant = actions.includes('AddParticipant');


      const canEdit = editable && (user.isAdmin || this.roles && this.roles.includes(user.role.name));
      const canDelete = editable && (user.isAdmin || this.deleteRole == user.role.name);
      const canDoActions = canDelete || canEdit;
      const headings = canDoActions
        ? HEADINGS
        : user.role.name == 'Region'
          ? HEADINGS.slice(1)
          : HEADINGS.slice(0, -1).slice(1);

      const unitsOptions = units && units.map( unit => ({label: unit.name, value: unit.name}));

      const participants = this.props.participants
      .map( participant => {
        const peselDate = participant.PESEL && participant.PESEL.slice(0, 6);
        const date = peselDate &&
          ( parseInt(peselDate.slice(2, 4)) <= 12
              ? moment(''.concat('19', peselDate), 'YYYYMDD')
              : moment(''.concat(
                  peselDate.slice(0, 2),
                  (parseInt(peselDate.slice(2, 4)) - 20) < 10 ? '0'.concat(parseInt(peselDate.slice(2, 4)) - 20) : parseInt(peselDate.slice(2, 4)) - 20,
                  peselDate.slice(4)),
                ['YY0MDD', 'YYMMDD'])
          ) || null;
        const age = date &&
          moment().diff(date, 'y') < 10 && 'below10' ||
          moment().diff(date, 'y') >= 18 && 'above18' ||
          moment().diff(date, 'y') >= 10 && 'above10';
        return {...participant, age}
      });

      const [above10, below10] = _.partition(participants, (participant => ['above10', 'above18'].includes(participant.age)));
      const educatorsBelow10 = Math.floor(below10.length / 15);
      const educatorsAbove10 = Math.floor(above10.length / 20);
      const mixedEducators = Math.ceil((below10.length % 15 + above10.length % 20) / 15);
      const educatorsCount = educatorsAbove10 + educatorsBelow10 + mixedEducators;
      const neededEducators = educatorsCount - educators.length;

      return (
        <div
          className="List Participants"
          onMouseUp={endSelecting}
          onPaste={canEdit ? (e => this.paste(e)) : undefined}
        >
          <Header />
          <Navbar />
          <div className="List__container">

            <div className="List__heading">
              <div>
                <h3>Uczestnicy</h3>
                <p>
                  Dodawaj nowe wiersze przyciskiem <i>Dodaj</i>.<br />
                  Możliwe jest wklejanie tabeli z programu excel oraz zaznaczanie konkretnych komórek i kopiowanie ich do nowych wierszy.<br />
                  Edycja jest możliwa po kliknięciu na komórce tabeli.
                </p>
              </div>
              <div className="Participants__btns">
                {canEdit &&
                  <SaveButton
                    className="Participants__action"
                    type="button"
                    onClick={() => this.duplicateParticipants()}
                    disabled={addingParticipant || !checkedParticipantsIds.length}
                    saving={addingParticipant}
                  >Duplikuj</SaveButton>
                }
                {canDelete &&
                  <SaveButton
                    className="Participants__action"
                    type="button"
                    onClick={() => this.deleteParticipants()}
                    disabled={addingParticipant || deletingParticipant || !checkedParticipantsIds.length}
                    saving={deletingParticipant}
                  >Usuń</SaveButton>
                }
              </div>
            </div>

            {participants.length > 0 &&
              <div className="Participants__row">
                <div className="Participants__card">
                  <Loader loading={isFetching} />
                  <div className={cx("Participants__cardContainer", {blur: isFetching})}>
                    {participants.length > 0 &&
                      <div className="Participants__summary">
                        <div className="Participants__count">
                          <h4>{below10.length}</h4>
                          <h5>poniżej 10 roku życia</h5>
                        </div>
                        <div className="Participants__count">
                          <h4>{above10.length}</h4>
                          <h5>powyżej 10 roku życia</h5>
                        </div>
                      </div>
                    }
                  </div>
                </div>
                <div className="Participants__card">
                  <Loader loading={isFetching} />
                  <div className={cx("Participants__cardContainer", {blur: isFetching})}>
                    <div className="Participants__summary">
                      <div className="Participants__count">
                        <h4>{educators.length}</h4>
                        <h5>wychowawcy</h5>
                      </div>
                      <div className="Participants__count">
                        <h4>{neededEducators >= 0 ? neededEducators : 0}</h4>
                        <h5>brakujący wychowawcy</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }

            <div className="Participants__card">
              <Loader loading={isFetching} />
              <div className={cx("Participants__cardContainer", {blur: isFetching})}>
                <Table
                  headings={headings}
                  toggle={canDoActions && (() => toggleAllParticipants(participants))}
                  checked={canDoActions && participants.length == checkedParticipantsIds.length}
                  accept={editable && accept(toggleAcceptParticipant, projectId, HEADINGS)}
                  comment={comment(commentParticipant, projectId)}
                  onSubmit={(p) => this.submitParticipant(p)}
                  orderedIds={_.pluck(participants, 'id')}
                  onCopy={() => this.copy()}
                  onPaste={canEdit ? (e => this.pasteToCell(e)) : undefined}
                  permissions={roles}
                  disabled={!editable}
                >
                  {participants.map( participant => {
                    const editing = loadingActions[participant.id] && loadingActions[participant.id].name == 'EditParticipant' && loadingActions[participant.id];
                    const accepting = loadingActions[participant.id] && loadingActions[participant.id].name == 'AcceptParticipant';
                    const deleting = loadingActions[participant.id] && loadingActions[participant.id].name == 'DeleteParticipant';

                    return (
                      <Row
                        key={participant.id}
                        loading={accepting || deleting}
                      >
                        {canDoActions &&
                          <AcceptCommentCell
                            hideIcons
                            className="Participants__check"
                            onClick={() => toggleParticipant(participant)}
                          >
                            <input type="checkbox" checked={checkedParticipantsIds.includes(participant.id)} />
                          </AcceptCommentCell>
                        }
                        <AcceptCommentCell
                          data={participant}
                          editable
                          loading={deleting || accepting || editing && editing.prop == 'lastName'}
                          loaderIcon={editing && editing.prop == 'lastName'}
                        />
                        <AcceptCommentCell
                          data={participant}
                          editable
                          loading={deleting || accepting || editing && editing.prop == 'firstName'}
                          loaderIcon={editing && editing.prop == 'firstName'}
                        />
                        <AcceptCommentCell
                          data={participant}
                          editable
                          loading={deleting || accepting || editing && editing.prop == 'address'}
                          loaderIcon={editing && editing.prop == 'address'}
                        />
                        <AcceptCommentCell
                          data={participant}
                          editable
                          loading={deleting || accepting || editing && editing.prop == 'PESEL'}
                          loaderIcon={editing && editing.prop == 'PESEL'}
                        />
                        <AcceptCommentCell
                          data={participant}
                          editable
                          loading={deleting || accepting || editing && editing.prop == 'unit'}
                          loaderIcon={editing && editing.prop == 'unit'}
                          options={unitsOptions}
                        />
                        <AcceptCommentCell
                          data={participant}
                          editable
                          loading={deleting || accepting || editing && editing.prop == 'educator'}
                          loaderIcon={editing && editing.prop == 'educator'}
                        />
                        <AcceptCommentCell
                          className="Participants__age"
                          data={participant}
                          loading={deleting || accepting}
                          hideIcons
                        >
                          {AGE_MAP[participant.age]}
                        </AcceptCommentCell>
                        {(canDoActions || user.role.name == 'Region') &&
                          <ActionsCell
                            className={cx("Participants__actions", {acceptable: user.isAdmin})}
                            data={participant}
                            del={canDelete && openModal('deleteParticipant')}
                            acceptable={user.isAdmin || user.role.name == 'Region'}
                          />
                        }
                      </Row>
                    )
                  })}
                </Table>
                {canEdit &&
                  <SaveButton
                    type="button"
                    className="Participants__add"
                    onClick={() => addParticipants({projectId}, [{}])}
                    disabled={addingParticipant}
                    saving={addingParticipant}
                  >Dodaj</SaveButton>
                }
                <textarea
                  id="Participants__text"
                  style={{position: 'absolute', left: '9999px'}}    //hidden textarea for clipboard use, cannot be {visibility: hidden} or {display: none} because of browser security
                  value={`${this.formatSelectedData(participants, editableTable.selectedProps, editableTable.selectedIds)}`}
                  readOnly
                />
                <button
                  style={{display: 'none'}}
                  ref={b => this.button = b}
                  data-clipboard-target="#Participants__text"
                />
              </div>
            </div>
          </div>
          <Modal
            className="Participants__modal"
            header={<h4>Uwaga!</h4>}
          >
            <p>
              Czy na pewno chcesz usunąć uczestnika?
            </p>
            <div className="Participants__modalActions">
              <button
                className="Participants__modalDiscard"
                onClick={closeModal}
              >Anuluj</button>
              <button
                className="Participants__modalOk"
                onClick={deleteParticipant(projectId, selectedParticipant.id)}
              >Usuń</button>
            </div>
          </Modal>
          <Footer />
        </div>
      )
    }
}

ParticipantsList.propTypes = {
  participants: PT.arrayOf(PT.shape({
    id: PT.string
  })),
  fetchParticipants: PT.func,
  // isFetching: PT.bool,
  selectedParticipant: PT.shape({
    id: PT.string,
    name: PT.string
  }),
  deleteParticipant: PT.func.isRequired,
  closeModal: PT.func.isRequired,
  showToast: PT.func.isRequired
}

const mapStateToProps = ({data: {participants, units, educators, activeProject}, isFetching, modal, participants: {participantsIds}, editableTable, auth: {user}}) => ({
  participants: participants || [],
  educators: educators || [],
  selectedParticipant: modal.payload || {},
  checkedParticipantsIds: participantsIds,
  loadingActions: isFetching.objects,
  editableTable,
  user,
  units,
  activeProject: activeProject || {}
})

export default withRouter(connect(
  mapStateToProps,
  {
    fetchParticipants,
    fetchUnits,
    fetchEducators,
    deleteParticipant,
    showToast,
    closeModal,
    toggleAcceptParticipant,
    editParticipant,
    toggleAllParticipants,
    toggleParticipant,
    commentParticipant,
    addParticipants,
    clearSelection,
    endSelecting,
    clearCachedParticipants
  }
)(ParticipantsList))
