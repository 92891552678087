import React, { Component }                       from 'react';
import PT                                         from 'prop-types';
import cx                                         from 'classnames';
import _                                          from 'underscore';
import deep                                       from 'deep-diff';
import { connect }                                from 'react-redux';
import { withRouter }                             from 'react-router';
import { fetchCommand, editCommand, addCommand }  from 'modules/commands/actions';
import { fetchProject }                           from 'modules/projects/actions';
import { download }                               from 'modules/files/actions';
import { showToast }                              from 'theme/actions';
import Header                                     from 'theme/Header';
import Navbar                                     from 'theme/Navbar';
import Footer                                     from 'theme/Footer';


import 'theme/common-views.sass';

import CommandForm                                from './CommandForm'


class CommandDetails extends Component {

    componentDidMount() {
      const {projectId, commandId} = this.props.match.params;
      if(projectId && commandId)
        this.props.fetchCommand(projectId, commandId);
    }

    render() {
      const {isFetching, editCommand, addCommand, showToast, match, command, user, activeProject} = this.props;
      const {projectId, commandId} = match.params;
      const edit = commandId != null;
      const initialForm = activeProject && activeProject.description ? {from: activeProject.description.from, to: activeProject.description.to} : null;

      const submit = (values) => {
        const diff = edit && _.pluck(deep.diff(command, values), 'path');
        const vals = diff ? _.pick(values, ..._.map(diff, _.first)) : values;
        const func = edit ? editCommand : addCommand;
        return func({projectId, id: commandId}, vals)
        .then(() => {
          const message = <div>Zapisano komendanta</div>
          showToast({message});
        })
        .catch(() => {
          const message = (
            <div>
              Nie udało się zapisać komendanta. Spróbuj ponownie później
            </div>
          )
          showToast({message, error: true});
        })
      }

      return (
        <div className="Details">
          <Header />
          <Navbar />
          <div className={cx("Details__container", {blur: isFetching})}>

            <div className="Details__heading">
              <h3>Komendant</h3>
              <p>
                {edit
                  ? 'Edycja komendanta'
                  : 'Nowy komendant'
                }
              </p>
            </div>

            <CommandForm
              initialValues={edit ? command : initialForm}
              onSubmit={submit}
              loading={isFetching}
              user={user}
              disabled={activeProject.state != 'Open'}
            />
          </div>
          <Footer />
        </div>
      )
    }
}

CommandDetails.propTypes = {
  command: PT.shape({
    id: PT.string
  }),
  fetchCommand: PT.func,
  editCommand: PT.func,
  addCommand: PT.func,
  showToast: PT.func.isRequired
}

const mapStateToProps = ({data: {command, files, activeProject}, isFetching, auth}) => ({
  command,
  files,
  isFetching: isFetching.global,
  user: auth.user,
  activeProject: activeProject || {}
})

export default withRouter(connect(
  mapStateToProps,
  { fetchCommand, editCommand, addCommand, showToast, download, fetchProject }
)(CommandDetails))
