export default {
  firstName: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  lastName: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  PESEL: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  phone: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  email: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  address: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  zipCode: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  city: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  rank: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  instructorRank: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  from: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  to: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  unit: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  paidDues: {
    filledBy: ['Region', 'Office'],
    acceptedBy: [],
    commentable: true
  },
  educatorCertificate: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  instructorCertificate: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  KRKCertificate: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  medicalCertificate: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  DDStatement: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  voluntaryContract: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  accountedDocuments: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
  },
  accountedMedicalCertificate: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
  },
  accountedKRKCertificate: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
  },
  accountedDDStatement: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
  },
  accountedZMStatement: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
  }
}
// Region
// Office
// Banner
// Detachment
// CampCommander
