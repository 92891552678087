import React from 'react';
import Header from 'modules/theme/Header';


import './NotFound.sass'


export default (() => (
  <div className="NotFound">
    <Header />
    <div className="NotFound__container">
      <h2>Strony nie znaleziono</h2>
      <a href="/projects">
        Wróć do projektów
      </a>
    </div>
  </div>
))
