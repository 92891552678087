import React, { Component }                             from 'react';
import PT                                               from 'prop-types';
import cx                                               from 'classnames';
import { connect }                                      from 'react-redux';
import _                                                from 'underscore';
import deep                                             from 'deep-diff';
import { withRouter }                                   from 'react-router';
import { fetchLifeGuard, editLifeGuard, addLifeGuard }  from 'modules/lifeGuards/actions';
import { fetchFiles }                                   from 'modules/files/actions';
import { fetchProject }                                 from 'modules/projects/actions';
import { showToast }                                    from 'theme/actions';
import Header                                           from 'theme/Header';
import Navbar                                           from 'theme/Navbar';
import Footer                                           from 'theme/Footer';

import 'theme/common-views.sass';

import LifeGuardForm                                    from './LifeGuardForm'


class LifeGuardDetails extends Component {

    componentDidMount() {
      const {projectId, lifeGuardId} = this.props.match.params;
      if(projectId && lifeGuardId)
        this.props.fetchLifeGuard(projectId, lifeGuardId);
    }

    render() {
      const {isFetching, editLifeGuard, addLifeGuard, showToast, match, lifeGuard, user, activeProject} = this.props;
      const {projectId, lifeGuardId} = match.params;
      const edit = lifeGuardId != null;
      const initialForm = activeProject.description ? {from: activeProject.description.from, to: activeProject.description.to} : null;

      const submit = (values) => {
        const diff = edit && _.pluck(deep.diff(lifeGuard, values), 'path');
        const vals = diff ? _.pick(values, ..._.map(diff, _.first)) : values;
        const func = edit ? editLifeGuard : addLifeGuard;
        return func({projectId, id: lifeGuardId}, vals)
        .then(() => {
          const message = <div>Zapisano ratownika</div>
          showToast({message});
        })
        .catch(() => {
          const message = (
            <div>
              Nie udało się zapisać ratownika. Spróbuj ponownie później
            </div>
          )
          showToast({message, error: true});
        })
      }

      return (
        <div className="LifeGuard">
          <Header />
          <Navbar />
          <div className={cx("Details__container", {blur: isFetching})}>

            <div className="Details__heading">
              <h3>Ratownik wodny</h3>
              <p>
                {edit
                  ? 'Edycja ratownika'
                  : 'Nowy ratownik'
                }
              </p>
            </div>

            <LifeGuardForm
              initialValues={edit ? lifeGuard : initialForm}
              onSubmit={submit}
              loading={isFetching}
              user={user}
              disabled={activeProject.state != 'Open'}
            />
          </div>
          <Footer />
        </div>
      )
    }
}

LifeGuardDetails.propTypes = {
  lifeGuard: PT.shape({
    id: PT.string
  }),
  fetchLifeGuard: PT.func,
  editLifeGuard: PT.func,
  addLifeGuard: PT.func,
  showToast: PT.func.isRequired
}

const mapStateToProps = ({data: {lifeGuard, files, activeProject}, isFetching, auth: {user}}) => ({
  lifeGuard,
  files,
  isFetching: isFetching.global,
  user,
  activeProject: activeProject || {}
})

export default withRouter(connect(
  mapStateToProps,
  { fetchLifeGuard, editLifeGuard, addLifeGuard, showToast, fetchFiles, fetchProject }
)(LifeGuardDetails))
