import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { requestReset, reset } from 'modules/auth/actions';
import { Input } from 'theme/Form';

import './Reset.sass'


const Component = reduxForm({
  form: 'ResetForm'
})(
  ({handleSubmit, err, submitSucceeded, submitting, mailToken, pristine}) => {
    return(
      <div className="Reset">
        <div className="Reset__card">
          <h3>{mailToken ? 'Resetowanie hasła' : 'Odzyskiwanie hasła'}</h3>
          {!mailToken &&
            <p>Wprowadź adres email powiązany z Twoim kontem. Dalsze instrukcje otrzymasz w wiadomości.</p>
          }
          <form onSubmit={handleSubmit}>
            {mailToken &&
              <Field
                name="password"
                type="password"
                component={Input}
                placeholder="Nowe hasło"
                className="Reset__field"
              />
            }
            {mailToken &&
              <Field
                name="password2"
                type="password"
                component={Input}
                placeholder="Powtórz hasło"
                className="Reset__field"
              />
            }
            {!mailToken &&
              <Field
                name="email"
                type="text"
                component={Input}
                placeholder="Email"
                className="Reset__field"
              />
            }
            {err &&
              <span className="Reset__error">Coś poszło nie tak. Spróbuj ponownie później.</span>
            }
            <div className="Reset__btnContainer">
              {(mailToken && !submitting && submitSucceeded)
                ?
                  <Link
                    to="/signin"
                    className="btn btn_primary btn_secondary"
                  >Zmieniono hasło</Link>
                :
                  <button type="submit" className="btn btn_primary btn_secondary" disabled={submitting || submitSucceeded || pristine}>
                    {submitSucceeded && !submitting ? 'Wysłano' : mailToken ? 'Zresetuj' : 'Wyślij'}
                    {
                      submitting == true && <i className="fa fa-spin fa-spinner" /> ||
                      submitSucceeded == true && <i className="fa fa-check" />
                    }
                  </button>
              }
            </div>
          </form>
        </div>
        <Link
          className="Reset__back"
          to="/signin"
        >Powrót do logowania</Link>
        <div className="Reset__img">
          <div>
            <img src="/assets/halihaz_white.png" alt="Okręg Mazowiecki ZHR" />
          </div>
          <div>
            <p>Projekt współfinansuje<br />m. st. Warszawa</p>
            <img src="/assets/logo_wspolfinansowanie.png" alt="Wpółfinansowan przez m. st. Warszawa" />
          </div>
        </div>
      </div>
    )
  }
)

const mapStateToProps = ({error, auth}, {match}) => {
  const mailToken = match.params.mailToken;
  return {
    err: error,
    isFetching: auth.loading,
    mailToken,
    validate: values => ({
      password: mailToken && !values.password && 'Wymagane',
      email: !mailToken && !values.email && 'Wymagane',
      password2: mailToken && (!values.password2 && 'Wymagane' || values.password2 != values.password && 'Hasła muszą się zgadzać')
    })
  }
}

const mapDispatchToProps = (dispatch, {match}) => ({
  onSubmit: match.params.mailToken
    ? ({password}) => dispatch(reset({password, mailToken: match.params.mailToken}))
    : (values) => dispatch(requestReset(values))
})

const Reset = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Component))

export default Reset;