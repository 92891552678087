import React from 'react';
import PT from 'prop-types';
import cx from 'classnames';
import _ from 'underscore';
import debounce from 'debounce-promise';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import Comments from 'modules/theme/Comments';
import Loader from 'modules/theme/Loader';
import { showComments, editCell, startSelecting, endSelecting, select, showToast } from 'modules/theme/actions';

import './Table.sass';

export * from './utils';

const Table = ({ headings, children, toggle, checked, responsive, className, ...rest}) => (
  <div className={cx("Table", className, {responsive: responsive})}>
    <table>
      <thead>
        <tr className="Table__check">
          {toggle && checked !== null &&
            <th>
              <input
                className="Table_checkbox"
                type="checkbox"
                onChange={toggle}
                checked={checked}
              />
            </th>
          }
          {headings && headings.map( (heading, i) => (
            heading && heading.key != 'check' &&
              <th key={i}>
                {typeof heading == 'string' && heading || heading.label}
              </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {React.Children.map(children, child => (
          child.type == 'tr' && <tr {...child.props} /> ||
          <child.type
            headings={headings}
            {...child.props}
            {...rest}
          />
        ))}
      </tbody>
    </table>
  </div>
)

Table.propTypes = {
  headings: PT.arrayOf(PT.oneOfType([
    PT.shape({
      key: PT.string,
      label: PT.string
    }),
    PT.string
  ])),
  checked: PT.bool,
  responsive: PT.bool,
  className: PT.string
}

export default Table;

export const Row = ({children, headings, onClick, loading, className, ...rest}) => {
  const filteredChildren = React.Children.toArray(children);
  return (
    <tr onClick={!loading && onClick || undefined} className={cx({loading}, className)}>
      {React.Children.map(filteredChildren, (child, i) => {
        return (
          child && child.type == 'td' && <td {...child.props} /> ||
          child && <child.type
            loading={loading}
            headings={headings}
            name={headings && headings.length > 0 && headings[i] && headings[i].key}
            {...child.props}
            {...rest}
          />
        )
      })}
    </tr>
  )
}

Row.propTypes = {
  headings: PT.arrayOf(PT.oneOfType([
    PT.shape({
      key: PT.string,
      label: PT.string
    }),
    PT.string
  ]))
}

export const CellAction = connect(null, {showToast})(
  ({type, onClick, meta, className, disabled, showToast}) => {
    const accept = (e) => onClick(e)
    .catch(() => {
      const message = (
        <div>
          Nie udało się wykonać akcji. Spróbuj ponownie później.
        </div>
      )
      showToast({message, error: true});
    })

    return (
      <div className={cx(`CellAction CellAction__${type}`, className)}>
        <button
          className={cx(
            {selected:
              type == 'comment' && meta && meta.length > 0 ||
              type == 'accept' && meta == 'Approved'
            },
            {rejected: type == 'accept' && meta == 'Rejected'}
          )}
          onClick={type == 'accept' ? accept : onClick}
          disabled={disabled}
        >
          <span
            className={cx(
              {"fa fa-check": type == 'accept' && meta != 'Rejected'},
              {"fa fa-times": type == 'accept' && meta == 'Rejected'},
              {"fa fa-comment": type == 'comment'}
            )}
          />
          {type == 'comment' && meta && meta.length > 0 &&
            <span className="CellAction__comments">
              {meta.length}
            </span>
          }
        </button>
      </div>
    )
  }
)

class EditableCellContentField extends React.Component {
  constructor ({input}) {
    super();
    this.state = {value: input.value}
    this.onChange = this.onChange.bind(this);
  }

  onChange (e) {
    e.preventDefault();
    e.persist();
    const value = e.target.value;
    this.setState({value});
    return this.props.change(value)
    .then(() => this.props.input.onChange(value))
    .catch(() => {
      this.props.showToast({
        message: <div>Nie udało się zapisać pola. Spróbuj ponownie później</div>,
        error: true
      })
      this.setState({value: this.props.input.value})
    });
  }

  disableSubmitOnReturn (e) {
    if (e.key === 'Enter') e.preventDefault();
  }

  render () {
    const {input, disabled} = this.props;
    return (
      <input
        {...input}
        value={this.state.value != input.value ? this.state.value : input.value}
        onChange={this.onChange}
        onKeyPress={this.disableSubmitOnReturn}
        tabIndex="1" //eslint-disable-line
        disabled={disabled}
      />
    )
  }
}

export const EditableCellContent = connect(
  (state, ownProps) => {
    const id = formValueSelector(ownProps.form)(state, 'id');
    return {
      editingForm: state.editableTable.form,
      id,
      selected: state.editableTable.selectedIds.includes(id) && state.editableTable.selectedProps.includes(ownProps.name)
    }
  },
  {editCell, startSelecting, endSelecting, select, showToast}
)(reduxForm({
  enableReinitialize: true
})(
  class extends React.Component {   //eslint-disable-line
    constructor({onSubmit, name, type}) {
      super();
      this.onSubmit = onSubmit;
      this.name = name;
      this.submit = (value, id) => this.onSubmit({[this.name]: value, id});
      this.change = type == 'checkbox' ? this.submit : debounce(this.submit, 1000);
      this.onChange = this.onChange.bind(this);
    }

    onChange (value) {
      return this.change(value, this.props.id);
    }

    render () {
      const {name, onCopy, onPaste, selected, editingForm, form, editCell, submitting, startSelecting, endSelecting, select, id, headings, orderedIds, disabled, ...rest} = this.props;
      return (
        <div
          className={cx(
            "EditableCellContent",
            {editing: editingForm == form || selected}
          )}
          onMouseDown={() => startSelecting(id, name, headings, orderedIds)}
          onMouseOver={() => select(id, name)}
          onMouseUp={endSelecting}
          onCopy={onCopy}
          onPaste={onPaste}
        >
          <form className={cx({submitting})}>
            <Field
              {...rest}
              name={name}
              component={EditableCellContentField}
              onKeyPress={(e) => e.key == 'Enter' && e.preventDefault()}
              change={this.onChange}
              onFocus={() => editCell(form)}
              onBlur={() => editCell()}
              disabled={disabled || submitting}
            />
          </form>
        </div>
      )
    }
  }
))

export const AcceptCommentCell = connect(
  ({comments, auth}) => ({...comments, user: auth.user}),
  { showComments }
)(
  ({
    children,
    className,
    accept,
    data,
    name,
    visible,
    obj,
    prop,
    commentsPosition,
    hideIcons,
    accepted,
    comment,
    showComments,
    loading,
    loaderIcon,
    noBlur,
    editable,
    onClick,
    headings,
    user,
    permissions,
    disabled,
    ...rest
  }) => {
    const permission = permissions && name && permissions[name];
    const state = accepted || data && data.meta && data.meta[name] && data.meta[name].state;
    const nextState = state == 'Approved' ? 'Rejected' : state == 'Rejected' ? 'Unapproved' : 'Approved';
    const acceptRoles = permission && permission.acceptedBy;
    const commentable = permission && permission.commentable;
    const editRoles = permission && permission.filledBy;
    const allowEdit = user && (user.isAdmin || (
      editRoles && user.role && (
        editRoles.includes(user.role.name)
      ))
    )
    const acceptable = accept && acceptRoles && user && user.role && (
      acceptRoles.includes(user.role.name) ||
      user.isAdmin && acceptRoles.includes('Admin')
    );

    const acceptFunc = (e) => {
      e.stopPropagation();
      return accept(data, nextState, name);
    }

    const showCommentsFunc = (e) => {
      e.stopPropagation();
      return showComments(data, name);
    }

    return (
      <td
        className={cx(
          "AcceptCommentCell",
          className,
          state,
          {blur: !noBlur && loading},
          {editable},
          {disabledAccept: !acceptable}
        )}
        onClick={onClick}
      >
        <div className="AcceptCommentCell__content">
          {editable &&
            <EditableCellContent
              {...rest}
              name={name}
              form={`${data ? data.id : 'new'}::${name}`}
              initialValues={_.pick(data, [name, 'id'])}
              headings={_.pluck(headings, 'key')}
              disabled={disabled || !allowEdit}
            />
          }
          {children}
          {!hideIcons &&
            <CellAction
              type="accept"
              meta={state}
              onClick={acceptFunc}
              disabled={!acceptable}
            />
          }
          {!hideIcons && commentable &&
            <CellAction
              type="comment"
              meta={data && data.meta && data.meta[name] && data.meta[name].comments}
              onClick={showCommentsFunc}
            />
          }
          {!hideIcons && visible &&
          obj && data && obj.id == data.id &&
          ((!prop && !name) || prop == name) &&
            <Comments
              comments={data && data.meta &&
                ((!name && data.meta.comments) ||
                data.meta[name] && data.meta[name].comments)
              }
              position={commentsPosition}
              comment={comment && comment(data, name)}
            />
          }
          <Loader loading={loading} noIcon={!loaderIcon} />
        </div>
      </td>
    )
  }
)

export const ActionsCell = ({editPath, del, accept, data, className, edit, loading, acceptable, permissions}) => {
  const acceptableProps = permissions
    &&  Object.keys(permissions)
        .filter(key => permissions[key] && permissions[key].acceptedBy && permissions[key].acceptedBy.length > 0);
  const accepted = accept && acceptableProps && acceptableProps.reduce(
    (a, b) => a && data.meta && data.meta[b] && data.meta[b].state == 'Approved',
    true
  );

  const e = (data) => (event) => {
    event.stopPropagation();
    return edit(data);
  }
  const d = (data) => (event) => {
    event.stopPropagation();
    return del(data);
  }
  const a = (data) => (event) => {
    event.stopPropagation();
    return accept(data, 'Approved', ...acceptableProps);
  }

  return (
    <AcceptCommentCell
      className={cx("Table__actions", className, {loading, accepted})}
      hideIcons
      data={data}
      commentsPosition="left"
      loading={loading}
      loaderIcon
      noBlur
    >
      <div className={cx("Table__actionsBtns", {blur: loading})}>
        {(edit || editPath) &&
          <div className="Table__actionsBtnContainer">
            {editPath &&
              <Link
                to={editPath}
                className="Table__edit"
                onClick={(event) => event.stopPropagation()}
              >
                <span className="fa fa-pencil" />
              </Link>
            }
            {edit &&
              <button
                className="Table__edit"
                onClick={e(data)}
              >
                <span className="fa fa-pencil" />
                {/* <Tooltip right>Usuń</Tooltip> */}
              </button>
            }
            {/* <Tooltip>Edytuj</Tooltip> */}
          </div>
        }
        {del &&
          <div className="Table__actionsBtnContainer">
            <button
              className="Table__delete"
              onClick={d(data)}
            >
              <span className="fa fa-trash" />
              {/* <Tooltip right>Usuń</Tooltip> */}
            </button>
          </div>
        }
        {accept && acceptable &&
          <div className="Table__actionsBtnContainer">
            <button
              className={cx("Table__action", {accepted})}
              onClick={a(data, 'Approved')}
            >
              <span className="fa fa-check" />
              {accepted ? 'Zatwierdzono' : 'Zatwierdź'}
              {/* <Tooltip>Zatwierdź</Tooltip> */}
            </button>
          </div>
        }
      </div>
    </AcceptCommentCell>
  )
}
