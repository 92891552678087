import api, { pickAllowedProps } from 'api';
import { push } from 'react-router-redux';
import { pick, omit, intersection } from 'underscore';
import { requestSuccess, createRequest, handleError } from 'actions/requests';
import { saveFiles, matchFiles } from 'modules/files/actions';
import roles from './roles';

const FILE_FIELDS = ['schedule'];


export function fetchUnits(projectId, opts = {loader: true}) {
  return (dispatch) => {
    opts && opts.loader && dispatch(createRequest({global: true, name: 'units'}));
    return api({
      method: 'get',
      url: `/projects/${projectId}/units`
    })
    .then((response) => {
			dispatch(requestSuccess(response, 'units', opts.id));
			return response.data;
		})
    .catch(handleError(dispatch));
  }
}

export function fetchUnit(projectId, id) {
  return (dispatch) => {
    dispatch(createRequest());
    return api({
      method: 'get',
      url: `/projects/${projectId}/units/${id}`
    })
    .then((response) => dispatch(matchFiles(response, FILE_FIELDS, 'unit')))
    .catch(handleError(dispatch));
  }
}

export function addUnit({projectId}, unit) {
  const allowedProps = pickAllowedProps(roles);
  return (dispatch) => {
    dispatch(createRequest());
    return saveFiles(unit, intersection(FILE_FIELDS, allowedProps))
    .then( filesObj => {
      const req = pick(omit(unit, FILE_FIELDS), allowedProps);
      return api({
        method: 'post',
        url: `/projects/${projectId}/units`,
        data: {
          ...req,
          ...filesObj
        }
      })
      .then((response) => {
        dispatch(matchFiles(response, FILE_FIELDS, 'unit'));
        dispatch(push(`/projects/${projectId}/units`));
      })
    })
    .catch(handleError(dispatch));
  }
}

export function editUnit({projectId, unitId, disableRedirect}, unit) {
  const allowedProps = pickAllowedProps(roles);
  return (dispatch) => {
    dispatch(createRequest({name: 'editUnit'}));
    return saveFiles(unit, intersection(FILE_FIELDS, allowedProps))
    .then( filesObj => {
      const req = pick(omit(unit, FILE_FIELDS), allowedProps);
      return api({
        method: 'patch',
        url: `/projects/${projectId}/units/${unitId}`,
        data: {
          ...req,
          ...filesObj
        }
      })
      .then((response) => {
        dispatch(matchFiles(response, FILE_FIELDS, 'unit'));
        !disableRedirect && dispatch(push(`/projects/${projectId}/units`));
      })
    })
    .catch(handleError(dispatch));
  }
}

export function deleteUnit(projectId, id) {
  return (dispatch) => {
    dispatch(createRequest({name: 'DeleteUnit', id}));
    return api({
      method: 'delete',
      url: `/projects/${projectId}/units/${id}`
    })
    .then(() => dispatch(fetchUnits(projectId, {loader: false, id})))
    .catch(handleError(dispatch, id));
  }
}

export function toggleAcceptUnit(projectId, unit, state, ...props) {
  return (dispatch) => {
    dispatch(createRequest({name: 'AcceptUnit', id: unit.id}));
    const prom = props.map( prop => api({
      method: 'put',
      url: `/projects/${projectId}/units/${unit.id}/${prop}/state`,
      data: {state}
    }))
    return Promise.all(prom)
    .then( () => dispatch(fetchUnits(projectId, {loader: false, id: unit.id})))
    .catch(handleError(dispatch, unit.id));
  }
}

export function commentUnit(projectId, unit, prop, data) {
  return (dispatch) => {
    dispatch(createRequest({name: 'CommentUnit', id: unit.id}));
    return api({
      method: 'post',
      url: `/projects/${projectId}/units/${unit.id}/${prop}/comments`,
      data
    })
    .then( () => dispatch(fetchUnits(projectId, {loader: false, id: unit.id})))
    .catch(handleError(dispatch, unit.id));
  }
}