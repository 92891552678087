import React, { Component }                       from 'react';
import PT                                         from 'prop-types';
import cx                                         from 'classnames';
import { connect }                                from 'react-redux';
import _                                          from 'underscore';
import deep                                       from 'deep-diff';
import { withRouter }                             from 'react-router';
import { fetchCook, editCook, addCook }           from 'modules/cooks/actions';
import { fetchFiles }                             from 'modules/files/actions';
import { fetchProject }                           from 'modules/projects/actions';
import { showToast }                              from 'theme/actions';
import Header                                     from 'theme/Header';
import Navbar                                     from 'theme/Navbar';
import Footer                                     from 'theme/Footer';

import 'theme/common-views.sass';

import CookForm                                   from './CookForm'


class CookDetails extends Component {

    componentDidMount() {
      const {projectId, cookId} = this.props.match.params;
      if(projectId && cookId)
        this.props.fetchCook(projectId, cookId);
    }

    render() {
      const {isFetching, editCook, addCook, showToast, match, cook, user, activeProject} = this.props;
      const {projectId, cookId} = match.params;
      const edit = cookId != null;
      const initialForm = activeProject.description ? {from: activeProject.description.from, to: activeProject.description.to} : null;

      const submit = (values) => {
        const diff = edit && _.pluck(deep.diff(cook, values), 'path');
        const vals = diff ? _.pick(values, ..._.map(diff, _.first)) : values;
        const func = edit ? editCook : addCook;
        return func({projectId, id: cookId}, vals)
        .then(() => {
          const message = <div>Zapisano kucharza</div>
          showToast({message});
        })
        .catch(() => {
          const message = (
            <div>
              Nie udało się zapisać kucharza. Spróbuj ponownie później
            </div>
          )
          showToast({message, error: true});
        })
      }

      return (
        <div className="Cook">
          <Header />
          <Navbar />
          <div className={cx("Details__container", {blur: isFetching})}>

            <div className="Details__heading">
              <h3>Kucharz</h3>
              <p>
                {edit
                  ? 'Edycja kucharza'
                  : 'Nowy kucharz'
                }
              </p>
            </div>

            <CookForm
              initialValues={edit ? cook : initialForm}
              onSubmit={submit}
              loading={isFetching}
              user={user}
              disabled={activeProject.state != 'Open'}
            />
          </div>
          <Footer />
        </div>
      )
    }
}

CookDetails.propTypes = {
  cook: PT.shape({
    id: PT.string
  }),
  fetchCook: PT.func,
  editCook: PT.func,
  addCook: PT.func,
  showToast: PT.func.isRequired
}

const mapStateToProps = ({data: {cook, files, activeProject}, isFetching, auth: {user}}) => ({
  cook,
  files,
  isFetching: isFetching.global,
  user,
  activeProject: activeProject || {}
})

export default withRouter(connect(
  mapStateToProps,
  { fetchCook, editCook, addCook, showToast, fetchFiles, fetchProject }
)(CookDetails))
