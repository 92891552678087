import React, { Component } from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import cx from 'classnames';
import { fetchUsers, addUser, editUser, deleteUser } from 'modules/users/actions';
import Header from 'theme/Header';
import Table, { Row, AcceptCommentCell, ActionsCell } from 'theme/Table';
import Loader from 'theme/Loader';
import Modal from 'theme/Modal';
import Footer from 'theme/Footer';
import { openModal, closeModal, showToast } from 'theme/actions';
import UserForm from './UserForm';

import './Users.sass';


class UsersList extends Component {

    componentDidMount() {
      this.props.fetchUsers();
    }

    render() {
      const {isFetching, openModal, loadingActions, closeModal, addUser, selectedUser, editUser, showToast, modalName} = this.props;
      const deleteUser = (userId) => {
        this.props.deleteUser(userId)
        .then(closeModal())
        .catch(() => {
          const message = (
            <div>
              Nie udało się usunąć użytkownika. Spróbuj ponownie później
            </div>
          )
          showToast({message, error: true});
        })
      }

      const submit = (values) => {
        const func = selectedUser ? editUser : addUser;
        return func(values, selectedUser && selectedUser.id)
        .then(() => {
          closeModal();
          const message = <div>Zapisano użytkownika</div>
          showToast({message});
        })
        .catch( error => {
          const message = (
            <div>
              {error.status == 409
                ? 'Użytkownik o padanym adresie mailowym już istnieje'
                : 'Nie udało się zapisać użytkownika. Spróbuj ponownie później'
              }
            </div>
          )
          showToast({message, error: true});
        })
      }

      const users = this.props.users && this.props.users.sort((a, b) => a.name.localeCompare(b.name));


      return (
        <div className="Users">
          <Header />
          <div className="List__container">

            <div className="List__heading">
              <div>
                <h3>Użytkownicy</h3>
                <p>Zarządzaj użytkownikami aplikacji</p>
              </div>
              <button
                onClick={() => openModal('addUser')}
                className="Users__add"
              >Nowy</button>
            </div>


            <div className="Users__card">
              <Loader loading={isFetching} />
              <div className={cx("Users__cardContainer", {blur: isFetching})}>
                <Table
                  headings={['Nazwa', 'Email', 'Admin', ' ']}
                >
                  {users && users.map((user) => {
                    const loading = loadingActions[user.id] && ['EditUser', 'DeleteUser'].includes(loadingActions[user.id].name);
                    return (
                      <Row
                        key={user.id}
                        hideIcons
                        loading={loading}
                      >
                        <AcceptCommentCell>
                          {user.name}
                        </AcceptCommentCell>
                        <AcceptCommentCell>
                          {user.email}
                        </AcceptCommentCell>
                        <AcceptCommentCell className="Users__admin">
                          {user.isAdmin &&
                            <span className="fa fa-check" />
                          }
                        </AcceptCommentCell>
                        <ActionsCell
                          className="Users__actions"
                          del={() => openModal('deleteUser', user)}
                          edit={() => openModal('editUser', user)}
                        />
                      </Row>
                    )
                  })}
                </Table>
              </div>
            </div>
          </div>
          <Modal
            className="Users__modal"
            header={
              modalName == 'deleteUser' && <h4>Uwaga!</h4> ||
              modalName == 'addUser' && <h4>Nowy użytkownik</h4> ||
              modalName == 'editUser' && <h4>Edycja użytkownika</h4>
            }
          >
            {modalName == 'deleteUser'
              ?
                <div>
                  <p>
                    Czy na pewno chcesz usunąć użytkownika <b>{selectedUser.name}</b>?
                  </p>
                  <div className="Users__modalActions">
                    <button
                      className="Users__modalDiscard"
                      onClick={closeModal}
                    >Anuluj</button>
                    <button
                      className="Users__modalOk"
                      onClick={() => deleteUser(selectedUser.id)}
                    >Usuń</button>
                  </div>
                </div>
              :
                <UserForm
                  onSubmit={submit}
                  initialValues={modalName == 'addUser' ? {isAdmin: false} : selectedUser}
                  edit={modalName == 'editUser'}
                />
            }
          </Modal>
          <Footer />
        </div>
      )
    }
}

UsersList.propTypes = {
  users: PT.arrayOf(PT.shape({
    id: PT.string,
    name: PT.string
  })),
  fetchUsers: PT.func,
  isFetching: PT.bool,
  openModal: PT.func.isRequired,
  closeModal: PT.func.isRequired,
  addUser: PT.func.isRequired,
  editUser: PT.func.isRequired,
  selectedUser: PT.shape({
    id: PT.string
  }),
  showToast: PT.func.isRequired,
  modalName: PT.string,
  deleteUser: PT.func
}

const mapStateToProps = ({data, isFetching, modal}) => ({
  users: data.users,
  isFetching: isFetching.global,
  selectedUser: modal.payload,
  modalName: modal.name,
  loadingActions: isFetching.objects
})

export default withRouter(connect(
  mapStateToProps,
  { fetchUsers, openModal, addUser, closeModal, editUser, showToast, deleteUser }
)(UsersList))
