import React                from 'react';
import cx                   from 'classnames';
import Loader               from 'theme/Loader';
import Table, {
  AcceptCommentCell,
  Row
}                           from 'theme/Table';
import roles                from 'modules/units/roles';
import Checkbox             from './Checkbox';

const headings = [
  {key: 'name', label: 'Nazwa'},
  {key: 'accountedSchedule', label: 'Plan pracy'},
  {key: 'accountedTimeSheet', label:'Książka pracy'}
]

export default ({fetching, units, comment, edit, disabled}) => {
  return (
    <div className="List__card">
      <Loader loading={fetching} />
      <div className={cx("List__cardContainer", {blur: fetching})}>
        {units.length == 0
          ? <h4>Do tego projektu nie dodano żadnych jednostek.</h4>
          :
          <Table
            headings={headings}
            comment={comment}
            permissions={roles}
          >
            {units.map( unit => {
              const onChange = edit(unit.id);
              return (
                <Row key={unit.id}>
                  <AcceptCommentCell
                    className="Accounting__name"
                    hideIcons
                  >
                    {unit.name}
                  </AcceptCommentCell>
                  <AcceptCommentCell data={unit} commentsPosition="left">
                    <Checkbox
                      checked={unit.accountedSchedule}
                      onChange={onChange}
                      name="accountedSchedule"
                      disabled={disabled}
                    />
                  </AcceptCommentCell>
                  <AcceptCommentCell data={unit} commentsPosition="left">
                    <Checkbox
                      checked={unit.accountedTimeSheet}
                      onChange={onChange}
                      name="accountedTimeSheet"
                      disabled={disabled}
                    />
                  </AcceptCommentCell>
                </Row>
              )
            })}
          </Table>
        }
      </div>
    </div>
  )
}
