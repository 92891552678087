export default {
  moneyStorage: {
    filledBy: ['Region', 'Office'],
    acceptedBy: [],
    commentable: true
  },
  booking: {
    filledBy: ['Region', 'Office'],
    acceptedBy: [],
    commentable: true
  },
  billsList: {
    filledBy: ['Region', 'Office'],
    acceptedBy: [],
    commentable: true
  },
  participantsDeposit: {
    filledBy: ['Region', 'Office'],
    acceptedBy: [],
    commentable: true
  },
  administrativeCosts: {
    filledBy: ['Region', 'Office'],
    acceptedBy: [],
    commentable: true
  },
  mazovianGuideApproval: {
    filledBy: ['Banner'],
    acceptedBy: [],
    commentable: true
  },
  mazovianScoutApproval: {
    filledBy: ['Banner'],
    acceptedBy: [],
    commentable: true
  },
  northEastGuideApproval: {
    filledBy: ['Banner'],
    acceptedBy: [],
    commentable: true
  },
  northEastScoutApproval: {
    filledBy: ['Banner'],
    acceptedBy: [],
    commentable: true
  },
  info: {
    filledBy: ['Command'],
    acceptedBy: ['Region', 'Banner', 'Admin'],
    commentable: true
  }
}
// Region
// Office
// Banner
// Detachment
// CampCommander
