import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { Input, SaveButton } from 'theme/Form';

const validate = (values) => {
  const errors = {};

  if(!values.name) {
      errors.name = 'Wymagane'
  }
  if(!values.email) {
      errors.email = 'Wymagane'
  }

  return errors;
}

export default connect()(
reduxForm({
  form: 'UsersForm',
  enableReinitialize: true,
  validate
})(
  ({handleSubmit, submitting, pristine, edit}) => {
    return (
      <form
        className="Users__form"
        onSubmit={handleSubmit}
      >
        <Field
          component={Input}
          name="name"
          label="Nazwa"
          placeholder="Imię i nazwisko"
        />
        <Field
          component={Input}
          name="email"
          label="Email"
          placeholder="Email"
          disabled={edit}
        />
        <Field
          component={Input}
          name="password"
          type="password"
          label="Hasło"
          placeholder="Hasło"
        />
        <Field
          component={Input}
          type="checkbox"
          name="isAdmin"
          label="Administrator"
          defaultChecked={false}
        />
        <div className="Users__formBtn">
          <SaveButton
            type="submit"
            disabled={submitting || pristine}
            saving={submitting}
          >Zapisz</SaveButton>
        </div>
      </form>
    )
  }
))
