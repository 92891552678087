import React, { Component }                                   from 'react';
import PT                                                     from 'prop-types';
import cx                                                     from 'classnames';
import { connect }                                            from 'react-redux';
import _                                                      from 'underscore';
import deep                                                   from 'deep-diff';
import { withRouter }                                         from 'react-router';
import { fetchHealthCarer, editHealthCarer, addHealthCarer }  from 'modules/healthCarers/actions';
import { fetchFiles }                                         from 'modules/files/actions';
import { fetchProject }                                       from 'modules/projects/actions';
import { showToast }                                          from 'theme/actions';
import Header                                                 from 'theme/Header';
import Navbar                                                 from 'theme/Navbar';
import Footer                                                 from 'theme/Footer';


import 'theme/common-views.sass';

import HealthCarerForm                                        from './HealthCarerForm'


class HealthCarerDetails extends Component {

    componentDidMount() {
      const {projectId, healthCarerId} = this.props.match.params;
      if(projectId && healthCarerId)
        this.props.fetchHealthCarer(projectId, healthCarerId);
    }

    render() {
      const {isFetching, editHealthCarer, addHealthCarer, showToast, match, healthCarer, user, activeProject} = this.props;
      const {projectId, healthCarerId} = match.params;
      const edit = healthCarerId != null;
      const initialForm = activeProject.description ? {from: activeProject.description.from, to: activeProject.description.to} : null;

      const submit = (values) => {
        const diff = edit && _.pluck(deep.diff(healthCarer, values), 'path');
        const vals = diff ? _.pick(values, ..._.map(diff, _.first)) : values;
        const func = edit ? editHealthCarer : addHealthCarer;
        return func({projectId, id: healthCarerId}, vals)
        .then(() => {
          const message = <div>Zapisano opiekuna</div>
          showToast({message});
        })
        .catch(() => {
          const message = (
            <div>
              Nie udało się zapisać opiekuna. Spróbuj ponownie później
            </div>
          )
          showToast({message, error: true});
        })
      }

      return (
        <div className="HealthCarer">
          <Header />
          <Navbar />
          <div className={cx("Details__container", {blur: isFetching})}>

            <div className="Details__heading">
              <h3>Opieka medyczna</h3>
              <p>
                {edit
                  ? 'Edycja opiekuna'
                  : 'Nowy opiekun'
                }
              </p>
            </div>

            <HealthCarerForm
              initialValues={edit ? healthCarer : initialForm}
              onSubmit={submit}
              loading={isFetching}
              user={user}
              disabled={activeProject.state != 'Open'}
            />
          </div>
          <Footer />
        </div>
      )
    }
}

HealthCarerDetails.propTypes = {
  healthCarer: PT.shape({
    id: PT.string
  }),
  fetchHealthCarer: PT.func,
  editHealthCarer: PT.func,
  addHealthCarer: PT.func,
  showToast: PT.func.isRequired
}

const mapStateToProps = ({data: {healthCarer, files, activeProject}, isFetching, auth: {user}}) => ({
  healthCarer,
  files,
  isFetching: isFetching.global,
  user,
  activeProject: activeProject || {}
})

export default withRouter(connect(
  mapStateToProps,
  { fetchHealthCarer, editHealthCarer, addHealthCarer, showToast, fetchFiles, fetchProject }
)(HealthCarerDetails))
