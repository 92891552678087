export default {
  firstName: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  lastName: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  from: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  to: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  sanepidBook: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  carrierTest: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  voluntaryContract: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  contractStatement: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  accountedDocuments: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
  },
  accountedKRKCertificate: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
  },
  accountedDDStatement: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
  },
  accountedMedicalBook: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
  },
  accountedContract: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
  }
}
// Region
// Office
// Banner
// Detachment
// CampCommander
