export default {
  name: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  schedule: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Detachment', 'Admin'],
    commentable: true
  },
  financialRecord: {
    filledBy: ['Region', 'Office'],
    acceptedBy: [],
    commentable: true
  },
  dues: {
    filledBy: ['Region', 'Office'],
    acceptedBy: [],
    commentable: true
  },
  accountedSchedule: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
  },
  accountedTimeSheet: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
  }
}
// Region
// Office
// Banner
// Detachment
// CampCommander
