import api from 'api';
import { groupBy, uniq } from 'underscore';
import { saveAs } from 'file-saver';
import { requestSuccess, createRequest, handleError } from 'actions/requests';
import { fetchManyFiles } from 'modules/files/actions';

const mapType = (array) => {
  return array.reduce((result, a) => {
    const [first, last] = a.split('.');
    if (!last)
      return [].concat(result, a);
    if (last == 'comment' || (last == 'fileId' && result.includes(first)))
      return result;
    if (last == 'fileId' && !result.includes(first))
      return [].concat(result, first);
    else return result;
  }, [])
}

export function fetchSummary(projectId) {
  return (dispatch) => {
    dispatch(createRequest());
    return api({
      method: 'get',
      url: `/projects/${projectId}/summary`
    })
    .then((response) => {
      const {data} = response;
      const mergedFiles = data.map(d => {
        const unfilled = mapType(d.unfilled);
        return {...d, unfilled, files: uniq(d.files)}
      })
      const grouped = groupBy(mergedFiles, (d) => d.elementType);
      const files = mergedFiles.reduce((a, b) => [].concat(a, b.files), []);
      fetchManyFiles(uniq(files))
      .then(({data: files}) => {
        dispatch(requestSuccess(grouped, 'summary'));
        dispatch(requestSuccess(files, 'files'));
      })
    })
    .catch(handleError(dispatch));
  }
}

export function fetchSpreadSheet (projectId, projectName) {
  return (dispatch) => {
    dispatch(createRequest());
    return api({
      method: 'get',
      url: `/projects/${projectId}/export`,
      responseType: 'blob'
    })
    .then((response) => {
      saveAs(response.data, `${projectName}.xlsx`);
      dispatch(requestSuccess(response, 'spreadSheet'));
    })
    .catch(handleError(dispatch));
  }
}
