import {
  TOGGLE_AVATAR_MODAL,
  TOGGLE_MOBILE_NAV,
  OPEN_MODAL,
  CLOSE_MODAL,
  SHOW_TOAST,
  CLOSE_TOAST,
  SHOW_COMMENTS,
  CLOSE_COMMENTS,
  EDIT_CELL,
  START_SELECT,
  END_SELECT,
  SELECT,
  CLEAR_SELECTION
} from 'types'

export const avatarModalVisible = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_AVATAR_MODAL:
      return !state;
    default:
      return state;
  }
}

export const mobileNavVisible = (state = false, action) => {
  switch (action.type) {
    case TOGGLE_MOBILE_NAV:
      return action.visible;
    default:
      return state;
  }
}

export const modal = (state = {visible: false}, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        visible: true,
        name: action.name,
        payload: action.payload
      }
    case CLOSE_MODAL:
      return {
        ...state,
        visible: false,
        payload: null
      }
    default:
      return state;
  }
}

export const toast = (state = {visible: false, error: false, message: null}, action) => {
  switch (action.type) {
    case SHOW_TOAST:
      return {
        ...state,
        ...action,
        visible: true
      }
    case CLOSE_TOAST:
      return {
        ...state,
        visible: false
      }
    default:
      return state;
  }
}

export const comments = (state = {visible: false}, action) => {
  switch (action.type) {
    case SHOW_COMMENTS:
      return {
        ...state,
        visible: true,
        obj: action.obj,
        prop: action.prop
      }
    case CLOSE_COMMENTS:
      return {
        ...state,
        visible: false
      }
    default:
      return state;
  }
}

const editableTableInitialState = {
  isMouseDown: false,
  objects: {},
  rows: [],
  columns: [],
  selectedIds: [],
  selectedProps: []
}

export const editableTable = (
  state = editableTableInitialState,
  action
) => {
  switch (action.type) {
    case EDIT_CELL:
      return {
        ...state,
        form: action.form
      }
    case START_SELECT:
      if(action.id && action.prop && action.columns && action.rows)
        return {
          ...state,
          isMouseDown: true,
          selectedIds: [action.id],
          selectedProps: [action.prop],
          startRow: action.rows.indexOf(action.id),
          startColumn: action.columns.indexOf(action.prop),
          columns: action.columns,
          rows: action.rows,
        }
      return {
        ...state,
        isMouseDown: true
      }
    case SELECT:
      if (state.isMouseDown)
        if(action.id && action.prop) {
          const indexOfRow = state.rows.indexOf(action.id);
          const indexOfColumn = state.columns.indexOf(action.prop);
          const selectedProps = state.startColumn <= indexOfColumn
            ? state.columns.slice(state.startColumn, indexOfColumn + 1)
            : state.columns.slice(indexOfColumn, state.startColumn + 1)
          const selectedIds = state.startRow <= indexOfRow
            ? state.rows.slice(state.startRow, indexOfRow + 1)
            : state.rows.slice(indexOfRow, state.startRow + 1)
          return {
            ...state,
            selectedProps,
            selectedIds
          }
        }
      return state;
    case END_SELECT:
      return {
        ...state,
        isMouseDown: false,
      }
    case CLEAR_SELECTION:
      if (!state.isMouseDown)
        return {
          ...state,
          ...editableTableInitialState
        }
      return state;
    default:
      return state;
  }
}
