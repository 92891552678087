import api, { pickAllowedProps } from 'api';
import { pick, omit, intersection } from 'underscore';
import { push } from 'react-router-redux';
import { requestSuccess, createRequest, handleError } from 'actions/requests';
import { saveFiles, matchFiles } from 'modules/files/actions';
import roles from './roles';

const FILE_FIELDS = [
  'sanepidBook',
  'carrierTest',
  'voluntaryContract',
  'contractStatement'
];


export function fetchDrivers(projectId, opts = {loader: true}) {
  return (dispatch) => {
    opts && opts.loader && dispatch(createRequest({global: true, name: 'drivers'}));
    return api({
      method: 'get',
      url: `/projects/${projectId}/drivers`
    })
    .then((response) => {
			dispatch(requestSuccess(response, 'drivers', opts.id));
			return response.data;
		})
    .catch(handleError(dispatch));
  }
}

export function fetchDriver(projectId, id) {
  return (dispatch) => {
    dispatch(createRequest());
    return api({
      method: 'get',
      url: `/projects/${projectId}/drivers/${id}`
    })
    .then((response) => dispatch(matchFiles(response, FILE_FIELDS, 'driver')))
    .catch(handleError(dispatch));
  }
}

export function addDriver({projectId}, driver) {
  const allowedProps = pickAllowedProps(roles);
  return (dispatch) => {
    dispatch(createRequest());
    return saveFiles(driver, intersection(FILE_FIELDS, allowedProps))
    .then( filesObj => {
      const req = pick(omit(driver, FILE_FIELDS), allowedProps);
      return api({
        method: 'post',
        url: `/projects/${projectId}/drivers`,
        data: {
          ...req,
          ...filesObj
        }
      })
      .then((response) => {
        dispatch(matchFiles(response, FILE_FIELDS, 'driver'));
        dispatch(push(`/projects/${projectId}/staff/drivers`));
      })
    })
    .catch(handleError(dispatch));
  }
}

export function editDriver({projectId, id, disableRedirect}, driver) {
  const allowedProps = pickAllowedProps(roles);
  return (dispatch) => {
    dispatch(createRequest({name: 'editDriver'}));
    return saveFiles(driver, intersection(FILE_FIELDS, allowedProps))
    .then( filesObj => {
      const req = pick(omit(driver, FILE_FIELDS), allowedProps);
      return api({
        method: 'patch',
        url: `/projects/${projectId}/drivers/${id}`,
        data: {
          ...req,
          ...filesObj
        }
      })
      .then((response) => {
        dispatch(matchFiles(response, FILE_FIELDS, 'driver'));
        !disableRedirect && dispatch(push(`/projects/${projectId}/staff/drivers`));
      })
    })
    .catch(handleError(dispatch));
  }
}

export function deleteDriver(projectId, id) {
  return (dispatch) => {
    dispatch(createRequest({name: 'DeleteDriver', id}));
    return api({
      method: 'delete',
      url: `/projects/${projectId}/drivers/${id}`
    })
    .then(() => dispatch(fetchDrivers(projectId, {loader: false, id})))
    .catch(handleError(dispatch, id));
  }
}

export function toggleAcceptDriver(projectId, driver, state, ...props) {
  return (dispatch) => {
    dispatch(createRequest({name: 'AcceptDriver', id: driver.id}));
    const prom = props.map( prop => api({
      method: 'put',
      url: `/projects/${projectId}/drivers/${driver.id}/${prop}/state`,
      data: {state}
    }))
    return Promise.all(prom)
    .then( () => dispatch(fetchDrivers(projectId, {loader: false, id: driver.id})))
    .catch(handleError(dispatch, driver.id));
  }
}

export function commentDriver(projectId, driver, prop, data) {
  return (dispatch) => {
    dispatch(createRequest({name: 'CommentDriver', id: driver.id}));
    return api({
      method: 'post',
      url: `/projects/${projectId}/drivers/${driver.id}/${prop}/comments`,
      data
    })
    .then( () => dispatch(fetchDrivers(projectId, {loader: false, id: driver.id})))
    .catch(handleError(dispatch, driver.id));
  }
}
