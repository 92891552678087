import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import Form, { Input, Datepicker, FileInput, Search, Group, SaveButton } from 'theme/Form';
import Loader from 'theme/Loader';
import roles from 'modules/healthCarers/roles';

import 'react-datepicker/dist/react-datepicker.css';

const typeOptions = [
  {label: 'Pielęgniarka', value: 'Nurse'},
  {label: 'Ratownik medyczny', value: 'Paramedic'},
  {label: 'Lekarz', value: 'Doctor'}
]


export default withRouter(connect(
  (state) => ({formValues: formValueSelector('HealthCarerForm')(state, 'voluntaryContract', 'contractStatement')})
)(reduxForm({
  form: 'HealthCarerForm',
  enableReinitialize: true
})(
  ({handleSubmit, pristine, submitting, match, loading, user, formValues, disabled}) => {
    const {projectId} = match.params;

    const filledVoluntaryContract = Boolean(formValues.voluntaryContract && (formValues.voluntaryContract.fileId || formValues.voluntaryContract.newFile));
    const filledContractStatement = Boolean(formValues.contractStatement && (formValues.contractStatement.fileId || formValues.contractStatement.newFile));

    return (
      <Form
        className="Details__form"
        onSubmit={handleSubmit}
      >
        <div className="Details__row">
          <div className="Details__card">
            <Loader loading={loading} />
            <div className={cx("Details__cardContainer", {blur: loading})}>
              <Field
                component={Input}
                name="firstName"
                label="Imię"
                disabled={disabled || !user.isAdmin && !roles.firstName.filledBy.includes(user.role.name)}
              />
              <Field
                component={Input}
                name="lastName"
                label="Nazwisko"
                disabled={disabled || !user.isAdmin && !roles.lastName.filledBy.includes(user.role.name)}
              />
              <h4>Dane obozowe</h4>
              <Field
                component={Search}
                name="type"
                label="Rodzaj"
                options={typeOptions}
                disabled={disabled || !user.isAdmin && !roles.type.filledBy.includes(user.role.name)}
              />
              <Group>
                <div className="Details__row">
                  <span>Termin pełnienia funkcji</span>
                  <Field
                    component={Datepicker}
                    name="from"
                    className="Details__formDatepicker"
                    disabled={disabled || !user.isAdmin && !roles.from.filledBy.includes(user.role.name)}
                  />
                  <Field
                    component={Datepicker}
                    name="to"
                    className="Details__formDatepicker"
                    disabled={disabled || !user.isAdmin && !roles.to.filledBy.includes(user.role.name)}
                  />
                </div>
              </Group>
            </div>
          </div>
          <div className="Details__card">
            <Loader loading={loading} />
            <div className={cx("Details__cardContainer", {blur: loading})}>
              <Field
                component={FileInput}
                name="sanepidBook"
                label="Książeczka sanepidowska"
                disabled={disabled || !user.isAdmin && !roles.sanepidBook.filledBy.includes(user.role.name)}
                info="Załącz plik"
              />
              <Field
                component={FileInput}
                name="carrierTest"
                label="Badania na nosicielstwo"
                disabled={disabled || !user.isAdmin && !roles.carrierTest.filledBy.includes(user.role.name)}
                info="Załącz plik"
              />
              <Field
                component={FileInput}
                name="practicePermit"
                label="Poświadczenia prawa wykonywania zawodu (lub dyplom ukończenia studiów ratownictwa medycznego)"
                disabled={disabled || !user.isAdmin && !roles.practicePermit.filledBy.includes(user.role.name)}
                info="Załącz plik"
              />
              <Field
                component={FileInput}
                name="voluntaryContract"
                label="Umowa wolontariacka"
                disabled={
                  disabled ||
                  !user.isAdmin && !roles.voluntaryContract.filledBy.includes(user.role.name) ||
                  filledContractStatement
                }
                info="Załącz plik"
              />
              <Field
                component={FileInput}
                name="contractStatement"
                label="Oświadczenie do umowy zlecenie"
                disabled={
                  disabled ||
                  !user.isAdmin && !roles.contractStatement.filledBy.includes(user.role.name) ||
                  filledVoluntaryContract
                }
                info="Załącz plik - oryginał należy donieść do Biura Okręgu"
              />
            </div>
          </div>
          {!disabled &&
            <div className="Details__btn">
              <Link to={`/projects/${projectId}/staff/healthCarers`}>Anuluj</Link>
              <SaveButton
                disabled={pristine || submitting}
                saving={submitting}
              >Zapisz</SaveButton>
            </div>
          }
        </div>
      </Form>
    )
  }))
)
