import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware, routerReducer } from 'react-router-redux';
import { createLogger } from 'redux-logger';
import { reducer as form } from 'redux-form';
import history from 'appHistory';
import reducers from 'reducers';
import { loadAuth } from 'modules/api/localStorage';


const initialState = {
  isFetching: false,
  auth: loadAuth()
}

const middleware = [
  process.env.NODE_ENV == 'development' && createLogger({collapsed: true}),  //logging redux actions
  thunkMiddleware,                                                    //allows to return functions instead of actions for dispatch, awesome for async requests
  routerMiddleware(history)
].filter(Boolean);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line no-underscore-dangle

const configureStore = () => {

  const store = createStore(
    combineReducers({
      ...reducers,
      form,
      router: routerReducer
    }),
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reducers', () => {
      // We need to require for hot reloading to work properly.
      const nextReducer = require('./reducers');  // eslint-disable-line global-require

      store.replaceReducer(nextReducer);
    });
  }

  return store;
}

export default configureStore();