import api, { pickAllowedProps } from 'api';
import { pick, omit, intersection } from 'underscore';
import { createRequest, handleError } from 'actions/requests';
import { saveFiles, matchFiles } from 'modules/files/actions';
import roles from './roles';

const FILE_FIELDS = ['info'];

export function fetchApproval(projectId) {
  return (dispatch) => {
    dispatch(createRequest());
    return api({
      method: 'get',
      url: `/projects/${projectId}/approval`
    })
    .then((response) => dispatch(matchFiles(response, FILE_FIELDS, 'approval')))
    .catch(handleError(dispatch));
  }
}

export function editApproval({projectId}, approval) {
  const allowedProps = pickAllowedProps(roles);
  return (dispatch) => {
    dispatch(createRequest({name: 'editPlace'}));
    return saveFiles(approval, intersection(FILE_FIELDS, allowedProps))
    .then( filesObj => {
      const req = pick(omit(approval, FILE_FIELDS), allowedProps);
      return api({
        method: 'patch',
        url: `/projects/${projectId}/approval`,
        data: {
          ...req,
          ...filesObj
        }
      })
      .then((response) => dispatch(matchFiles(response, FILE_FIELDS, 'approval')))
    })
    .catch(handleError(dispatch));
  }
}

export function toggleAcceptApproval(projectId, state, ...props) {
  return (dispatch) => {
    dispatch(createRequest({name: 'AcceptApproval'}));
    const prom = props.map( prop => api({
      method: 'put',
      url: `/projects/${projectId}/approval/${prop}/state`,
      data: {state}
    }))
    return Promise.all(prom)
    .then( () => dispatch(fetchApproval(projectId, {loader: false})))
    .catch(handleError(dispatch));
  }
}

export function commentApproval(projectId, prop, data) {
  return (dispatch) => {
    dispatch(createRequest({name: 'CommentApproval'}));
    return api({
      method: 'post',
      url: `/projects/${projectId}/approval/${prop}/comments`,
      data
    })
    .then( () => dispatch(fetchApproval(projectId, {loader: false})))
    .catch(handleError(dispatch));
  }
}