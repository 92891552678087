import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getRole } from 'modules/auth/actions'


class Permissions extends React.Component {
  componentDidMount() {
    const { projectId } = this.props.match.params;
    this.props.getRole(projectId, this.props.user);
  }

  render () {
    const { children } = this.props;
    return (
      <div className="Permissions">
        {children}
      </div>
    );
  }
}

function mapStateToProps({auth}) {
  return {user: auth.user}
}

export default withRouter(connect(mapStateToProps, {getRole})(Permissions));
