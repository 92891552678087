export default {
  participantsCount: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
	},
  participantsList: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
	},
  staffList: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
	},
  cards: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
	},
  cardsCount: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
	},
  comment: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
	},
  cashBook: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
	},
  lastCashBookPosition: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
	},
  bankBook: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
	},
  lastBankBookPosition: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
	},
  receiptBooks: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
	},
  lastReceiptBookPosition: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
	},
  paymentsList: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
	},
  returnsList: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
	},
  timeSheet: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
	},
  accidentsRegistry: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
	},
  proceduresBook: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
	},
  medicalDocuments: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
	},
  menu: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
	},
  visitProtocol: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
	},
  contracts: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
	},
  officePapers: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
	},
  financialBookUploaded: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
	},
  participantsListUploaded: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
	},
  paymentsListUploaded: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
	},
  staffListUploaded: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
  }
}
// Region
// Office
// Banner
// Detachment
// CampCommander
