import  React,
        { Component }   from 'react';
import  PT              from 'prop-types';
import  { connect }     from 'react-redux'
import  { withRouter }  from 'react-router'
import  cx              from 'classnames';
import  { fetchProject,
          editProject,
          addProject,
          changeState }  from 'modules/projects/actions';
import  { fetchUsers }  from 'modules/users/actions';
import  { showToast }   from 'theme/actions';
import  Header          from 'theme/Header';
import Footer from 'theme/Footer';
import  ProjectForm     from './ProjectForm'

import './Project.sass';

const STATES = {
  Open: 'Otwarty',
  PendingAccounting: 'W trakcie rozliczania',
  Accounted: 'Rozliczony'
}

class ProjectDetails extends Component {

    componentDidMount() {
      if(this.props.match.params.projectId)
        this.props.fetchProject(this.props.match.params.projectId);
      if(this.props.user.isAdmin)
        this.props.fetchUsers();
    }

    render() {
      const {project, editProject, addProject, users, showToast, isFetching} = this.props;
      const projectId = this.props.match.params.projectId;
      const edit = projectId != null;

      const resolveSave = () => {
        const message = <div>Zapisano projekt</div>
        showToast({message});
      };

      const handleSaveError = () => {
        const message = (
          <div>
            Nie udało się zapisać projektu. Spróbuj ponownie później
          </div>
        )
        showToast({message, error: true});
      }

      const submit = (values) => {
        const func = edit ? editProject : addProject;
        return func(values, projectId)
        .then(resolveSave)
        .catch(handleSaveError)
      }

      const changeState = (state) => {
        return this.props.changeState(state, projectId)
        .then(resolveSave)
        .catch(handleSaveError)
      }

      return (
        <div className="Project">
          <Header />
          <div className="List__container">

            <div className="List__heading">
              <div>
                <h3>Projekt</h3>
                <p>
                  {edit
                    ? 'Edycja projektu'
                    : 'Nowy projekt'
                  }
                </p>
              </div>
              {edit &&
                <div className="Project__states">
                  {Object.keys(STATES).map((state) =>
                    <button
                      key={state}
                      className={cx(state, {active: project.state == state})}
                      onClick={() => project.state != state && changeState(state)}
                      disabled={isFetching}
                    >
                      {STATES[state]}
                    </button>
                  )}
                </div>
              }
            </div>


            <ProjectForm
              initialValues={edit ? project : {users: []}}
              onSubmit={submit}
              users={users}
            />
          </div>
          <Footer />
        </div>
      )
    }
}

ProjectDetails.propTypes = {
  project: PT.shape({
    id: PT.string,
    name: PT.string
  }),
  users: PT.arrayOf(PT.shape({
    id: PT.string
  })),
  fetchProject: PT.func,
  fetchUsers: PT.func,
  editProject: PT.func,
  addProject: PT.func,
  showToast: PT.func.isRequired
}

const mapStateToProps = ({data, auth, isFetching}) => ({
  project: data.project || {},
  users: data.users,
  user: auth.user,
  isFetching: isFetching.global
})

export default withRouter(connect(
  mapStateToProps,
  { fetchProject, editProject, addProject, fetchUsers, showToast, changeState }
)(ProjectDetails))
