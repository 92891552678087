import React                from 'react';
import moment               from 'moment';
import cx                   from 'classnames';
import Loader               from 'theme/Loader';
import Table,
      {
        AcceptCommentCell,
        Row
      }                     from 'theme/Table';
import roles                from 'modules/places/roles';
import Checkbox             from './Checkbox';

const headings = [
  {key: 'date', label: 'Termin'},
  {key: 'address', label: 'Adres lub trasa'},
  {key: 'accountedLeaseContract', label: 'Umowa dzierżawy terenu'},
  {key: 'accountedRegistration', label: 'Zgłoszenie do Kuratorium Oświaty'}
]

export default ({fetching, places, comment, edit, disabled}) => {
  return (
    <div className="List__card">
      <Loader loading={fetching} />
      <div className={cx("List__cardContainer", {blur: fetching})}>
        {places.length == 0
          ? <h4>Do tego projektu nie dodano żadnych miejsc.</h4>
          :
          <Table
            headings={headings}
            comment={comment}
            permissions={roles}
          >
            {places.map( place => {
              const onChange = edit(place.id);
              return (
                <Row key={place.id}>
                  <AcceptCommentCell
                    className="Accounting__date"
                    hideIcons
                  >
                    {place.from && moment(place.from).format('DD.MM.YYYY')} - {place.to && moment(place.to).format('DD.MM.YYYY')}
                  </AcceptCommentCell>
                  <AcceptCommentCell
                    className="Accounting__address"
                    hideIcons
                  >
                    {place.address}
                  </AcceptCommentCell>
                  <AcceptCommentCell
                    data={place}
                    commentsPosition="left"
                  >
                    <Checkbox
                      checked={place.accountedLeaseContract}
                      onChange={onChange}
                      name="accountedLeaseContract"
                      disabled={disabled}
                    />
                  </AcceptCommentCell>
                  <AcceptCommentCell data={place} commentsPosition="left">
                    <Checkbox
                      checked={place.accountedRegistration}
                      onChange={onChange}
                      name="accountedRegistration"
                      disabled={disabled}
                    />
                  </AcceptCommentCell>
                </Row>
              )
            })}
          </Table>
        }
      </div>
    </div>
  )
}