import  React,
        { Component }               from 'react';
import  cx                          from 'classnames';
import { uniq }                     from 'underscore';
import  { connect }                 from 'react-redux'
import { Link }                     from 'react-router-dom';
import  { withRouter }              from 'react-router'
import  { fetchSummary,
          fetchSpreadSheet }        from 'modules/summary/actions';
import { openModal, showToast }     from 'modules/theme/actions';
import { fetchManyFiles }           from 'modules/files/actions';
import Header                       from 'theme/Header';
import Navbar                       from 'theme/Navbar';
import Loader                       from 'theme/Loader';
import Modal                        from 'modules/theme/Modal';
import Footer                       from 'theme/Footer';
import DownloadButton               from 'modules/theme/DownloadButton';
import {types,
        editableFieldsCount,
        acceptableFieldsCount,
        paths }                     from 'modules/summary/fields';
import DonutChart                   from './DonutChart';
import ObjectSummary                from './ObjectSummary';
import ListSummary                  from './ListSummary';

import './Summary.sass';


class SummaryDetails extends Component {
  constructor () {
    super();
    this.download = this.download.bind(this);
    this.export = this.export.bind(this);
  }

  componentDidMount() {
    this.props.fetchSummary(this.props.match.params.projectId);
  }

  getData (summary) {
    const typesNames = Object.keys(types);

    const statesFieldsCount = typesNames.reduce((a, b) => {
      const approved = summary[b] ? summary[b].reduce( (c, d) => c + d.approved.length, 0) : 0;
      const rejected = summary[b] ? summary[b].reduce( (c, d) => c + d.rejected.length, 0) : 0;
      const unfilled = summary[b] ? summary[b].reduce( (c, d) => c + d.unfilled.length, 0) : 0;
      const files = summary[b] ? summary[b].reduce( (c, d) => [].concat(c, d.files), []) : [];
      return {
        approved: a.approved + approved,
        rejected: a.rejected + rejected,
        unfilled: a.unfilled + unfilled,
        files: uniq([].concat(a.files, files))
      }
    }, {approved: 0, rejected: 0, unfilled: 0, files: []});

    const fieldsCount = typesNames.reduce(
      (a, b) =>
      summary[b] ?
      {
        acceptable: a.acceptable + (summary[b].length * acceptableFieldsCount[b]),
        editable: a.editable + (summary[b].length * editableFieldsCount[b])
      } : a
      , {acceptable: 0, editable: 0});

    const filledData = [
      {
        key: 'filled',
        value: (fieldsCount.editable - statesFieldsCount.unfilled) / fieldsCount.editable,
        label: true,
        count: (fieldsCount.editable - statesFieldsCount.unfilled)
      },
      {key: 'unfilled', value: statesFieldsCount.unfilled / fieldsCount.editable, count: statesFieldsCount.unfilled}
    ]

    const approvedData = [
      {
        key: 'approved',
        value: fieldsCount.acceptable != 0 ? statesFieldsCount.approved / fieldsCount.acceptable : 0,
        count: statesFieldsCount.approved,
        label: true
      },
      {
        key: 'rejected',
        value: fieldsCount.acceptable != 0 ? statesFieldsCount.rejected / fieldsCount.acceptable : 0,
        count: statesFieldsCount.rejected
      },
      {
        key: 'unapproved',
        value: fieldsCount.acceptable != 0 ? (fieldsCount.acceptable - statesFieldsCount.approved) / fieldsCount.acceptable : 0,
        count: (fieldsCount.acceptable - statesFieldsCount.approved)
      }
    ]

    return {filledData, approvedData, files: statesFieldsCount.files};
  }

  download (files) {
    return (e) => {
      e.stopPropagation();
      const matchedFiles = this.props.files.filter( file => files.includes(file.id));
      this.props.openModal('download', matchedFiles);
    }
  }

  export () {
    const {projectId} = this.props.match.params;
    this.props.fetchSpreadSheet(projectId, this.props.activeProject.name)
    .catch(() => {
      this.props.showToast({
        message: <div>Nie udało się pobrać raportu. Spróbuj ponownie później.</div>,
        error: true
      })
    })
  }

  render() {
    const {isFetching, match, summary, history, selectedFiles} = this.props;
    const {projectId} = match.params;

    const typesNames = Object.keys(types);

    const {filledData, approvedData, files} = this.getData(summary);

    return (
      <div className="List Summary">
        <Header />
        <Navbar />
        <div className="List__container">

          <div className="List__heading">
            <div>
              <h3>Podsumowanie</h3>
              <p>Podsumowanie wszystkich pól projektu — zatwierdzenie, wypełnienie, pliki</p>
              <p>W podsumowaniach zakładek kliknięcie na wiersz spowoduje przekierowanie do edycji.</p>
            </div>
          </div>

          <div className="Summary__row">
            <div className="List__card">
              <Loader loading={isFetching} />
              <h4 className="Summary__cardHeading">
                Wypełnione pola
              </h4>
              <div className={cx("List__cardContainer Summary__row", {blur: isFetching})}>
                <DonutChart data={filledData} />
                {filledData &&
                  <div className="Summary__chartLegend">
                    <div>
                      Wypełnione
                      <span>{filledData[0].count}</span>
                    </div>
                    <div>
                      Niewypełnione
                      <span>{filledData[1].count}</span>
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className="List__card">
              <Loader loading={isFetching} />
              <h4 className="Summary__cardHeading">
                Zatwierdzone pola
              </h4>
              <div className={cx("List__cardContainer Summary__row", {blur: isFetching})}>
                <DonutChart data={approvedData} />
                {approvedData &&
                  <div className="Summary__chartLegend">
                    <div>
                      Zatwierdzone
                      <span>{approvedData[0].count}</span>
                    </div>
                    <div>
                      Odrzucone
                      <span>{approvedData[1].count}</span>
                    </div>
                    <div>
                      Niezatwierdzone
                      <span>{approvedData[2].count}</span>
                    </div>
                  </div>
                }
              </div>
            </div>
            <div className="List__card">
              <Loader loading={isFetching} />
              <h4 className="Summary__cardHeading">
                Pliki
              </h4>
              <div className={cx("List__cardContainer Summary__row", {blur: isFetching})}>
                {files &&
                  <div className="Summary__files">
                    <div className="Summary__chartLabel">
                      <span className="fa fa-file" />
                      <div className="Summary__filesCount">{files.length}</div>
                    </div>
                    {files.length > 0 &&
                      <button
                        onClick={this.download(files)}
                      >Pobierz</button>
                    }
                  </div>
                }
              </div>
            </div>
          </div>

          {typesNames.map( typeName => {
            return (
              <div className="Summary__section Summary__row" key={typeName}>
                <div className="List__card">
                  <Loader loading={isFetching} />
                  <h4 className="Summary__cardHeading">
                    <Link to={`/projects/${projectId}${paths[typeName]}`}>
                      {types[typeName]}
                    </Link>
                  </h4>
                  <div className={cx("List__cardContainer", {blur: isFetching})}>
                    {summary
                      && (summary[typeName] && summary[typeName].length == 1 && !summary[typeName][0].id
                        &&
                          <ObjectSummary
                            data={summary[typeName][0]}
                            download={this.download}
                          />
                        )
                      || (summary[typeName]
                        &&
                          <ListSummary
                            data={summary[typeName]}
                            projectId={projectId}
                            history={history}
                            download={this.download}
                            redirect={typeName != 'Participant'}
                          />)
                      || <ListSummary data={[]} projectId={projectId} download={this.download} />
                    }
                  </div>
                </div>
                {typeName == 'Description' &&
                  <div className="List__card Summary__files">
                    <Loader loading={isFetching} />
                    <h4 className="Summary__cardHeading">
                      Raport
                    </h4>
                    <div className={cx("List__cardContainer Summary__row", {blur: isFetching})}>
                      <div className="Summary__files">
                        <div className="Summary__chartLabel">
                          <span className="fa fa-file" />
                          <div className="Summary__filesCount">XLS</div>
                        </div>
                        <button
                          onClick={this.export}
                        >Pobierz</button>
                      </div>
                    </div>
                  </div>
                }
              </div>
            )
          })}

        </div>

        <Modal
          className="Summary__modal"
          header={<h4>Pobierz pliki</h4>}
        >
          {uniq(selectedFiles).map(file => {
            return (
              <DownloadButton
                className="Summary__modalButton"
                file={file}
                key={file.id}
              >
                {file.name}
              </DownloadButton>
            )
          })}
        </Modal>
        <Footer />
      </div>
    )
  }
}


const mapStateToProps = ({data: {summary, activeProject, files}, isFetching, auth, modal}) => ({
  summary: summary || {},
  isFetching: isFetching.global,
  files: files || [],
  user: auth.user,
  activeProject: activeProject || {},
  selectedFiles: (modal && modal.payload) || []
})

export default withRouter(connect(
  mapStateToProps,
  { fetchSummary, openModal, fetchManyFiles, fetchSpreadSheet, showToast }
)(SummaryDetails))
