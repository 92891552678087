import React                                                from 'react';
import cx                                                   from 'classnames';
import { reduxForm, Field}                                  from 'redux-form';
import Form,
       {
         Input,
         Radio,
         SaveButton,
         CommentableField,
         FileInput }                                        from 'theme/Form';
import Loader                                               from 'theme/Loader';
import Table                                                from 'theme/Table';
import roles                                                from 'modules/approval/roles';

const moneyStorageOptions = [
  {label: 'Gotówka', value: 'Cash'},
  {label: 'Konto', value: 'Account'},
  {label: 'Subkonto okręgowe', value: 'Subaccount'}
]


export default reduxForm({
  form: 'ApprovalForm',
  enableReinitialize: true
})(
  ({handleSubmit, pristine, submitting, loading, commentsProps, approval, showComments, user, units, disabled}) => {
    return (
      <Form
        className="Approval__form"
        onSubmit={handleSubmit}
      >
        <div className="Approval__row">
          <div className="Approval__card Approval__units">
            <Loader loading={loading} />
            <div className={cx("Approval__cardContainer", {blur: loading})}>
              <h4>Jednostki</h4>
              {units && units.length == 0
                ? <h5>Do tego projektu nie dodano jeszcze żadnych jednostek.</h5>
                : units &&
                <Table>
                  {units.map( unit => {
                    return (
                      <tr key={unit.id}>
                        <td>
                          {unit.name}
                        </td>
                      </tr>
                    )
                  })}
                </Table>
              }
            </div>
          </div>
          <div className="Approval__card">
            <Loader loading={loading} />
            <div className={cx("Approval__cardContainer", {blur: loading})}>
              <CommentableField
                commentsProps={commentsProps}
                data={approval}
                showComments={showComments}
                prop="moneyStorage"
              >
                <Field
                  component={Radio}
                  name="moneyStorage"
                  label="Forma przechowywania pieniędzy"
                  options={moneyStorageOptions}
                  disabled={disabled || !user.isAdmin && !roles.moneyStorage.filledBy.includes(user.role.name)}
                />
              </CommentableField>
              <CommentableField
                commentsProps={commentsProps}
                data={approval}
                showComments={showComments}
                prop="booking"
              >
                <Field
                  component={Input}
                  type="checkbox"
                  name="booking"
                  label="Księgowanie na bieżąco w książce finansowej"
                  disabled={disabled || !user.isAdmin && !roles.booking.filledBy.includes(user.role.name)}
                />
              </CommentableField>
              <CommentableField
                commentsProps={commentsProps}
                data={approval}
                showComments={showComments}
                prop="billsList"
              >
                <Field
                  component={Input}
                  type="checkbox"
                  name="billsList"
                  label="Lista odpłatności"
                  disabled={disabled || !user.isAdmin && !roles.billsList.filledBy.includes(user.role.name)}
                />
              </CommentableField>
              <CommentableField
                commentsProps={commentsProps}
                data={approval}
                showComments={showComments}
                prop="administrativeCosts"
              >
                <Field
                  component={Input}
                  type="checkbox"
                  name="administrativeCosts"
                  label="Wpłacenie do kasy kosztów administracyjnych (7%)"
                  disabled={disabled || !user.isAdmin && !roles.administrativeCosts.filledBy.includes(user.role.name)}
                />
              </CommentableField>
              <CommentableField
                commentsProps={commentsProps}
                data={approval}
                showComments={showComments}
                prop="participantsDeposit"
              >
                <Field
                  component={Input}
                  type="checkbox"
                  name="participantsDeposit"
                  label="Zebrane 70% wpłat uczestników"
                  disabled={disabled || !user.isAdmin && !roles.participantsDeposit.filledBy.includes(user.role.name)}
                />
              </CommentableField>
            </div>
          </div>
          <div className="Approval__card">
            <Loader loading={loading} />
            <div className={cx("Approval__cardContainer", {blur: loading})}>
              <CommentableField
                commentsProps={commentsProps}
                data={approval}
                showComments={showComments}
                prop="mazovianGuideApproval"
                commentPosition="left"
              >
                <Field
                  component={Input}
                  name="mazovianGuideApproval"
                  label="Zatwierdzenie Mazowieckiej Chorągwi Harcerek"
                  type="checkbox"
                  disabled={disabled || !user.isAdmin && !roles.mazovianGuideApproval.filledBy.includes(user.role.name)}
                />
              </CommentableField>
              <CommentableField
                commentsProps={commentsProps}
                data={approval}
                showComments={showComments}
                prop="mazovianScoutApproval"
                commentPosition="left"
              >
                <Field
                  component={Input}
                  name="mazovianScoutApproval"
                  label="Zatwierdzenie Mazowieckiej Chorągwi Harcerzy"
                  type="checkbox"
                  disabled={disabled || !user.isAdmin && !roles.mazovianScoutApproval.filledBy.includes(user.role.name)}
                />
              </CommentableField>
              <CommentableField
                commentsProps={commentsProps}
                data={approval}
                showComments={showComments}
                prop="northEastGuideApproval"
                commentPosition="left"
              >
                <Field
                  component={Input}
                  name="northEastGuideApproval"
                  label="Zatwierdzenie Północno-wschodniego Namiestnictwa Harcerek"
                  type="checkbox"
                  disabled={disabled || !user.isAdmin && !roles.northEastGuideApproval.filledBy.includes(user.role.name)}
                />
              </CommentableField>
              <CommentableField
                commentsProps={commentsProps}
                data={approval}
                showComments={showComments}
                prop="northEastScoutApproval"
                commentPosition="left"
              >
                <Field
                  component={Input}
                  name="northEastScoutApproval"
                  label="Zatwierdzenie Północno-wschodniej Chorągwi Harcerzy"
                  type="checkbox"
                  disabled={disabled || !user.isAdmin && !roles.northEastScoutApproval.filledBy.includes(user.role.name)}
                />
              </CommentableField>
              <CommentableField
                commentsProps={commentsProps}
                data={approval}
                showComments={showComments}
                prop="info"
                commentPosition="left"
              >
                <Field
                  component={FileInput}
                  name="info"
                  label="Informacje o zgrupowaniu/kratka zgrupowania"
                  info="Załącz plik"
                  disabled={disabled || !user.isAdmin && !roles.info.filledBy.includes(user.role.name)}
                />
              </CommentableField>
            </div>
          </div>
        </div>
        {!disabled &&
          <div className="Approval__btn">
            <SaveButton
              disabled={pristine || submitting}
              saving={submitting}
            >Zapisz</SaveButton>
          </div>
        }
      </Form>
    )
  }
)
