export const types = {
  Description: 'Szczegóły projektu',
  Place: 'Miejsca',
  Unit: 'Jednostki',
  Command: 'Komendant',
  Cook: 'Kucharze',
  Driver: 'Kierowcy',
  Educator: 'Wychowawcy',
  HealthCarer: 'Opieka medyczna',
  LifeGuard: 'Ratownicy wodni',
  Quartermaster: 'Kwatermistrz',
  Participant: 'Uczestnicy',
  Approval: 'Zatwierdzanie',
  Accounting: 'Rozliczanie'
}

export const labels = {
  units: 'Jednostki organizujące',
  type: 'Rodzaj',
  address: 'Adres',
  from: 'Od',
  to: 'Do',
  comment: 'Uwagi',
  //places
  gpsData: 'Współrzędne gps',
  attendees: 'Liczba uczestników',
  registration: 'Zgłoszenie do kuratorium oświaty',
  leaseContract: 'Umowa / rezerwacja miejsca noclegów',
  healthCenter: 'Najbliższy ośrodek zdrowia',
  hospital: 'Najbliższy szpital',
  accountedLeaseContract: 'Rozliczona umowa dzierżawy',
  accountedRegistration: 'Rozliczone zgłoszenie do Kuratowium Oświaty',
  //units
  name: 'Nazwa',
  schedule: 'Plan pracy',
  dues: 'Opłacone składki',
  financialRecord: 'Złożona dokumentacja finansowa',
  accountedSchedule: 'Rozliczony plan pracy',
  accountedTimeSheet: 'Rozliczona książka pracy',
  //command
  firstName: 'Imię',
  lastName: 'Nazwisko',
  PESEL: 'Pesel',
  phone: 'Telefon',
  email: 'Email',
  zipCode: 'Kod pocztowy',
  city: 'Miejscowość',
  rank: 'Stopień harcerski',
  instructorRank: 'Stopień instruktorski',
  leaderCertificate: 'Zaświadczenie z kursu kierowników',
  internshipCertificate: 'Zaświadczenie o 3-letnim stazu wychowawczym',
  phmInstructorCertificate: 'Zaświadczenie o byciu czynnym instruktorem ZHR w stopniu conajmniej phm',
  KRKCertificate: 'Zaświadczenie z KRK',
  medicalCertificate: 'Zaświadczenie lekarskie o braku przeciwskazań do pełnienia funkcji',
  DDStatement: 'Oświadczenie DD',
  ZMStatement: 'Oświadczenie ZM',
  paidDues: 'Opłacone składki instruktorskie',
  isEducator: 'Wpisany na listę wychowawców',
  accountedDocuments: 'Rozliczone dokumenty potwierdzające uprawnienia',
  accountedMedicalCertificate: 'Rozliczone zaświadczenie lekarskie',
  accountedKRKCertificate: 'Rozliczone zaświadczenie z KRK',
  accountedDDStatement: 'Rozliczone oświadczenie DD',
  accountedZMStatement: 'Rozliczone oświadczenie ZM',
  //quartermaster
  sanepidBook: 'Książeczka sanepidowska',
  carrierTest: 'Badania na nosicielstwo',
  voluntaryContract: 'Umowa wolontariacka',
  passedQuatermasterCourse: 'Zdany kurs kwatermistrzowski',
  accountedMedicalBook: 'Rozliczona książeczka lub badania lekarskie',
  accountedContract: 'Rozliczona umowa',
  //educator
  unit: 'Jednostka',
  educatorCertificate: 'Zaświadczenie z kursu wychowawców',
  instructorCertificate: 'Zaświadczenie o byciu czynnym instruktorem ZHR',
  //healthCarer
  practicePermit: 'Poświadczenia prawa wykonywania zawodu',
  contractStatement: 'Oświadczenie do umowy zlecenie',
  //lifeGuard
  woprId: 'Poświadczenie uprawnień ratownika wodnego',
  //participants
  educator: 'Wychowawca',
  //approval
  moneyStorage: 'Forma przechowywania pieniędzy',
  booking: 'Księgowanie na bieżąco w książce finansowej',
  billsList: 'Lista odpłatności',
  participantsDeposit: 'Zebrane 70% wpłat uczestników',
  mazovianScoutApproval: 'Zatwierdzenie Mazowieckiej Chorągwi Harcerzy',
  mazovianGuideApproval: 'Zatwierdzenie Mazowieckiego Namiestnicta Harcerek',
  northEastGuideApproval: 'Zatwierdzenie Północno-wschodniego Namiestnictwa Harcerek',
  northEastScoutApproval: 'Zatwierdzenie Północno-wschodniej Chorągwi Harcerzy',
  administrativeCosts: 'Wpłacenie do kasy kosztów administracyjnych',
  info: 'Informacje o zgrupowaniu/kratka zgrupowania',
  //accounting
  participantsCount: 'Liczba uczestników',
  participantsList: 'Lista uczestników',
  staffList: 'Lista kadry',
  cards: 'Karty',
  cardsCount: 'Ilość kart',
  cashBook: 'Książka finansowa',
  lastCashBookPosition: 'Numer ostatniej pozycji w książce kasowej',
  bankBook: 'Książka bankowa',
  lastBankBookPosition: 'Numer ostatniej pozycji w książce bankowej',
  receiptBooks: 'Kwitariusz KP',
  lastReceiptBookPosition: 'Numer ostatniego kwitu KP',
  paymentsList: 'Lista odpłatności',
  returnsList: 'Lista zwrotów',
  timeSheet: 'Książka pracy zgrupowania',
  accidentsRegistry: 'Rejestr wypadków',
  proceduresBook: 'Książka porad i zabiegów',
  medicalDocuments: 'Dokumenty medyczne',
  menu: 'Jadłospisy',
  visitProtocol: 'Protokoły wizytacyjne',
  contracts: 'Umowy',
  officePapers: 'Pisma do urzędów i instytucji',
  financialBookUploaded: 'Przesłana książka finansowa w Excelu',
  participantsListUploaded: 'Przesłana lista uczestników',
  paymentsListUploaded: 'Przesłana lista odpłatności',
  staffListUploaded: 'Przesłana lista kadry'
}

export const editableFieldsCount = {
  Description: 6,
  Place: 11,
  Unit: 7,
  Command: 25,
  Cook: 13,
  Driver: 13,
  Educator: 25,
  HealthCarer: 15,
  LifeGuard: 12,
  Quartermaster: 24,
  Participant: 6,
  Approval: 10,
  Accounting: 26
}

export const acceptableFieldsCount = {
  Description: 0,
  Place: 9,
  Unit: 3,
  Command: 20,
  Cook: 8,
  Driver: 8,
  Educator: 20,
  HealthCarer: 10,
  LifeGuard: 7,
  Quartermaster: 19,
  Participant: 6,
  Approval: 1,
  Accounting: 0
}

export const paths = {
  Description: '',
  Place: '/places',
  Unit: '/units',
  Command: '/staff/commands',
  Cook: '/staff/cooks',
  Driver: '/staff/drivers',
  Educator: '/staff/educators',
  HealthCarer: '/staff/healthCarers',
  LifeGuard: '/staff/lifeGuards',
  Quartermaster: '/staff/quartermasters',
  Participant: '/participants',
  Approval: '/approval',
  Accounting: '/accounting'
}

export const fieldsTypes = {
  Description: {
    unfilled: true
  },
  Place: {
    unfilled: true,
    unapproved: true,
    files: true
  },
  Unit: {
    unfilled: true,
    unapproved: true,
    files: true
  },
  Command: {
    unfilled: true,
    unapproved: true,
    files: true
  },
  Cook: {
    unfilled: true,
    unapproved: true,
    files: true
  },
  Driver: {
    unfilled: true,
    unapproved: true,
    files: true
  },
  Educator: {
    unfilled: true,
    unapproved: true,
    files: true
  },
  HealthCarer: {
    unfilled: true,
    unapproved: true,
    files: true
  },
  LifeGuard: {
    unfilled: true,
    unapproved: true,
    files: true
  },
  Quartermaster: {
    unfilled: true,
    unapproved: true,
    files: true
  },
  Participant: {
    unfilled: true,
    unapproved: true,
  },
  Approval: {
    unfilled: true
  },
  Accounting: {
    unfilled: true,
  },
}
