import React, { Component } from 'react';
import PT from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom';
import moment from 'moment';
import { fetchPlaces, deletePlace, toggleAcceptPlace, commentPlace } from 'modules/places/actions';
import { closeModal, showToast } from 'theme/actions';
import Header from 'theme/Header';
import Navbar from 'theme/Navbar';
import Table, { AcceptCommentCell, ActionsCell, Row, openModal, accept, comment } from 'theme/Table';
import Footer from 'theme/Footer';
import Loader from 'theme/Loader';
import Modal from 'theme/Modal';
import DownloadButton from 'theme/DownloadButton';
import roles from 'modules/places/roles';

import 'theme/common-views.sass';
import './Places.sass'


const HEADINGS = [
  {key: 'from', label: 'Od'},
  {key: 'to', label: 'Do'},
  {key: 'address', label: 'Adres'},
  {key: 'gpsData', label: 'Współrzędne'},
  {key: 'hospital', label: 'Szpital'},
  {key: 'healthCenter', label: 'Ośrodek zdrowia'},
  {key: 'registration', label: 'Zgłoszenie do kuratorium'},
  {key: 'leaseContract', label: 'Umowa / rezerwacja miejsca noclegów'},
  {key: 'meta', label: ''}
]

class PlacesList extends Component {

    componentDidMount() {
      this.props.fetchPlaces(this.props.match.params.projectId);

      this.roles = Object.keys(roles)
      .map( key => roles[key].filledBy)
      .reduce( (a, b) => a.concat(b));

      const rolesCount = this.roles
      .reduce( (sum, role) => ({...sum, [role]: (sum[role] || 0) + 1}), {});

      this.deleteRole = Object.keys(rolesCount)
      .reduce( (max, role) => rolesCount[max] >= rolesCount[role] ? max : role);
    }

    render() {
      const {
        isFetching,
        selectedPlace,
        showToast,
        closeModal,
        places,
        toggleAcceptPlace,
        commentPlace,
        loadingActions,
        user,
        activeProject
      } = this.props;
      const projectId = this.props.match.params.projectId;

      const deletePlace = (...args) => () => {
        closeModal();
        this.props.deletePlace(...args)
        .catch(() => {
          const message = (
            <div>
              Nie udało się usunąć miejsca. Spróbuj ponownie później
            </div>
          )
          showToast({message, error: true});
        })
      };

      const editable = activeProject.state == 'Open';

      const canEdit = user.isAdmin || this.roles && this.roles.includes(user.role.name);
      const canDelete = editable && (user.isAdmin || this.deleteRole == user.role.name);
      const canDoActions = canDelete || canEdit;
      const headings = canDoActions ? HEADINGS : HEADINGS.slice(0, -1);

      return (
        <div className="List">
          <Header />
          <Navbar />
          <div className="List__container">

            <div className="List__heading">
              <div>
                <h3>Miejsca</h3>
                <p>Miejsce obozu/kolonii/zimowiska (w przypadku obozu wędrownego – miejsca planowanych noclegów)</p>
              </div>
              {canEdit && editable &&
                <Link
                  to={`/projects/${projectId}/places/new`}
                  className="List__add"
                >Dodaj</Link>
              }
            </div>


            <div className="List__card">
              <Loader
                loading={isFetching}
                className="List__loader"
              />
              <div className={cx("List__cardContainer", {blur: isFetching})}>
                {places.length == 0
                  ? <h4>Do tego projektu nie dodano jeszcze żadnych miejsc.</h4>
                  :
                  <Table
                    headings={headings}
                    accept={editable && accept(toggleAcceptPlace, projectId, HEADINGS)}
                    comment={comment(commentPlace, projectId)}
                    permissions={roles}
                  >
                    {places.map( place => {
                      const loadingPlace = loadingActions[place.id] && ['AcceptPlace', 'DeletePlace'].includes(loadingActions[place.id].name);
                      return (
                        <Row
                          key={place.id}
                          loading={loadingPlace}
                        >
                          <AcceptCommentCell data={place}>
                            {place.from && moment(place.from).format('DD.MM.YYYY')}
                          </AcceptCommentCell>
                          <AcceptCommentCell data={place}>
                            {place.to && moment(place.to).format('DD.MM.YYYY')}
                          </AcceptCommentCell>
                          <AcceptCommentCell data={place}>
                            {place.address}
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={place}
                            className="Places__gpsData"
                          >
                            {place.gpsData}
                          </AcceptCommentCell>
                          <AcceptCommentCell data={place}>
                            {place.hospital}
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={place}
                            commentsPosition="left"
                          >
                            {place.healthCenter}
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={place}
                            commentsPosition="left"
                          >
                            {place.registration && place.registration.fileId &&
                              <DownloadButton file={place.registration} />
                            }
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={place}
                            commentsPosition="left"
                          >
                            {place.leaseContract && place.leaseContract.fileId &&
                              <DownloadButton file={place.leaseContract} />
                            }
                          </AcceptCommentCell>
                          {canDoActions &&
                            <ActionsCell
                              data={place}
                              del={canDelete && openModal('deletePlace')}
                              editPath={canEdit && `/projects/${projectId}/places/${place.id}`}
                              acceptable={editable && user.isAdmin}
                            />
                          }
                        </Row>
                      )
                    })}
                  </Table>
                }
              </div>
            </div>
          </div>
          <Modal
            className="List__modal"
            header={<h4>Uwaga!</h4>}
          >
            <p>
              Czy na pewno chcesz usunąć miejsce <b>{selectedPlace.address}</b>?
            </p>
            <div className="List__modalActions">
              <button
                className="List__modalDiscard"
                onClick={closeModal}
              >Anuluj</button>
              <button
                className="List__modalOk"
                onClick={deletePlace(projectId, selectedPlace.id)}
              >Usuń</button>
            </div>
          </Modal>
          <Footer />
        </div>
      )
    }
}

PlacesList.propTypes = {
  places: PT.arrayOf(PT.shape({
    id: PT.string
  })),
  fetchPlaces: PT.func,
  isFetching: PT.bool,
  selectedPlace: PT.shape({
    id: PT.string,
    name: PT.string
  }),
  deletePlace: PT.func.isRequired,
  closeModal: PT.func.isRequired,
  showToast: PT.func.isRequired
}

const mapStateToProps = ({data: {places, activeProject}, isFetching, modal, auth }) => ({
  places: places || [],
  isFetching: isFetching.global,
  selectedPlace: modal.payload || {},
  loadingActions: isFetching.objects,
  user: auth.user,
  activeProject: activeProject || {}
})

export default withRouter(connect(
  mapStateToProps,
  { fetchPlaces, deletePlace, showToast, closeModal, toggleAcceptPlace, commentPlace }
)(PlacesList))
