import React, { Component }       from 'react';
import PT                         from 'prop-types';
import cx                         from 'classnames';
import _                          from 'underscore';
import deep                       from 'deep-diff';
import { connect }                from 'react-redux'
import { withRouter }             from 'react-router'
import {
        fetchQuartermaster,
        editQuartermaster,
        addQuartermaster }        from 'modules/quartermasters/actions';
import { fetchFiles }             from 'modules/files/actions';
import { fetchProject }           from 'modules/projects/actions';
import { showToast }              from 'theme/actions';
import Header                     from 'theme/Header';
import Navbar                     from 'theme/Navbar';
import Footer                     from 'theme/Footer';


import 'theme/common-views.sass';

import QuartermasterForm          from './QuartermasterForm'


class QuartermasterDetails extends Component {

    componentDidMount() {
      const {projectId, quartermasterId} = this.props.match.params;
      if(projectId && quartermasterId)
        this.props.fetchQuartermaster(projectId, quartermasterId)
    }

    render() {
      const {isFetching, editQuartermaster, addQuartermaster, showToast, match, quartermaster, user, activeProject} = this.props;
      const {projectId, quartermasterId} = match.params;
      const edit = quartermasterId != null;
      const initialForm = activeProject.description ? {from: activeProject.description.from, to: activeProject.description.to} : null;

      const submit = (values) => {
        const diff = edit && _.pluck(deep.diff(quartermaster, values), 'path');
        const vals = diff ? _.pick(values, ..._.map(diff, _.first)) : values;
        const func = edit ? editQuartermaster : addQuartermaster;
        return func({projectId, id: quartermasterId}, vals)
        .then(() => {
          const message = <div>Zapisano kwatermistrza</div>
          showToast({message});
        })
        .catch(() => {
          const message = (
            <div>
              Nie udało się zapisać kwatermistrza. Spróbuj ponownie później
            </div>
          )
          showToast({message, error: true});
        })
      }

      return (
        <div className="Quartermaster">
          <Header />
          <Navbar />
          <div className={cx("Details__container", {blur: isFetching})}>

            <div className="Details__heading">
              <h3>Kwatermistrz</h3>
              <p>
                {edit
                  ? 'Edycja kwatermistrza'
                  : 'Nowy kwatermistrz'
                }
              </p>
            </div>

            <QuartermasterForm
              initialValues={edit ? quartermaster : initialForm}
              onSubmit={submit}
              loading={isFetching}
              user={user}
              disabled={activeProject.state != 'Open'}
            />
          </div>
          <Footer />
        </div>
      )
    }
}

QuartermasterDetails.propTypes = {
  quartermaster: PT.shape({
    id: PT.string
  }),
  fetchQuartermaster: PT.func,
  editQuartermaster: PT.func,
  addQuartermaster: PT.func,
  showToast: PT.func.isRequired
}

const mapStateToProps = ({data: {quartermaster, files, activeProject}, isFetching, auth: {user}}) => ({
  quartermaster,
  files,
  isFetching: isFetching.global,
  user,
  activeProject: activeProject || {}
})

export default withRouter(connect(
  mapStateToProps,
  { fetchQuartermaster, editQuartermaster, addQuartermaster, showToast, fetchFiles, fetchProject }
)(QuartermasterDetails))
