export default {
  address: {
    filledBy: ['CampCommander', 'Office'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  gpsData: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  attendees: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  from: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  to: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  registration: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  leaseContract: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  healthCenter: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  hospital: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  accountedLeaseContract: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
  },
  accountedRegistration: {
    filledBy: ['Office'],
    acceptedBy: [],
    commentable: true
  }
}
// Region
// Office
// Banner
// Detachment
// CampCommander
