import api, { pickAllowedProps } from 'api';
import { pick, omit, intersection } from 'underscore';
import { push } from 'react-router-redux';
import { requestSuccess, createRequest, handleError } from 'actions/requests';
import { saveFiles, matchFiles } from 'modules/files/actions';
import roles from './roles'

const FILE_FIELDS = [
  'woprId',
  'voluntaryContract',
  'contractStatement'
];


export function fetchLifeGuards(projectId, opts = {loader: true}) {
  return (dispatch) => {
    opts && opts.loader && dispatch(createRequest({global: true, name: 'lifeGuards'}));
    return api({
      method: 'get',
      url: `/projects/${projectId}/lifeGuards`
    })
    .then((response) => {
			dispatch(requestSuccess(response, 'lifeGuards', opts.id));
			return response.data;
		})
    .catch(handleError(dispatch));
  }
}

export function fetchLifeGuard(projectId, id) {
  return (dispatch) => {
    dispatch(createRequest());
    return api({
      method: 'get',
      url: `/projects/${projectId}/lifeGuards/${id}`
    })
    .then((response) => dispatch(matchFiles(response, FILE_FIELDS, 'lifeGuard')))
    .catch(handleError(dispatch));
  }
}


export function addLifeGuard({projectId}, lifeGuard) {
  const allowedProps = pickAllowedProps(roles);
  return (dispatch) => {
    dispatch(createRequest());
    return saveFiles(lifeGuard, intersection(FILE_FIELDS, allowedProps))
    .then( filesObj => {
      const req = pick(omit(lifeGuard, FILE_FIELDS), allowedProps);
      return api({
        method: 'post',
        url: `/projects/${projectId}/lifeGuards`,
        data: {
          ...req,
          ...filesObj
        }
      })
      .then((response) => {
        dispatch(matchFiles(response, FILE_FIELDS, 'lifeGuard'));
        dispatch(push(`/projects/${projectId}/staff/lifeGuards`));
      })
    })
    .catch(handleError(dispatch));
  }
}

export function editLifeGuard({projectId, id, disableRedirect}, lifeGuard) {
  const allowedProps = pickAllowedProps(roles);
  return (dispatch) => {
    dispatch(createRequest({name: 'editLifeGuard'}));
    return saveFiles(lifeGuard, intersection(FILE_FIELDS, allowedProps))
    .then( filesObj => {
      const req = pick(omit(lifeGuard, FILE_FIELDS), allowedProps);
      return api({
        method: 'patch',
        url: `/projects/${projectId}/lifeGuards/${id}`,
        data: {
          ...req,
          ...filesObj
        }
      })
      .then((response) => {
        dispatch(matchFiles(response, FILE_FIELDS, 'lifeGuard'));
        !disableRedirect && dispatch(push(`/projects/${projectId}/staff/lifeGuards`));
      })
    })
    .catch(handleError(dispatch));
  }
}


export function deleteLifeGuard(projectId, id) {
  return (dispatch) => {
    dispatch(createRequest({name: 'DeleteLifeGuard', id}));
    return api({
      method: 'delete',
      url: `/projects/${projectId}/lifeGuards/${id}`
    })
    .then(() => dispatch(fetchLifeGuards(projectId, {loader: false, id})))
    .catch(handleError(dispatch, id));
  }
}

export function toggleAcceptLifeGuard(projectId, lifeGuard, state, ...props) {
  return (dispatch) => {
    dispatch(createRequest({name: 'AcceptLifeGuard', id: lifeGuard.id}));
    const prom = props.map( prop => api({
      method: 'put',
      url: `/projects/${projectId}/lifeGuards/${lifeGuard.id}/${prop}/state`,
      data: {state}
    }))
    return Promise.all(prom)
    .then( () => dispatch(fetchLifeGuards(projectId, {loader: false, id: lifeGuard.id})))
    .catch(handleError(dispatch, lifeGuard.id));
  }
}

export function commentLifeGuard(projectId, lifeGuard, prop, data) {
  return (dispatch) => {
    dispatch(createRequest({name: 'CommentLifeGuard', id: lifeGuard.id}));
    return api({
      method: 'post',
      url: `/projects/${projectId}/lifeGuards/${lifeGuard.id}/${prop}/comments`,
      data
    })
    .then( () => dispatch(fetchLifeGuards(projectId, {loader: false, id: lifeGuard.id})))
    .catch(handleError(dispatch, lifeGuard.id));
  }
}
