import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Toast from 'theme/Toast';
import * as actions from 'actions';

import './Main.sass';


const Main = ({ children }) => {
  return (
    <div className="Main">
      <div className="Main__content">
        {children}
      </div>
      <Toast />
    </div>
  );
}

function mapStateToProps({auth}) {
  return {user: auth.user}
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
