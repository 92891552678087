import jwtDecode from 'jwt-decode';

export const loadAuth = () => {
  try {
    const serializedState = localStorage.getItem("Zhr__auth");
    if (serializedState == null) return {};
    return JSON.parse(serializedState);
  } catch (err) {
    return null;
  }
}

export const saveToken = (token) => {
  try {
    const user = jwtDecode(token);
    const serializedState = {
      token,
      user: {
        ...user,
        role: {}
      }
    };
    localStorage.setItem("Zhr__auth", JSON.stringify(serializedState));
    return serializedState;
  } catch (err) {
    //console.log(err);
  }
}

export const saveRole = (role) => {
  try {
    const auth = loadAuth();
    const serializedState = {
      ...auth,
      user: {
        ...auth.user,
        role
      }
    };
    localStorage.setItem("Zhr__auth", JSON.stringify(serializedState));
    return serializedState;
  } catch (err) {
    //console.log(err);
  }
}

export const deleteAuth = () => {
  try {
    localStorage.removeItem("Zhr__auth");
  } catch (err) {
    // console.log(err);
  }
}