import React                from 'react';
import cx                   from 'classnames';
import { reduxForm, Field } from 'redux-form';
import Loader               from 'theme/Loader';
import roles                from 'modules/accounting/roles';
import Comments             from 'theme/Comments';
import Form,
      {
        Input,
        Textarea,
        SaveButton,
        CommentableField
      }                     from 'theme/Form';

const fields = [
	'participantsCount',
	'participantsList',
	'staffList',
	'cards',
	'cardsCount',
	'comment'
]


const ParticipantsForm = reduxForm({
  enableReinitialize: true,
  form: 'AccountingParticipantsForm'
})(({handleSubmit, fetching, commentsProps, showComments, user, pristine, submitting, accounting, disabled}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <div className="Details__card">
        <Loader loading={fetching} />
        <div className={cx("Details__cardContainer", {blur: fetching})}>
          <div className="Details__row">
            <div className="Accounting__column">
              <CommentableField
                commentsProps={commentsProps}
                showComments={showComments}
                prop="participantsCount"
                data={accounting}
              >
                <Field
                  component={Input}
                  name="participantsCount"
                  type="number"
                  label="Liczba uczestników"
                  disabled={disabled || !user.isAdmin && !roles.participantsCount.filledBy.includes(user.role.name)}
                />
              </CommentableField>
              <CommentableField
                commentsProps={commentsProps}
                showComments={showComments}
                prop="participantsList"
                data={accounting}
              >
                <Field
                  component={Input}
                  name="participantsList"
                  label="Lista uczestników"
                  type="checkbox"
                  disabled={disabled || !user.isAdmin && !roles.participantsList.filledBy.includes(user.role.name)}
                />
              </CommentableField>
              <CommentableField
                commentsProps={commentsProps}
                showComments={showComments}
                prop="staffList"
                data={accounting}
              >
                <Field
                  component={Input}
                  name="staffList"
                  label="Lista kadry"
                  type="checkbox"
                  disabled={disabled || !user.isAdmin && !roles.staffList.filledBy.includes(user.role.name)}
                />
              </CommentableField>
              <CommentableField
                commentsProps={commentsProps}
                showComments={showComments}
                prop="cards"
                data={accounting}
              >
                <Field
                  component={Input}
                  name="cards"
                  label="Karty"
                  type="checkbox"
                  disabled={disabled || !user.isAdmin && !roles.cards.filledBy.includes(user.role.name)}
                />
              </CommentableField>
            </div>
            <div className="Accounting__column">
              <CommentableField
                commentsProps={commentsProps}
                showComments={showComments}
                prop="cardsCount"
                data={accounting}
              >
                <Field
                  component={Input}
                  type="number"
                  name="cardsCount"
                  label="Liczba kart"
                  disabled={disabled || !user.isAdmin && !roles.cardsCount.filledBy.includes(user.role.name)}
                />
              </CommentableField>
              <CommentableField
                commentsProps={commentsProps}
                showComments={showComments}
                prop="comment"
                data={accounting}
              >
                <Field
                  component={Textarea}
                  name="comment"
                  label="Uwagi"
                  disabled={disabled || !user.isAdmin && !roles.comment.filledBy.includes(user.role.name)}
                />
              </CommentableField>
            </div>
          </div>
        </div>
      </div>
      {!disabled &&
        <div className="Accounting__btn">
          <SaveButton
            disabled={pristine || submitting}
            saving={submitting}
          >Zapisz</SaveButton>
        </div>
      }
    </Form>
  )
})

export default ({comment: commentAcocunting, accounting, commentsProps, ...rest}) => {
  const {visible, prop} = commentsProps;
  const comment = (prop) => (values) => commentAcocunting(prop, values);
  return (
    <div>
      <ParticipantsForm
        {...rest}
        commentsProps={commentsProps}
        initialValues={accounting}
        accounting={accounting}
      />
      {visible && prop && fields.includes(prop) &&
        <Comments
          comment={comment(prop)}
          comments={accounting && accounting.meta && accounting.meta[prop] && accounting.meta[prop].comments}
          className="Accounting__commentBox"
          hideArrow
        />
      }
    </div>
  )
}
