import _ from 'underscore';
import { TOGGLE_PARTICIPANT, TOGGLE_ALL_PARTICIPANTS } from 'types';

export default (
  state = {participantsIds: []},
  action
) => {
  switch (action.type) {
    case TOGGLE_PARTICIPANT:
      if (action.participant) {
        const isChecked = state.participantsIds.includes(action.participant.id);
        return {
          ...state,
          participantsIds: isChecked
            ? _.without(state.participantsIds, action.participant.id)
            : [...state.participantsIds, action.participant.id]
        }
      }
      return state;

    case TOGGLE_ALL_PARTICIPANTS:
      if(action.participants) {
        if (action.participants.length == state.participantsIds.length)
          return {
            ...state,
            participantsIds: []
          }
        return {
          ...state,
          participantsIds: _.pluck(action.participants, 'id')
        }
      }
      return state;
    default: return state;
  }
}