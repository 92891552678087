import React from 'react';
import cx from 'classnames';
import PT from 'prop-types';

import './Loader.sass';

const Loader = ({ loading, className, noIcon }) => (
  <div className={cx("Loader", {visible: loading}, className)}>
    {!noIcon && <span className="fa fa-spin fa-spinner" />}
  </div>
)

Loader.propTypes = {
  loading: PT.bool,
  className: PT.string
}

export default Loader;
