import React from 'react';
import { connect } from 'react-redux';
import { showToast } from 'actions';
import Loader from 'modules/theme/Loader';


export default connect(null, {showToast})(
  class extends React.Component {
    constructor ({checked}) {
      super();
      this.state = {checked, loading: false};
      this.change = this.change.bind(this);
    }

    change (e) {
      const {onChange, name, showToast} = this.props;
      e.persist();
      this.setState({checked: e.target.checked, loading: true});
      return onChange({[name]: e.target.checked})
      .then(() => this.setState({loading: false}))
      .catch(() => {
        showToast({message: <div>Nie można zapisać pola. Spróbuj ponownie później</div>, error: true});
        this.setState({checked: !e.target.checked, loading: false});
      })
    }


    render () {
      return (
        <div>
          <input
            type="checkbox"
            checked={this.props.checked != this.state.checked ? this.state.checked : this.props.checked || false}
            onChange={this.change}
            disabled={this.props.disabled || this.state.loading}
          />
          <Loader loading={this.state.loading} />
        </div>
      )
    }
  }
)
