import React                from 'react';
import cx                   from 'classnames';
import { reduxForm, Field } from 'redux-form';
import Loader               from 'theme/Loader';
import roles                from 'modules/accounting/roles';
import Comments             from 'theme/Comments';
import Form,
      {
        Input,
        Radio,
        SaveButton,
        CommentableField
      }                     from 'theme/Form';

const options = [
  {label: 'Tak', value: 'yes'},
  {label: 'Nie', value: 'no'},
  {label: 'Nie dotyczy', value: 'n/a'}
]

const fields = [
	'timeSheet',
	'accidentsRegistry',
	'proceduresBook',
	'medicalDocuments',
	'menu',
	'visitProtocol',
	'contracts',
  'officePapers'
]


const DocumentsForm = reduxForm({
  enableReinitialize: true,
  form: 'AccountingDocumentsForm'
})(({handleSubmit, fetching, commentsProps, showComments, user, pristine, submitting, accounting, disabled}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <div className="Details__card">
        <Loader loading={fetching} />
        <div className={cx("Details__cardContainer", {blur: fetching})}>
          <div className="Details__row">
            <div className="Accounting__column">
              <CommentableField
                commentsProps={commentsProps}
                showComments={showComments}
                prop="timeSheet"
                data={accounting}
              >
                <Field
                  component={Input}
                  name="timeSheet"
                  label="Książka pracy zgrupowania"
                  type="checkbox"
                  disabled={disabled || !user.isAdmin && !roles.timeSheet.filledBy.includes(user.role.name)}
                />
              </CommentableField>
              <CommentableField
                commentsProps={commentsProps}
                showComments={showComments}
                prop="accidentsRegistry"
                data={accounting}
              >
                <Field
                  component={Input}
                  name="accidentsRegistry"
                  label="Rejestr wypadków"
                  type="checkbox"
                  disabled={disabled || !user.isAdmin && !roles.accidentsRegistry.filledBy.includes(user.role.name)}
                />
              </CommentableField>
              <CommentableField
                commentsProps={commentsProps}
                showComments={showComments}
                prop="proceduresBook"
                data={accounting}
              >
                <Field
                  component={Input}
                  name="proceduresBook"
                  label="Rejestr porad i zabiegów"
                  type="checkbox"
                  disabled={disabled || !user.isAdmin && !roles.proceduresBook.filledBy.includes(user.role.name)}
                />
              </CommentableField>
              <CommentableField
                commentsProps={commentsProps}
                showComments={showComments}
                prop="medicalDocuments"
                data={accounting}
              >
                <Field
                  component={Input}
                  name="medicalDocuments"
                  label="Dokumenty medyczne (czy zostały przekazane rodzicom)"
                  type="checkbox"
                  disabled={disabled || !user.isAdmin && !roles.medicalDocuments.filledBy.includes(user.role.name)}
                />
              </CommentableField>
              <CommentableField
                commentsProps={commentsProps}
                showComments={showComments}
                prop="menu"
                data={accounting}
              >
                <Field
                  component={Radio}
                  name="menu"
                  label="Jadłospisy"
                  options={options}
                  disabled={disabled || !user.isAdmin && !roles.menu.filledBy.includes(user.role.name)}
                />
              </CommentableField>
              <CommentableField
                commentsProps={commentsProps}
                showComments={showComments}
                prop="visitProtocol"
                data={accounting}
              >
                <Field
                  component={Radio}
                  name="visitProtocol"
                  label="Protokoły wizytacyjne"
                  info="Sanepid, KO, straż pożarna, donator lokalny, Okręg, KRO, KRZ"
                  options={options}
                  disabled={disabled || !user.isAdmin && !roles.visitProtocol.filledBy.includes(user.role.name)}
                />
              </CommentableField>
            </div>
            <div className="Accounting__column">
              <CommentableField
                commentsProps={commentsProps}
                showComments={showComments}
                prop="contracts"
                data={accounting}
              >
                <Field
                  component={Radio}
                  name="contracts"
                  label="Umowy"
                  info="Nadleśnictwo, szkoła, schronisko, ośrodek"
                  options={options}
                  disabled={disabled || !user.isAdmin && !roles.contracts.filledBy.includes(user.role.name)}
                />
              </CommentableField>
              <CommentableField
                commentsProps={commentsProps}
                showComments={showComments}
                prop="officePapers"
                data={accounting}
              >
                <Field
                  component={Radio}
                  name="officePapers"
                  label="Pisma do urzędów i instytucji"
                  info="Jeśli były wysłane bez wiadomości biura okręgu"
                  options={options}
                  disabled={disabled || !user.isAdmin && !roles.officePapers.filledBy.includes(user.role.name)}
                />
              </CommentableField>
            </div>
          </div>
        </div>
      </div>
      {!disabled &&
        <div className="Accounting__btn">
          <SaveButton
            disabled={pristine || submitting}
            saving={submitting}
          >Zapisz</SaveButton>
        </div>
      }
    </Form>
  )
})

export default ({comment: commentAccounting, accounting, commentsProps, ...rest}) => {
  const {visible, prop} = commentsProps;
  const comment = (values) => commentAccounting(prop, values);
  return (
    <div>
      <DocumentsForm
        {...rest}
        commentsProps={commentsProps}
        initialValues={accounting}
        accounting={accounting}
      />
      {visible && prop && fields.includes(prop) &&
        <Comments
          comment={comment}
          comments={accounting && accounting.meta && accounting.meta[prop] && accounting.meta[prop].comments}
          className="Accounting__commentBox"
          hideArrow
        />
      }
    </div>
  )
}
