import React from 'react'
import { reduxForm, Field } from 'redux-form'
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { login } from 'actions';
import { Input } from 'theme/Form';

import './Login.sass'


const validate = values => (
  {
    password: !values.password && 'Wymagane',
    email: !values.email && 'Wymagane'
  }
)

const Component = reduxForm({
  form: 'LoginForm',
  validate
})(
  ({handleSubmit, err, submitting, pristine, submitFailed}) => {
    return(
      <div className="Login">
        <div className="Login__card">
          <div className="Login__header">
            <img src="/assets/logo.png" alt="zhr-logo"/>
          </div>
          <form onSubmit={handleSubmit}>
            <Field
              name="email"
              type="text"
              component={Input}
              placeholder="Email"
              className="Login__field"
            />
            <Field
              name="password"
              type="password"
              component={Input}
              placeholder="Hasło"
              className="Login__field"
            />
            {submitFailed && !submitting &&
              <p className="Login__error">
                {
                  err == 'Invalid credentials' && 'Nieprawidłowy email lub hasło' ||
                  err == 'Account was deleted' && 'Konto zostało usunięte' ||
                  'Coś poszło nie tak. Spróbuj ponownie później'
                }
              </p>
            }
            <div className="Login__btnContainer">
              <button type="submit" className="btn btn_primary btn_secondary" disabled={submitting | pristine}>
                Login
                {submitting == true && <i className="fa fa-spin fa-spinner" />}
              </button>
            </div>
          </form>
        </div>
        <Link
          className="Login__forgot"
          to="/reset"
        >Zapomniałem hasła</Link>
        <div className="Login__img">
          <div>
            <img src="/assets/halihaz_white.png" alt="Okręg Mazowiecki ZHR" />
          </div>
          <div>
            <p>Projekt współfinansuje<br />m. st. Warszawa</p>
            <img src="/assets/logo_wspolfinansowanie.png" alt="Wpółfinansowan przez m. st. Warszawa" />
          </div>
        </div>
      </div>
    )
  }
)

const mapStateToProps = ({data: {error}, auth}) => {
  return {
      err: error,
      isFetching: auth.loading
    }
  }

const Login = withRouter(connect(
  mapStateToProps,
  {onSubmit: login}
)(Component))

export default Login;