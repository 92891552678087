import React from 'react';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { logout, toggleUserModal } from 'actions'


import './Header.sass';


const Component = ({avatarModalVisible, toggleUserModal, logout, user}) => (
  <div className="Header">
    <div className="Header__container">
      <Link to="/" className="Header__logo">
        <div className="Header__logoImage" />
      </Link>
      <div className="Header__options">
        <Link
          to={'/projects'}
          className={cx({active: location.pathname.indexOf('/projects') == 0})}
        >Projekty</Link>
        {user && user.isAdmin &&
          <Link
            to={'/users'}
            className={cx({active: location.pathname.indexOf('/users') == 0})}
          >Użytkownicy</Link>
        }
      </div>
      <button
        onClick={toggleUserModal}
        className="Header__logout"
      >Wyloguj</button>
      <div
        className={cx("Header__overlay", {visible: avatarModalVisible})}
        onClick={toggleUserModal}
      >
        <div className="Header__dropdown">
          <button
            onClick={() => logout()}
          >
            <span className="fa fa-power-off" />
            <span>Wyloguj</span>
          </button>
        </div>
      </div>
    </div>
  </div>
)

Component.propTypes = {
  toggleUserModal: PT.func.isRequired,
  logout: PT.func.isRequired,
  avatarModalVisible: PT.bool.isRequired
}

const mapStateToProps = ({ auth, avatarModalVisible }) => {
  return {
    user: auth.user,
    avatarModalVisible
  }
}

const Header = withRouter(connect(
  mapStateToProps,
  { logout, toggleUserModal }
)(Component))

export default Header;
