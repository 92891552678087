import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { reduxForm, Field} from 'redux-form';
import Form, { Input, Textarea, Datepicker, FileInput, SaveButton, Group } from 'theme/Form';
import Loader from 'theme/Loader';
import roles from 'modules/places/roles';

import 'react-datepicker/dist/react-datepicker.css';


export default withRouter(reduxForm({
  form: 'ProjectForm',
  enableReinitialize: true
})(
  ({handleSubmit, pristine, submitting, match, loading, user, disabled}) => {
    const {projectId} = match.params;
    const isAdmin = user && user.isAdmin;

    return (
      <Form
        className="Details__form"
        onSubmit={handleSubmit}
      >
        <div className="Details__row">
          <div className="Details__card">
            <Loader loading={loading} />
            <div className={cx("Details__cardContainer", {blur: loading})}>
              <Field
                component={Textarea}
                name="address"
                label="Adres"
                info="Powiat, najbliższa miejscowość oraz nazwa nadleśnictwa lub nazwa ulicy i numer domu"
                disabled={disabled || !isAdmin && !roles.address.filledBy.includes(user.role.name)}
              />
              <Field
                component={Input}
                name="gpsData"
                label="Współrzędne gps"
                disabled={disabled || !isAdmin && !roles.gpsData.filledBy.includes(user.role.name)}
              />
              <Group>
                <div className="Details__row">
                  <Field
                    component={Datepicker}
                    name="from"
                    label="Data rozpoczęcia"
                    className="Details__formDatepicker"
                    disabled={disabled || !isAdmin && !roles.from.filledBy.includes(user.role.name)}
                  />
                  <Field
                    component={Datepicker}
                    name="to"
                    label="Data zakończenia"
                    className="Details__formDatepicker"
                    disabled={disabled || !isAdmin && !roles.to.filledBy.includes(user.role.name)}
                  />
                </div>
              </Group>
              <Field
                component={FileInput}
                name="registration"
                label="Zgłoszenie do kuratorium oświaty"
                info="Wpisz numer zgłoszenia z bazy MEN w pole oraz załącz plik z treścią zgłoszenia"
                disabled={disabled || !isAdmin && !roles.registration.filledBy.includes(user.role.name)}
              />
              <Field
                component={FileInput}
                name="leaseContract"
                label="Umowa / rezerwacja miejsca noclegów"
                disabled={disabled || !isAdmin && !roles.leaseContract.filledBy.includes(user.role.name)}
              />
            </div>
          </div>
          <div className="Details__card">
            <Loader loading={loading} />
            <div className={cx("Details__cardContainer", {blur: loading})}>
              <h4>Punkty medyczne</h4>
              <p>
                <span className="fa fa-info-circle" />
                Należy podać adres i odległość od miejsca w kilometrach
              </p>
              <Field
                component={Textarea}
                name="healthCenter"
                label="Najbliszy ośrodek zdrowia"
                disabled={disabled || !isAdmin && !roles.healthCenter.filledBy.includes(user.role.name)}
              />
              <Field
                component={Textarea}
                name="hospital"
                label="Najbliszy szpital"
                disabled={disabled || !isAdmin && !roles.hospital.filledBy.includes(user.role.name)}
              />
            </div>
          </div>
          {!disabled &&
            <div className="Details__btn">
              <Link to={`/projects/${projectId}/places`}>Anuluj</Link>
              <SaveButton
                disabled={pristine || submitting}
                saving={submitting}
              >Zapisz</SaveButton>
            </div>
          }
        </div>
      </Form>
    )
  }
))
