import React, { Component } from 'react';
import PT from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import _ from 'underscore';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { fetchCooks, deleteCook, toggleAcceptCook, commentCook } from 'modules/cooks/actions';
import { closeModal, showToast } from 'theme/actions';
import Header from 'theme/Header';
import Navbar from 'theme/Navbar';
import Table, { AcceptCommentCell, Row, ActionsCell, openModal, accept, comment } from 'theme/Table';
import Loader from 'theme/Loader';
import Modal from 'theme/Modal';
import DownloadButton from 'theme/DownloadButton';
import Footer from 'theme/Footer';
import roles from 'modules/cooks/roles';

import 'theme/common-views.sass';

const certsProps = [
  {key: 'sanepidBook', label: 'Książeczka sanepidowska'},
  {key: 'carrierTest', label: 'Badania na nosicielstwo'},
  {key: 'voluntaryContract', label: 'Umowa wolotariacka'},
  {key: 'contractStatement', label: 'Oświadczenie do umowy zlecenie'}
];

const HEADINGS = [
  {key: 'lastName', label: 'Nazwisko'},
  {key: 'firstName', label: 'Imię'},
  {key: 'from', label: 'Od'},
  {key: 'to', label: 'Do'},
  ...certsProps,
  {key: 'meta', label: ''}
]


class Cooks extends Component {

    componentDidMount() {
      this.props.fetchCooks(this.props.match.params.projectId);
      this.roles = Object.keys(roles)
      .map( key => roles[key].filledBy)
      .reduce( (a, b) => a.concat(b));

      const rolesCount = this.roles
      .reduce( (sum, role) => ({...sum, [role]: (sum[role] || 0) + 1}), {});

      this.deleteRole = Object.keys(rolesCount)
      .reduce( (max, role) => rolesCount[max] >= rolesCount[role] ? max : role);
    }

    render() {
      const {user, isFetching, selectedCook, showToast, loadingActions, closeModal, cooks, toggleAcceptCook, commentCook, activeProject} = this.props;
      const projectId = this.props.match.params.projectId;
      const deleteCook = (...args) => () => {
        this.props.deleteCook(...args)
        .then(closeModal)
        .catch(() => {
          const message = (
            <div>
              Nie udało się usunąć kucharza. Spróbuj ponownie później
            </div>
          )
          showToast({message, error: true});
        })
      };

      const editable = activeProject.state == 'Open';

      const canEdit = user.isAdmin || this.roles && this.roles.includes(user.role.name);
      const canDelete = editable && (user.isAdmin || this.deleteRole == user.role.name);

      return (
        <div className="List">
          <Header />
          <Navbar />
          <div className="List__container">

            <div className="List__heading">
              <h3>Kucharze</h3>
              {canEdit && editable &&
                <Link
                  to={`/projects/${projectId}/staff/cooks/new`}
                  className="List__add"
                >Dodaj</Link>
              }
            </div>


            <div className="List__card">
              <Loader loading={isFetching} />
              <div className={cx("List__cardContainer", {blur: isFetching})}>
                {cooks.length == 0
                  ? <h4>Do tego projektu nie dodano jeszcze żadnych kucharzy.</h4>
                  :
                  <Table
                    headings={HEADINGS}
                    accept={editable && accept(toggleAcceptCook, projectId, HEADINGS, _.pluck(certsProps, 'key'))}
                    comment={comment(commentCook, projectId)}
                    permissions={roles}
                  >
                    {cooks && cooks.map( cook => {
                      const loading = loadingActions[cook.id] && ['AcceptCook', 'DeleteCook'].includes(loadingActions[cook.id].name);
                      return (
                        <Row
                          key={cook.id}
                          loading={loading}
                        >
                          <AcceptCommentCell data={cook}>
                            {cook.lastName}
                          </AcceptCommentCell>
                          <AcceptCommentCell data={cook}>
                            {cook.firstName}
                          </AcceptCommentCell>
                          <AcceptCommentCell data={cook}>
                            {cook.from && moment(cook.from).format('DD.MM.YYYY')}
                          </AcceptCommentCell>
                          <AcceptCommentCell data={cook}>
                            {cook.to && moment(cook.to).format('DD.MM.YYYY')}
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={cook}
                            commentsPosition="left"
                          >
                            {cook.sanepidBook && cook.sanepidBook.fileId &&
                              <DownloadButton onlyIcon file={cook.sanepidBook} />
                            }
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={cook}
                            commentsPosition="left"
                          >
                            {cook.carrierTest && cook.carrierTest.fileId &&
                              <DownloadButton onlyIcon file={cook.carrierTest} />
                            }
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={cook}
                            commentsPosition="left"
                          >
                            {cook.voluntaryContract && cook.voluntaryContract.fileId &&
                              <DownloadButton onlyIcon file={cook.voluntaryContract} />
                            }
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={cook}
                            commentsPosition="left"
                          >
                            {cook.contractStatement && cook.contractStatement.fileId &&
                              <DownloadButton onlyIcon file={cook.contractStatement} />
                            }
                          </AcceptCommentCell>
                          <ActionsCell
                            data={cook}
                            del={canDelete && openModal('deleteCook')}
                            editPath={`/projects/${projectId}/staff/cooks/${cook.id}`}
                            acceptable={editable && user.isAdmin}
                          />
                        </Row>
                      )
                    })}
                  </Table>
                }
              </div>
            </div>
          </div>
          <Modal
            className="List__modal"
            header={<h4>Uwaga!</h4>}
          >
            <p>
              Czy na pewno chcesz usunąć kucharza <b>{selectedCook.firstName} {selectedCook.lastName}</b>?
            </p>
            <div className="List__modalActions">
              <button
                className="List__modalDiscard"
                onClick={closeModal}
              >Anuluj</button>
              <button
                className="List__modalOk"
                onClick={deleteCook(projectId, selectedCook.id)}
              >Usuń</button>
            </div>
          </Modal>
          <Footer />
        </div>
      )
    }
}

Cooks.propTypes = {
  cooks: PT.arrayOf(PT.shape({
    id: PT.string
  })),
  fetchCooks: PT.func,
  isFetching: PT.bool,
  selectedCook: PT.shape({
    id: PT.string,
    name: PT.string
  }),
  deleteCook: PT.func.isRequired,
  closeModal: PT.func.isRequired,
  showToast: PT.func.isRequired
}

const mapStateToProps = ({data: {cooks, activeProject}, isFetching, modal, auth: {user}}) => ({
  cooks: cooks || [],
  isFetching: isFetching.global,
  selectedCook: modal.payload || {},
  loadingActions: isFetching.objects,
  user,
  activeProject: activeProject || {}
})

export default withRouter(connect(
  mapStateToProps,
  { fetchCooks, deleteCook, showToast, closeModal, toggleAcceptCook, commentCook }
)(Cooks))
