import { REQUEST_FAILURE, REQUEST_SUCCESS, CREATE_REQUEST} from 'types';


export function requestError(error, id) {
  return { error: error && error.data && error.data.message, type: REQUEST_FAILURE, id };
}

export function requestSuccess(response, name, id) {
  return {
    data: response && (response.data || response),
    name,
    type: REQUEST_SUCCESS,
    id
  };
}

export function createRequest({...args}) {
  return {...args, type: CREATE_REQUEST };
}

export function handleError(dispatch, id) {
  return (error) => {
    dispatch(requestError(error.response || error, id));
    return Promise.reject(error.response || error);
  }
}
