import {
  CLOSE_MESSAGE,
  CLOSE_MODAL,
  TOGGLE_MOBILE_NAV,
  TOGGLE_AVATAR_MODAL,
  OPEN_MODAL,
  SHOW_TOAST,
  CLOSE_TOAST,
  SHOW_COMMENTS,
  CLOSE_COMMENTS,
  EDIT_CELL,
  START_SELECT,
  END_SELECT,
  SELECT,
  CLEAR_SELECTION,
} from 'types';
import store from 'store';

export function closeMessage() {
    return {type: CLOSE_MESSAGE};
}

export function closeModal() {
  return {type: CLOSE_MODAL};
}

export function openModal(name, payload) {
  return {type: OPEN_MODAL, name, payload};
}

export function closeToast() {
  return {type: CLOSE_TOAST};
}

export function showToast({error, message, options}) {
  (!options || (options && !options.disableTimeout)) && setTimeout(() => store.dispatch(closeToast()), 2000);
  return {type: SHOW_TOAST, error, message};
}

export function toggleUserModal() {
  return {type: TOGGLE_AVATAR_MODAL}
}

export function toggleMobileNav(visible) {
  return {type: TOGGLE_MOBILE_NAV, visible}
}

export function showComments(obj, prop) {
  return {type: SHOW_COMMENTS, obj, prop}
}

export function closeComments() {
  return {type: CLOSE_COMMENTS}
}

export function editCell(form) {
  return {type: EDIT_CELL, form};
}

export function startSelecting(id, prop, columns, rows) {
  return {type: START_SELECT, id, prop, columns, rows}
}

export function endSelecting() {
  return {type: END_SELECT}
}

export function select(id, prop, columns, rows) {
  return {type: SELECT, id, prop, columns, rows}
}

export function clearSelection() {
  return {type: CLEAR_SELECTION}
}
