import api, { pickAllowedProps } from 'api';
import { pick, omit, intersection } from 'underscore';
import { push } from 'react-router-redux';
import { requestSuccess, createRequest, handleError } from 'actions/requests';
import { saveFiles, matchFiles } from 'modules/files/actions';
import roles from './roles';

const FILE_FIELDS = [
  'sanepidBook',
  'carrierTest',
  'voluntaryContract',
  'DDStatement',
  'ZMStatement'
];


export function fetchQuartermasters(projectId, opts = {loader: true}) {
  return (dispatch) => {
    opts && opts.loader && dispatch(createRequest({global: true, name: 'quartermasters'}));
    return api({
      method: 'get',
      url: `/projects/${projectId}/quartermasters`
    })
    .then((response) => {
			dispatch(requestSuccess(response, 'quartermasters', opts.id));
			return response.data;
		})
    .catch(handleError(dispatch));
  }
}

export function fetchQuartermaster(projectId, id) {
  return (dispatch) => {
    dispatch(createRequest());
    return api({
      method: 'get',
      url: `/projects/${projectId}/quartermasters/${id}`
    })
    .then((response) => dispatch(matchFiles(response, FILE_FIELDS, 'quartermaster')))
    .catch(handleError(dispatch));
  }
}

export function addQuartermaster({projectId}, quartermaster) {
  const allowedProps = pickAllowedProps(roles);
  return (dispatch) => {
    dispatch(createRequest());
    return saveFiles(quartermaster, intersection(FILE_FIELDS, allowedProps))
    .then( filesObj => {
      const req = pick(omit(quartermaster, FILE_FIELDS), allowedProps);
      return api({
        method: 'post',
        url: `/projects/${projectId}/quartermasters`,
        data: {
          ...req,
          ...filesObj
        }
      })
      .then((response) => {
        dispatch(matchFiles(response, FILE_FIELDS, 'quartermaster'));
        dispatch(push(`/projects/${projectId}/staff/quartermasters`));
      })
    })
    .catch(handleError(dispatch));
  }
}

export function editQuartermaster({projectId, id, disableRedirect}, quartermaster) {
  const allowedProps = pickAllowedProps(roles);
  return (dispatch) => {
    dispatch(createRequest({name: 'editQuartermaster'}));
    return saveFiles(quartermaster, intersection(FILE_FIELDS, allowedProps))
    .then( filesObj => {
      const req = pick(omit(quartermaster, FILE_FIELDS), allowedProps);
      return api({
        method: 'patch',
        url: `/projects/${projectId}/quartermasters/${id}`,
        data: {
          ...req,
          ...filesObj
        }
      })
      .then((response) => {
        dispatch(matchFiles(response, FILE_FIELDS, 'quartermaster'));
        !disableRedirect && dispatch(push(`/projects/${projectId}/staff/quartermasters`));
      })
    })
    .catch(handleError(dispatch));
  }
}
export function deleteQuartermaster(projectId, id) {
  return (dispatch) => {
    dispatch(createRequest({name: 'DeleteQuartermaster', id}));
    return api({
      method: 'delete',
      url: `/projects/${projectId}/quartermasters/${id}`
    })
    .then(() => dispatch(fetchQuartermasters(projectId, {loader: false, id})))
    .catch(handleError(dispatch, id));
  }
}

export function toggleAcceptQuartermaster(projectId, quartermaster, state, ...props) {
  return (dispatch) => {
    dispatch(createRequest({name: 'AcceptQuartermaster', id: quartermaster.id}));
    const prom = props.map( prop => api({
      method: 'put',
      url: `/projects/${projectId}/quartermasters/${quartermaster.id}/${prop}/state`,
      data: {state}
    }))
    return Promise.all(prom)
    .then( () => dispatch(fetchQuartermasters(projectId, {loader: false, id: quartermaster.id})))
    .catch(handleError(dispatch, quartermaster.id));
  }
}

export function commentQuartermaster(projectId, quartermaster, prop, data) {
  return (dispatch) => {
    dispatch(createRequest({name: 'CommentQuartermaster', id: quartermaster.id}));
    return api({
      method: 'post',
      url: `/projects/${projectId}/quartermasters/${quartermaster.id}/${prop}/comments`,
      data
    })
    .then( () => dispatch(fetchQuartermasters(projectId, {loader: false, id: quartermaster.id})))
    .catch(handleError(dispatch, quartermaster.id));
  }
}
