import React            from 'react';
import {Route,
        Switch,
        Redirect }      from 'react-router-dom';
import Login            from 'modules/auth/Login';
import Reset            from 'modules/auth/Reset';
import Permissions      from 'modules/auth/Permissions';
import Projects         from 'modules/projects/Projects';
import Project          from 'modules/projects/Project';
import Users            from 'modules/users/Users';
import Places           from 'modules/places/Places';
import Place            from 'modules/places/Place';
import Units            from 'modules/units/Units';
import Unit             from 'modules/units/Unit';
import Commands         from 'modules/commands/Commands';
import Command          from 'modules/commands/Command';
import Educators        from 'modules/educators/Educators';
import Educator         from 'modules/educators/Educator';
import Quartermasters   from 'modules/quartermasters/Quartermasters';
import Quartermaster    from 'modules/quartermasters/Quartermaster';
import HealthCarers     from 'modules/healthCarers/HealthCarers';
import HealthCarer      from 'modules/healthCarers/HealthCarer';
import LifeGuards       from 'modules/lifeGuards/LifeGuards';
import LifeGuard        from 'modules/lifeGuards/LifeGuard';
import Cooks            from 'modules/cooks/Cooks';
import Cook             from 'modules/cooks/Cook';
import Drivers          from 'modules/drivers/Drivers';
import Driver           from 'modules/drivers/Driver';
import StaffSummary     from 'modules/staffSummary/StaffSummary';
import Participants     from 'modules/participants/Participants';
import Approval         from 'modules/approval/Approval';
import Accounting       from 'modules/accounting/Accounting';
import Summary          from 'modules/summary/Summary';
import NotFound         from 'theme/NotFound';

/*
 * @param {Redux Store}
 * We require store as an argument here because we wish to get
 * state from the store after it has been authenticated.
 */
export default (store) => {

  const LoginRoute = ({component, ...rest}) => {
    const { auth } = store.getState();
    return (
      <Route {...rest}>
        {auth.token == null || auth.token == undefined
          ? component
          : <Redirect to="/projects" />
        }
      </Route>
    )
  }

  const AuthenticatedRoute = ({children, ...rest}) => {
    const { auth } = store.getState();
    if (auth.token) {
      return (
        <Route {...rest}>
          {children}
        </Route>
      )
    }
    return (
      <Route {...rest}>
        <Redirect to="/signin" />
      </Route>
    )
  }

  const StaffRedirect = ({match}) => <Redirect to={`/projects/${match.params.projectId}/staff/commands`} />
  const AccountingRedirect = ({match}) => <Redirect to={`/projects/${match.params.projectId}/accounting/places`} />

  return (
    <div>
      <Switch>
        <Route exact path="/">
          <Redirect to="/signin" />
        </Route>
        <LoginRoute exact path="/signin" component={Login} />
        <LoginRoute exact path="/reset" component={Reset} />
        <LoginRoute exact path="/reset/:mailToken" component={Reset} />
        <AuthenticatedRoute>
          <Switch>
            <Route exact path="/users" component={Users} />
            <Route exact path="/projects" component={Projects} />
            <Route exact path="/projects/new" component={Project} />
            <Route exact path="/projects/:projectId" component={Project} />
            <Route path="/projects/:projectId">
              <Permissions>
                <Switch>
                  <Route exact path="/projects/:projectId/places" component={Places} />
                  <Route exact path="/projects/:projectId/places/new" component={Place} />
                  <Route exact path="/projects/:projectId/places/:placeId" component={Place} />
                  <Route exact path="/projects/:projectId/units" component={Units} />
                  <Route exact path="/projects/:projectId/units/new" component={Unit} />
                  <Route exact path="/projects/:projectId/units/:unitId" component={Unit} />
                  <Route exact path="/projects/:projectId/staff" render={StaffRedirect} />
                  <Route exact path="/projects/:projectId/staff/commands" component={Commands} />
                  <Route exact path="/projects/:projectId/staff/commands/new" component={Command} />
                  <Route exact path="/projects/:projectId/staff/commands/:commandId" component={Command} />
                  <Route exact path="/projects/:projectId/staff/educators" component={Educators} />
                  <Route exact path="/projects/:projectId/staff/educators/new" component={Educator} />
                  <Route exact path="/projects/:projectId/staff/educators/:educatorId" component={Educator} />
                  <Route exact path="/projects/:projectId/staff/quartermasters" component={Quartermasters} />
                  <Route exact path="/projects/:projectId/staff/quartermasters/new" component={Quartermaster} />
                  <Route exact path="/projects/:projectId/staff/quartermasters/:quartermasterId" component={Quartermaster} />
                  <Route exact path="/projects/:projectId/staff/healthCarers" component={HealthCarers} />
                  <Route exact path="/projects/:projectId/staff/healthCarers/new" component={HealthCarer} />
                  <Route exact path="/projects/:projectId/staff/healthCarers/:healthCarerId" component={HealthCarer} />
                  <Route exact path="/projects/:projectId/staff/lifeGuards" component={LifeGuards} />
                  <Route exact path="/projects/:projectId/staff/lifeGuards/new" component={LifeGuard} />
                  <Route exact path="/projects/:projectId/staff/lifeGuards/:lifeGuardId" component={LifeGuard} />
                  <Route exact path="/projects/:projectId/staff/cooks" component={Cooks} />
                  <Route exact path="/projects/:projectId/staff/cooks/new" component={Cook} />
                  <Route exact path="/projects/:projectId/staff/cooks/:cookId" component={Cook} />
                  <Route exact path="/projects/:projectId/staff/drivers" component={Drivers} />
                  <Route exact path="/projects/:projectId/staff/drivers/new" component={Driver} />
                  <Route exact path="/projects/:projectId/staff/drivers/:driverId" component={Driver} />
                  <Route exact path="/projects/:projectId/staff/summary" component={StaffSummary} />
                  <Route exact path="/projects/:projectId/participants" component={Participants} />
                  <Route exact path="/projects/:projectId/approval" component={Approval} />
                  <Route exact path="/projects/:projectId/accounting" render={AccountingRedirect} />
                  <Route exact path="/projects/:projectId/accounting/:card" component={Accounting} />
                  <Route exact path="/projects/:projectId/summary" component={Summary} />
                  <Route component={NotFound} />
                </Switch>
              </Permissions>
            </Route>
            <Route component={NotFound} />
          </Switch>
        </AuthenticatedRoute>
      </Switch>
    </div>
  );
};
