import api, { pickAllowedProps } from 'api';
import { push } from 'react-router-redux';
import { pick, omit, intersection } from 'underscore';
import { requestSuccess, createRequest, handleError } from 'actions/requests';
import { saveFiles, matchFiles } from 'modules/files/actions';
import roles from './roles';

const FILE_FIELDS = [
  'educatorCertificate',
  'instructorCertificate',
  'KRKCertificate',
  'medicalCertificate',
  'voluntaryContract',
  'DDStatement'
];


export function fetchEducators(projectId, opts = {loader: true}) {
  return (dispatch) => {
    opts && opts.loader && dispatch(createRequest({global: true, name: 'educators'}));
    return api({
      method: 'get',
      url: `/projects/${projectId}/educators`
    })
    .then((response) => {
			dispatch(requestSuccess(response, 'educators', opts.id));
			return response.data;
		})
    .catch(handleError(dispatch));
  }
}

export function fetchEducator(projectId, id) {
  return (dispatch) => {
    dispatch(createRequest());
    return api({
      method: 'get',
      url: `/projects/${projectId}/educators/${id}`
    })
    .then((response) => dispatch(matchFiles(response, FILE_FIELDS, 'educator')))
    .catch(handleError(dispatch));
  }
}

export function addEducator({projectId}, educator) {
  const allowedProps = pickAllowedProps(roles);
  return (dispatch) => {
    dispatch(createRequest());
    return saveFiles(educator, intersection(FILE_FIELDS, allowedProps))
    .then( filesObj => {
      const req = pick(omit(educator, FILE_FIELDS), allowedProps);
      return api({
        method: 'post',
        url: `/projects/${projectId}/educators`,
        data: {
          ...req,
          ...filesObj
        }
      })
      .then((response) => {
        dispatch(matchFiles(response, FILE_FIELDS, 'educator'));
        dispatch(push(`/projects/${projectId}/staff/educators`));
      })
    })
    .catch(handleError(dispatch));
  }
}

export function editEducator({projectId, id, disableRedirect}, educator) {
  const allowedProps = pickAllowedProps(roles);
  return (dispatch) => {
    dispatch(createRequest({name: 'editEducator'}));
    return saveFiles(educator, intersection(FILE_FIELDS, allowedProps))
    .then( filesObj => {
      const req = pick(omit(educator, FILE_FIELDS), allowedProps);
      return api({
        method: 'patch',
        url: `/projects/${projectId}/educators/${id}`,
        data: {
          ...req,
          ...filesObj
        }
      })
      .then((response) => {
        dispatch(matchFiles(response, FILE_FIELDS, 'educator'));
        !disableRedirect && dispatch(push(`/projects/${projectId}/staff/educators`));
      })
    })
    .catch(handleError(dispatch));
  }
}

export function deleteEducator(projectId, id) {
  return (dispatch) => {
    dispatch(createRequest({name: 'DeleteEducator', id}));
    return api({
      method: 'delete',
      url: `/projects/${projectId}/educators/${id}`
    })
    .then(() => dispatch(fetchEducators(projectId, {loader: false, id})))
    .catch(handleError(dispatch, id));
  }
}

export function toggleAcceptEducator(projectId, educator, state, ...props) {
  return (dispatch) => {
    dispatch(createRequest({name: 'AcceptEducator', id: educator.id}));
    const prom = props.map( prop => api({
      method: 'put',
      url: `/projects/${projectId}/educators/${educator.id}/${prop}/state`,
      data: {state}
    }))
    return Promise.all(prom)
    .then( () => dispatch(fetchEducators(projectId, {loader: false, id: educator.id})))
    .catch(handleError(dispatch, educator.id));
  }
}

export function commentEducator(projectId, educator, prop, data) {
  return (dispatch) => {
    dispatch(createRequest({name: 'CommentEducator', id: educator.id}));
    return api({
      method: 'post',
      url: `/projects/${projectId}/educators/${educator.id}/${prop}/comments`,
      data
    })
    .then( () => dispatch(fetchEducators(projectId, {loader: false, id: educator.id})))
    .catch(handleError(dispatch, educator.id));
  }
}
