import React, { Component }                 from 'react';
import PT                                   from 'prop-types';
import cx                                   from 'classnames';
import _                                    from 'underscore';
import deep                                 from 'deep-diff';
import { connect }                          from 'react-redux'
import { withRouter }                       from 'react-router'
import { fetchUnit, editUnit, addUnit }     from 'modules/units/actions';
import { showToast }                        from 'theme/actions';
import Header                               from 'theme/Header';
import Navbar                               from 'theme/Navbar';
import Footer                               from 'theme/Footer';

import 'theme/common-views.sass';

import UnitForm from './UnitForm'


class UnitDetails extends Component {

    componentDidMount() {
      const {projectId, unitId} = this.props.match.params;
      if(projectId && unitId)
        this.props.fetchUnit(projectId, unitId)
    }

    render() {
      const {isFetching, editUnit, addUnit, showToast, match, unit, user, activeProject} = this.props;
      const {projectId, unitId} = match.params;
      const edit = unitId != null;

      const submit = (values) => {
        const diff = edit && _.pluck(deep.diff(unit, values), 'path');
        const vals = diff ? _.pick(values, ..._.map(diff, _.first)) : values;
        const func = edit ? editUnit : addUnit;
        return func({projectId, unitId}, vals)
        .then(() => {
          const message = <div>Zapisano jednostkę</div>
          showToast({message});
        })
        .catch(() => {
          const message = (
            <div>
              Nie udało się zapisać miejsca. Spróbuj ponownie później
            </div>
          )
          showToast({message, error: true});
        })
      }

      return (
        <div className="Details">
          <Header />
          <Navbar />
          <div className={cx("Details__container", {blur: isFetching})}>

            <div className="Details__heading">
              <h3>Jednostka</h3>
              <p>
                {edit
                  ? 'Edycja jednostki'
                  : 'Nowa jednostka'
                }
              </p>
            </div>

            <UnitForm
              initialValues={edit ? unit : null}
              onSubmit={submit}
              loading={isFetching}
              user={user}
              disabled={activeProject.state != 'Open'}
            />
          </div>
          <Footer />
        </div>
      )
    }
}

UnitDetails.propTypes = {
  unit: PT.shape({
    id: PT.string
  }),
  fetchUnit: PT.func,
  editUnit: PT.func,
  addUnit: PT.func,
  isFetching: PT.bool,
  showToast: PT.func.isRequired
}

const mapStateToProps = ({data: {unit, files, activeProject}, isFetching, auth}) => ({
  unit,
  files,
  isFetching: isFetching.global,
  user: auth.user,
  activeProject: activeProject || {}
})

export default withRouter(connect(
  mapStateToProps,
  { fetchUnit, editUnit, addUnit, showToast }
)(UnitDetails))
