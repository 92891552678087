import React, { Component }                 from 'react';
import PT                                   from 'prop-types';
import cx                                   from 'classnames';
import _                                    from 'underscore';
import deep                                 from 'deep-diff';
import { connect }                          from 'react-redux';
import { withRouter }                       from 'react-router';
import { fetchPlace, editPlace, addPlace }  from 'modules/places/actions';
import { fetchProject }                     from 'modules/projects/actions';
import { download }                         from 'modules/files/actions';
import { showToast }                        from 'theme/actions';
import Header                               from 'theme/Header';
import Navbar                               from 'theme/Navbar';
import Footer                               from 'theme/Footer';

import 'theme/common-views.sass';

import PlaceForm                            from './PlaceForm'


class PlaceDetails extends Component {

    componentDidMount() {
      const {projectId, placeId} = this.props.match.params;
      placeId && this.props.fetchPlace(projectId, placeId);
    }

    render() {
      const {isFetching, editPlace, addPlace, showToast, match, place, user, activeProject} = this.props;
      const {projectId, placeId} = match.params;
      const edit = placeId != null;
      const initialForm = activeProject && activeProject.description ? {from: activeProject.description.from, to: activeProject.description.to} : null;

      const submit = (values) => {
        const diff = edit && _.pluck(deep.diff(place, values), 'path');
        const vals = diff ? _.pick(values, ..._.map(diff, _.first)) : values;
        const func = edit ? editPlace : addPlace;
        return func({projectId, placeId}, vals)
        .then(() => {
          const message = <div>Zapisano miejsce</div>
          showToast({message});
        })
        .catch(() => {
          const message = (
            <div>
              Nie udało się zapisać miejsca. Spróbuj ponownie później
            </div>
          )
          showToast({message, error: true});
        })
      }

      return (
        <div className="Details">
          <Header />
          <Navbar />
          <div className={cx("Details__container", {blur: isFetching})}>

            <div className="Details__heading">
              <h3>Miejsce</h3>
              <p>
                {edit
                  ? 'Edycja miejsca'
                  : 'Nowe miejsce'
                }
              </p>
            </div>

            <PlaceForm
              initialValues={edit ? place : initialForm}
              onSubmit={submit}
              loading={isFetching}
              user={user}
              disabled={activeProject.state != 'Open'}
            />
          </div>
          <Footer />
        </div>
      )
    }
}

PlaceDetails.propTypes = {
  place: PT.shape({
    id: PT.string
  }),
  fetchPlace: PT.func,
  editPlace: PT.func,
  addPlace: PT.func,
  showToast: PT.func.isRequired
}

const mapStateToProps = ({data: {place, files, activeProject}, isFetching, auth}) => ({
  place,
  files,
  isFetching: isFetching.global,
  user: auth.user,
  activeProject: activeProject || {}
})

export default withRouter(connect(
  mapStateToProps,
  { fetchPlace, editPlace, addPlace, showToast, download, fetchProject }
)(PlaceDetails))
