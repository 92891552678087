import React from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { reduxForm, Field} from 'redux-form';
import Form, { Input, FileInput, SaveButton } from 'theme/Form';
import Loader from 'theme/Loader';
import roles from 'modules/units/roles';

import 'react-datepicker/dist/react-datepicker.css';


export default withRouter(reduxForm({
  form: 'UnitForm',
  enableReinitialize: true
})(
  ({handleSubmit, pristine, submitting, match, loading, user, disabled}) => {
    const {projectId} = match.params;
    const isAdmin = user.isAdmin;

    return (
      <Form
        className="Details__form"
        onSubmit={handleSubmit}
      >
        <div className="Details__row">
          <div className="Details__card">
            <Loader loading={loading} />
            <div className={cx("Details__cardContainer", {blur: loading})}>
              <Field
                component={Input}
                name="name"
                label="Nazwa"
                disabled={disabled || !isAdmin && !roles.name.filledBy.includes(user.role.name)}
              />
              <Field
                component={FileInput}
                name="schedule"
                label="Plan pracy"
                info="Załącz plik z planem pracy"
                disabled={disabled || !isAdmin && !roles.schedule.filledBy.includes(user.role.name)}
              />
            </div>
          </div>
          <div className="Details__card">
            <Loader loading={loading} />
            <div className={cx("Details__cardContainer", {blur: loading})}>
              <h5>Wypełnia Obwód/Okręg</h5>
              <Field
                component={Input}
                name="dues"
                label="Składki włącznie do I (HAZ) lub III (HAL) kwartału br."
                type="checkbox"
                disabled={disabled || !isAdmin && !roles.dues.filledBy.includes(user.role.name)}
              />
              <Field
                component={Input}
                name="financialRecord"
                label="Złożona dokumentacja finansowa za ubiegły rok kalendarzowy"
                type="checkbox"
                disabled={disabled || !isAdmin && !roles.financialRecord.filledBy.includes(user.role.name)}
              />
            </div>
          </div>
        </div>
        {!disabled &&
          <div className="Details__btn">
            <Link to={`/projects/${projectId}/units`}>Anuluj</Link>
            <SaveButton
              disabled={pristine || submitting}
              saving={submitting}
            >Zapisz</SaveButton>
          </div>
        }
      </Form>
    )
  }
))
