import React, { Component } from 'react';
import PT from 'prop-types';
import cx from 'classnames';
import moment from 'moment';
import ReactDatePicker from 'react-datepicker';
import Select, { Creatable } from 'react-select';
import Dropzone from 'react-dropzone';
import DownloadButton from 'theme/DownloadButton';


import 'react-datepicker/dist/react-datepicker.css';
import 'react-select/dist/react-select.css';
import './Form.sass';


const Form = ({ onSubmit, children, className }) => (
  <form
    onSubmit={onSubmit}
    className={cx("Form", className)}
  >
    {children}
  </form>
)

Form.propTypes = {
  onSubmit: PT.func.isRequired,
  className: PT.string
}

export default Form;

export const Group = ({className, children}) => (
  <div className={cx("Form__field", className)}>
    {children}
  </div>
)

export const Input = ({input, meta, label, placeholder, type, className, disabled, onChange, info}) => {
  const change = (input, type) => (e) => {
    if (disabled) return;
    input.onChange(type == 'checkbox' ? !input.value : e);
    onChange && onChange(e);
  }
  return (
    <Group className={cx(className, {Form__checkbox: type == 'checkbox'})}>
      {label && type != 'checkbox' &&
        <label htmlFor={input.name}>
          {label}
          <span>{info}</span>
        </label>
      }
      <input
        {...input}
        className={cx("Form__input", {error: meta.touched && meta.error})}
        placeholder={placeholder}
        onChange={change(input, type)}
        type={type}
        disabled={disabled}
        id={input.name}
      />
      {label && type == 'checkbox' &&
        <label htmlFor={input.name}>
          {label}
        </label>
      }
      {meta.touched && meta.error &&
        <span className={cx("Form__error", {noLabel: !label})}>
          {meta.error}
        </span>
      }
    </Group>
  )
}

Input.propTypes = {
  label: PT.string,
  placeholder: PT.string,
  type: PT.string,
  className: PT.string
}

export const Textarea = ({input, meta, label, placeholder, type, className, disabled, info}) => (
  <Group className={cx(className)}>
    {label &&
      <label htmlFor={input.name}>
        {label}
        <span>{info}</span>
      </label>
    }
    <textarea
      {...input}
      className={cx("Form__input", {error: meta.touched && meta.error})}
      placeholder={placeholder}
      type={type}
      disabled={disabled}
      id={input.name}
    />
    {meta.touched && meta.error &&
      <span className={cx("Form__error", {noLabel: !label})}>
        {meta.error}
      </span>
    }
  </Group>
)

Textarea.propTypes = {
  label: PT.string,
  placeholder: PT.string,
  type: PT.string,
  className: PT.string
}

export const Radio = ({input, meta, className, disabled, options, label, info}) => {
  return (
    <Group className={cx(className, 'Form__radio')}>
      {label &&
        <label htmlFor={input.name}>
          {label}
          <span>{info}</span>
        </label>
      }
      {options.map( (option, i) => (
        <div className="Form__radioGroup" key={option.value}>
          <label htmlFor={`${input.name}${i}`}>
            <input
              {...input}
              checked={option.value == input.value}
              type="radio"
              value={option.value}
              className={cx("Form__input", {error: meta.touched && meta.error})}
              disabled={disabled}
              id={`${input.name}${i}`}
            />
            {option.label}
          </label>
        </div>
      ))}
    </Group>
  )
}

export const Datepicker = ({input, meta, label, className, disabled, info}) => {
  const onChange = (value) => moment(value).diff(moment()) >= 0 && input.onChange(value.format("YYYY-MM-DD"))

  return (
    <Group className={cx(className)}>
      {label &&
        <label htmlFor={input.name}>
          {label}
          <span>{info}</span>
        </label>
      }
      <div className="Form__datepicker">
        <ReactDatePicker
          className={cx("Form__datepickerInput Form__input", {error: meta.touched && meta.error})}
          selected={input.value != "" && moment(input.value, "YYYY-MM-DD") || moment()}
          onChange={onChange}
          dateFormat="DD/MM/YYYY"
          locale="pl"
          disabled={disabled}
          id={input.name}
        />
        <span className="fa fa-calendar" />
      </div>
      {meta.touched && meta.error &&
        <span className={cx("Form__error", {noLabel: !label})}>
          {meta.error}
        </span>
      }
    </Group>
  )
}

Datepicker.propTypes = {
  label: PT.string,
  className: PT.string
}

export class Search extends Component {
  constructor() {
    super()
    this.state = {
      newOptions: []
    }
  }

  render() {
    const {input: {value, name, onBlur, onChange, onFocus}, meta, placeholder, className, label, addOption, disabled, info} = this.props;
    const options = [...this.state.newOptions, ...this.props.options];

    const SearchComponent = addOption ? Creatable : Select;
    const change = (value) => {
      if(value.new) this.setState({newOptions: [value, ...this.state.newOptions]});
      return value ? onChange(value.value) : '';
    }

    const key = ({keyCode}) => {
      return keyCode == 13 ? true : false;
    }

    const create = ({label, labelKey, valueKey}) => {
      return {[valueKey]: label, [labelKey]: label, new: true};
    }

    const transformedValue = options.find( option => option.value == value);

    return (
      <Group className="Form__search">
        {label &&
          <label htmlFor={name}>
            {label}
            <span>{info}</span>
          </label>
        }
        <SearchComponent
          name={name}
          value={transformedValue}
          options={options}
          onBlur={() => onBlur(value)}
          onChange={change}
          placeholder={placeholder || 'Wybierz...'}
          onFocus={onFocus}
          clearable={false}
          className={cx(className, {error: meta.touched && meta.error})}
          backspaceRemoves
          promptTextCreator={label => `Nowy podobóz: ${label}`}
          shouldKeyDownEventCreateNewOption={key}
          newOptionCreator={create}
          disabled={disabled}
        />
        {meta.touched && meta.error &&
          <span className={cx("Form__error", {noLabel: !label})}>
            {meta.error}
          </span>
        }
      </Group>
    )
  }
}

Search.propTypes = {
  placeholder: PT.string,
  className: PT.string,
  options: PT.arrayOf(PT.shape({
    label: PT.shape
  })),
  label: PT.string
}

export const FileInput = ({input, label, className, info, disabled}) => {
  const onChange = (files) => {
    input.onChange({
      ...input.value,
      newFile: files[0]
    });
  }
  const onClick = (e) => {
    e.stopPropagation();
  }
  const textareaChange = (e) => {
    input.onChange({
      ...input.value,
      comment: e.target.value
    })
  }

  const removeFile = (e) => {
    onClick(e);
    input.onChange({comment: input.value.comment});
  }

  return (
    <Dropzone
      name={input.name}
      onDrop={onChange}
      className={cx("Form__field", "Form__dropzone", className)}
      multiple={false}
      disabled={disabled}
    >
      {label &&
        <label htmlFor={input.name}>
          {label}
          <span>{info}</span>
        </label>
      }
      <div className="Form__dropzoneBtns">
        <button type="button" disabled={disabled}>
          <span
            className={cx(
              {"fa fa-file-o": !disabled && !input.value.newFile && !input.value.name},
              {"fa fa-paperclip": input.value.newFile || input.value.name}
            )} />
          {input.value.newFile
            ? input.value.newFile.name
            : input.value.name || !disabled && 'Dodaj plik'
          }
        </button>
        <div>
          {input.value.id &&
            <DownloadButton file={input.value} />
          }
          {(input.value.newFile || input.value.name) &&
            <button
              type="button"
              onClick={removeFile}
              disabled={disabled}
            >
              <span className="fa fa-times" />
              Usuń plik
            </button>
          }
        </div>
      </div>

      <textarea
        className="Form__input"
        onClick={onClick}
        value={input.value && input.value.comment}
        placeholder="Wpisz komentarz..."
        onChange={textareaChange}
        disabled={disabled}
      />

    </Dropzone>
  )
}

FileInput.propTypes = {
  label: PT.string,
  className: PT.string
}

export const SaveButton = ({disabled, className, saving, children, type = 'submit', ...props}) => (
  <button
    {...props}
    type={type}
    disabled={disabled}
    className={cx("SaveButton", className, {saving})}
  >
    <span className="fa fa-spin fa-spinner" />
    {children}
  </button>
)

export const CommentableField = ({data, showComments, prop, children, commentsProps}) => {
  const {visible, prop: name} = commentsProps;
  const meta = data && data.meta && data.meta[prop];
  return (
    <div className={cx("Form__commentsContainer", {selected: visible && name == prop})}>
      {children}
      <button
        className={cx("Form__commentsBtn", {selected: meta && meta.comments && meta.comments.length > 0})}
        onClick={() => showComments(null, prop)}
        type="button"
      >
        <span className="fa fa-comment" />
        {meta && meta.comments && meta.comments.length > 0 &&
          <span className="Form__commentsBtnCount">
            {meta.comments.length}
          </span>
        }
      </button>
    </div>
  )
}
