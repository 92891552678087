import React                        from 'react';
import ListItem                     from './ListItem';

const mapData = (data) => {
  const unfilled = data && data.reduce((a, b) => a + b.unfilled.length, 0);
  const unapproved = data && data.reduce((a, b) => a + b.unapproved.length, 0);
  const files = data && data.reduce((a, b) => [].concat(a, b.files), []);
  return {unfilled, unapproved, files};
}

export default ({data, projectId, history, download, redirect}) => {
  const {unfilled, unapproved, files} = mapData(data);
  return (
    <div className="Summary__list">
      {!data || data.length == 0 &&
        <h4>Brak rekordów</h4>
      }
      {data && data.length > 0 &&
        <div className="Summary__object">
          <div className="Summary__objectList">
            <h4>
              Niewypełnione pola
              <span>{unfilled}</span>
            </h4>
          </div>
          <div className="Summary__objectList">
            <h4>
              Niezatwierdzone pola
              <span>{unapproved}</span>
            </h4>
          </div>
          <div className="Summary__objectList">
            <h4>
              Pliki
              <span>{files && files.length}</span>
            </h4>
          </div>
          <div className="Summary__expand" />
        </div>
      }
      {data && data.map((d, i) => {
        return (
          <ListItem
            key={i}
            item={d}
            projectId={projectId}
            history={history}
            download={download}
            redirect={redirect}
            />
        )
      })}
    </div>
  )
}