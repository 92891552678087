import React                from 'react';
import cx                   from 'classnames';
import Loader               from 'theme/Loader';
import Table, {
  AcceptCommentCell,
  Row
}                           from 'theme/Table';
import quartermasterRoles   from 'modules/quartermasters/roles';
import healthCarerRoles     from 'modules/healthCarers/roles';
import lifeGuardRoles       from 'modules/lifeGuards/roles';
import cookRoles            from 'modules/cooks/roles';
import driverRoles          from 'modules/drivers/roles';
import Radio                from './Radio';


const headings = [
  {key: 'name', label: 'Imię i nazwisko'},
  {key: 'accountedDocuments', label: 'Dokumenty potwierdzające uprawnienia specjalistyczne'},
  {key: 'accountedKRKCertificate', label: 'Zaświadczenie z KRK'},
  {key: 'accountedDDStatement', label: 'Oświadczenie DD'},
  {key: 'accountedMedicalBook', label: 'Książeczka lub badania lekarskie'},
  {key: 'accountedContract', label: 'Umowa'}
]

const options = [
  {label: 'Tak', value: 'yes'},
  {label: 'Nie', value: 'no'},
  {label: 'Nie dotyczy', value: 'n/a'}
]

const contractOptions = [
  {label: 'Wolontariacka', value: 'voluntary'},
  {label: 'Zlecenie', value: 'order'}
]


export default ({fetching, staff, comment, edit, disabled}) => {
  const roles = {
    ...quartermasterRoles,
    ...healthCarerRoles,
    ...lifeGuardRoles,
    ...cookRoles,
    ...driverRoles
  }
  return (
    <div className="List__card">
      <Loader loading={fetching} />
      <div className={cx("List__cardContainer", {blur: fetching})}>
        {staff.length == 0
          ? <h4>W tym projekcie nie ma kadry pomocniczej.</h4>
          :
          <Table
            headings={headings}
            permissions={roles}
          >
            {staff.map( s => {
              const onChange = edit(s.id, s.func, false);
              return (
                <Row
                  key={s.id}
                  comment={comment(s.func)}
                >
                  <AcceptCommentCell className="Accounting__name">
                    {s.firstName} {s.lastName}
                  </AcceptCommentCell>
                  <AcceptCommentCell data={s}>
                    <Radio
                      checked={s.accountedDocuments}
                      onChange={onChange}
                      name="accountedDocuments"
                      options={options}
                      disabled={disabled}
                    />
                  </AcceptCommentCell>
                  <AcceptCommentCell
                    data={s}
                    commentsPosition="left"
                  >
                    <Radio
                      checked={s.accountedKRKCertificate}
                      onChange={onChange}
                      name="accountedKRKCertificate"
                      options={options}
                      disabled={disabled}
                    />
                  </AcceptCommentCell>
                  <AcceptCommentCell
                    data={s}
                    commentsPosition="left"
                  >
                    <Radio
                      checked={s.accountedDDStatement}
                      onChange={onChange}
                      name="accountedDDStatement"
                      options={options}
                      disabled={disabled}
                    />
                  </AcceptCommentCell>
                  <AcceptCommentCell
                    data={s}
                    commentsPosition="left"
                  >
                    <Radio
                      checked={s.accountedMedicalBook}
                      onChange={onChange}
                      name="accountedMedicalBook"
                      options={options}
                      disabled={disabled}
                    />
                  </AcceptCommentCell>
                  <AcceptCommentCell
                    data={s}
                    commentsPosition="left"
                  >
                    <Radio
                      checked={s.accountedContract}
                      onChange={onChange}
                      name="accountedContract"
                      options={contractOptions}
                      disabled={disabled}
                    />
                  </AcceptCommentCell>
                </Row>
              )
            })}
          </Table>
        }
      </div>
    </div>
  )
}