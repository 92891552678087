import React                from 'react';
import cx                   from 'classnames';
import { reduxForm, Field } from 'redux-form';
import Loader               from 'theme/Loader';
import roles                from 'modules/accounting/roles';
import Comments             from 'theme/Comments';
import Form,
      {
        Input,
        SaveButton,
        CommentableField
      }                     from 'theme/Form';

const fields = [
	'financialBookUploaded',
	'participantsListUploaded',
	'paymentsListUploaded',
	'staffListUploaded'
]


const DropboxForm = reduxForm({
  enableReinitialize: true,
  form: 'AccountingDropboxForm'
})(({handleSubmit, fetching, commentsProps, showComments, user, pristine, submitting, accounting, disabled}) => {
  return (
    <Form onSubmit={handleSubmit}>
      <div className="Details__card">
        <Loader loading={fetching} />
        <div className={cx("Details__cardContainer", {blur: fetching})}>
          <CommentableField
            commentsProps={commentsProps}
            showComments={showComments}
            prop="financialBookUploaded"
            data={accounting}
          >
            <Field
              component={Input}
              name="financialBookUploaded"
              label="Książka finansowa w Excelu"
              type="checkbox"
              disabled={disabled || !user.isAdmin && !roles.financialBookUploaded.filledBy.includes(user.role.name)}
            />
          </CommentableField>
          <CommentableField
            commentsProps={commentsProps}
            showComments={showComments}
            prop="participantsListUploaded"
            data={accounting}
          >
            <Field
              component={Input}
              name="participantsListUploaded"
              label="Lista uczestników"
              type="checkbox"
              disabled={disabled || !user.isAdmin && !roles.participantsListUploaded.filledBy.includes(user.role.name)}
            />
          </CommentableField>
          <CommentableField
            commentsProps={commentsProps}
            showComments={showComments}
            prop="paymentsListUploaded"
            data={accounting}
          >
            <Field
              component={Input}
              name="paymentsListUploaded"
              label="Lista odpłatności"
              type="checkbox"
              disabled={disabled || !user.isAdmin && !roles.paymentsListUploaded.filledBy.includes(user.role.name)}
            />
          </CommentableField>
          <CommentableField
            commentsProps={commentsProps}
            showComments={showComments}
            prop="staffListUploaded"
            data={accounting}
          >
            <Field
              component={Input}
              name="staffListUploaded"
              label="Lista kadry"
              type="checkbox"
              disabled={disabled || !user.isAdmin && !roles.staffListUploaded.filledBy.includes(user.role.name)}
            />
          </CommentableField>
        </div>
      </div>
      {!disabled &&
        <div className="Accounting__btn">
          <SaveButton
            disabled={pristine || submitting}
            saving={submitting}
          >Zapisz</SaveButton>
        </div>
      }
    </Form>
  )
})

export default ({comment: commentAccounting, accounting, commentsProps, ...rest}) => {
  const {visible, prop} = commentsProps;
  const comment = (values) => commentAccounting(prop, values);
  return (
    <div>
      <DropboxForm
        {...rest}
        commentsProps={commentsProps}
        initialValues={accounting}
        accounting={accounting}
      />
      {visible && prop && fields.includes(prop) &&
        <Comments
          comment={comment}
          comments={accounting && accounting.meta && accounting.meta[prop] && accounting.meta[prop].comments}
          className="Accounting__commentBox"
          hideArrow
        />
      }
    </div>
  )
}
