import axios from 'axios';
import store from 'store';
import { logout } from 'modules/auth/actions';
import { loadAuth } from 'localStorage';
import { CONNECTED, NO_CONNECTION } from 'types';

const urlMapping = {
  "localhost": "https://zhr-test.iterato.rs/api",
  "vb-localhost": "https://zhr-test.iterato.rs/api",
  "default": location.origin + "/api/"
};

const api = axios.create({
  baseURL: urlMapping[location.hostname] || urlMapping["default"]
});

export const apiBase = urlMapping[location.hostname] || urlMapping["default"];

export const pickAllowedProps = (roles) => {
  const user = store.getState().auth.user;
  return Object.keys(roles).filter( key => user && user.isAdmin || roles[key].filledBy.includes(user.role.name));
}

api.interceptors.request.use(
  (config) => {
    const auth = loadAuth();
    if (auth.token)
      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: (loadAuth() && `Bearer ${loadAuth().token}`) || ''
        }
      }
    return config;
  }, (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => {
    store.dispatch({type: CONNECTED});
    return Promise.resolve(response);
  }
  , (error) => {
    if(!error.response)
      store.dispatch({type: NO_CONNECTION});
    else if(error.response.status == 401) {
      store.dispatch(logout({pathname: '/signin'}));
    }
    return Promise.reject(error);
  }
);

export default api;
