const types = {
  CREATE_REQUEST: 'CREATE_REQUEST',
  REQUEST_SUCCESS: 'REQUEST_SUCCESS',
  REQUEST_FAILURE: 'REQUEST_FAILURE',
  CONNECTED: 'CONNECTED',
  NO_CONNECTION: 'NO_CONNECTION',
  LOGIN_ATTEMPT: 'LOGIN_ATTEMPT',
  LOGGED_FAILED: 'LOGGED_FAILED',
  LOGGED_SUCCESSFULLY: 'LOGGED_SUCCESSFULLY',
  LOGGED_OUT: 'LOGGED_OUT',
  TOGGLE_AVATAR_MODAL: "TOGGLE_AVATAR_MODAL",
  TOGGLE_MOBILE_NAV: "TOGGLE_MOBILE_NAV",
  OPEN_MODAL: "OPEN_MODAL",
  CLOSE_MODAL: "CLOSE_MODAL",
  SHOW_TOAST: "SHOW_TOAST",
  CLOSE_TOAST: "CLOSE_TOAST",
  SHOW_COMMENTS: "SHOW_COMMENTS",
  CLOSE_COMMENTS: "CLOSE_COMMENTS",
  TOGGLE_PARTICIPANT: "TOGGLE_PARTICIPANT",
  TOGGLE_ALL_PARTICIPANTS: "TOGGLE_ALL_PARTICIPANTS",
  EDIT_CELL: "EDIT_CELL",
  START_SELECT: "START_SELECT",
  END_SELECT: "END_SELECT",
  SELECT: "SELECT",
  CLEAR_SELECTION: "CLEAR_SELECTION",
  SAVE_PERMISSION: "SAVE_PERMISSION"
}

module.exports = types;
