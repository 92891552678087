import React, { Component } from 'react';
import PT from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import _ from 'underscore';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { fetchDrivers, deleteDriver, toggleAcceptDriver, commentDriver } from 'modules/drivers/actions';
import { closeModal, showToast } from 'theme/actions';
import Header from 'theme/Header';
import Navbar from 'theme/Navbar';
import Table, { AcceptCommentCell, Row, ActionsCell, openModal, accept, comment } from 'theme/Table';
import Loader from 'theme/Loader';
import Modal from 'theme/Modal';
import DownloadButton from 'theme/DownloadButton';
import Footer from 'theme/Footer';
import roles from 'modules/drivers/roles';

import 'theme/common-views.sass';

const certsProps = [
  {key: 'sanepidBook', label: 'Książeczka sanepidowska'},
  {key: 'carrierTest', label: 'Badania na nosicielstwo'},
  {key: 'voluntaryContract', label: 'Umowa wolotariacka'},
  {key: 'contractStatement', label: 'Oświadczenie do umowy zlecenie'}
];

const HEADINGS = [
  {key: 'lastName', label: 'Nazwisko'},
  {key: 'firstName', label: 'Imię'},
  {key: 'from', label: 'Od'},
  {key: 'to', label: 'Do'},
  ...certsProps,
  {key: 'meta', label: ''}
]


class Drivers extends Component {

    componentDidMount() {
      this.props.fetchDrivers(this.props.match.params.projectId);
      this.roles = Object.keys(roles)
      .map( key => roles[key].filledBy)
      .reduce( (a, b) => a.concat(b));

      const rolesCount = this.roles
      .reduce( (sum, role) => ({...sum, [role]: (sum[role] || 0) + 1}), {});

      this.deleteRole = Object.keys(rolesCount)
      .reduce( (max, role) => rolesCount[max] >= rolesCount[role] ? max : role);
    }

    render() {
      const {user, isFetching, selectedDriver, showToast, loadingActions, closeModal, drivers, toggleAcceptDriver, commentDriver, activeProject} = this.props;
      const projectId = this.props.match.params.projectId;
      const deleteDriver = (...args) => () => {
        this.props.deleteDriver(...args)
        .then(closeModal)
        .catch(() => {
          const message = (
            <div>
              Nie udało się usunąć kucharza. Spróbuj ponownie później
            </div>
          )
          showToast({message, error: true});
        })
      };

      const editable = activeProject.state == 'Open';

      const canEdit = user.isAdmin || this.roles && this.roles.includes(user.role.name);
      const canDelete = editable && (user.isAdmin || this.deleteRole == user.role.name);

      return (
        <div className="List">
          <Header />
          <Navbar />
          <div className="List__container">

            <div className="List__heading">
              <h3>Kierowcy</h3>
              {canEdit && editable &&
                <Link
                  to={`/projects/${projectId}/staff/drivers/new`}
                  className="List__add"
                >Dodaj</Link>
              }
            </div>


            <div className="List__card">
              <Loader loading={isFetching} />
              <div className={cx("List__cardContainer", {blur: isFetching})}>
                {drivers.length == 0
                  ? <h4>Do tego projektu nie dodano jeszcze żadnych kierowców.</h4>
                  :
                  <Table
                    headings={HEADINGS}
                    accept={editable && accept(toggleAcceptDriver, projectId, HEADINGS, _.pluck(certsProps, 'key'))}
                    comment={comment(commentDriver, projectId)}
                    permissions={roles}
                  >
                    {drivers && drivers.map( driver => {
                      const loading = loadingActions[driver.id] && ['AcceptDriver', 'DeleteDriver'].includes(loadingActions[driver.id].name);
                      return (
                        <Row
                          key={driver.id}
                          loading={loading}
                        >
                          <AcceptCommentCell data={driver}>
                            {driver.lastName}
                          </AcceptCommentCell>
                          <AcceptCommentCell data={driver}>
                            {driver.firstName}
                          </AcceptCommentCell>
                          <AcceptCommentCell data={driver}>
                            {driver.from && moment(driver.from).format('DD.MM.YYYY')}
                          </AcceptCommentCell>
                          <AcceptCommentCell data={driver}>
                            {driver.to && moment(driver.to).format('DD.MM.YYYY')}
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={driver}
                            commentsPosition="left"
                          >
                            {driver.sanepidBook && driver.sanepidBook.fileId &&
                              <DownloadButton onlyIcon file={driver.sanepidBook} />
                            }
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={driver}
                            commentsPosition="left"
                          >
                            {driver.carrierTest && driver.carrierTest.fileId &&
                              <DownloadButton onlyIcon file={driver.carrierTest} />
                            }
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={driver}
                            commentsPosition="left"
                          >
                            {driver.voluntaryContract && driver.voluntaryContract.fileId &&
                              <DownloadButton onlyIcon file={driver.voluntaryContract} />
                            }
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={driver}
                            commentsPosition="left"
                          >
                            {driver.contractStatement && driver.contractStatement.fileId &&
                              <DownloadButton onlyIcon file={driver.contractStatement} />
                            }
                          </AcceptCommentCell>
                          <ActionsCell
                            data={driver}
                            del={canDelete && openModal('deleteDriver')}
                            editPath={`/projects/${projectId}/staff/drivers/${driver.id}`}
                            acceptable={editable && user.isAdmin}
                          />
                        </Row>
                      )
                    })}
                  </Table>
                }
              </div>
            </div>
          </div>
          <Modal
            className="List__modal"
            header={<h4>Uwaga!</h4>}
          >
            <p>
              Czy na pewno chcesz usunąć kierowcę <b>{selectedDriver.firstName} {selectedDriver.lastName}</b>?
            </p>
            <div className="List__modalActions">
              <button
                className="List__modalDiscard"
                onClick={closeModal}
              >Anuluj</button>
              <button
                className="List__modalOk"
                onClick={deleteDriver(projectId, selectedDriver.id)}
              >Usuń</button>
            </div>
          </Modal>
          <Footer />
        </div>
      )
    }
}

Drivers.propTypes = {
  drivers: PT.arrayOf(PT.shape({
    id: PT.string
  })),
  fetchDrivers: PT.func,
  isFetching: PT.bool,
  selectedDriver: PT.shape({
    id: PT.string,
    name: PT.string
  }),
  deleteDriver: PT.func.isRequired,
  closeModal: PT.func.isRequired,
  showToast: PT.func.isRequired
}

const mapStateToProps = ({data: {drivers, activeProject}, isFetching, modal, auth: {user}}) => ({
  drivers: drivers || [],
  isFetching: isFetching.global,
  selectedDriver: modal.payload || {},
  loadingActions: isFetching.objects,
  user,
  activeProject: activeProject || {}
})

export default withRouter(connect(
  mapStateToProps,
  { fetchDrivers, deleteDriver, showToast, closeModal, toggleAcceptDriver, commentDriver }
)(Drivers))
