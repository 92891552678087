import React from 'react';
import { connect } from 'react-redux';
import cx from 'classnames';
import { closeToast } from 'theme/actions';

import './Toast.sass'


const mapStateToProps = ({toast, noNetwork}) => ({...toast, noNetwork})

export default connect(
  mapStateToProps,
  {closeToast}
)(({visible, closeToast, className, error, message, noNetwork}) => (
  <div
    className={cx(
      'Toast',
      className,
      {visible: visible || noNetwork},
      {error: error || noNetwork}
    )}
    onClick={closeToast}
  >
    <div className="Toast__content">
      {noNetwork ? "Brak połączenia z internetem" : message}
    </div>
    <button
      className="Toast__close"
      onClick={closeToast}
    ><span className="fa fa-times" /></button>
  </div>
))
