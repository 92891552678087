export default {
  firstName: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  lastName: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  address: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  PESEL: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  unit: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  },
  educator: {
    filledBy: ['CampCommander'],
    acceptedBy: ['Admin', 'Region'],
    commentable: true
  }
}
// Region
// Office
// Banner
// Detachment
// CampCommander
