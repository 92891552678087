import api, { pickAllowedProps } from 'api';
import { pick } from 'underscore';
import { requestSuccess, createRequest, handleError } from 'actions/requests';
import roles from './roles';


export function fetchAccounting(projectId, opts = {loader: true}) {
  return (dispatch) => {
    opts && opts.loader && dispatch(createRequest({global: true, name: 'accounting'}));
    return api({
      method: 'get',
      url: `/projects/${projectId}/accounting`
    })
    .then((response) => {
			dispatch(requestSuccess(response, 'accounting', opts.id));
			return response.data;
		})
    .catch(handleError(dispatch));
  }
}


export function editAccounting({projectId}, accounting) {
  const { participantsCount, cardsCount} = accounting;
  const allowedProps = pickAllowedProps(roles);
  return (dispatch) => {
    dispatch(createRequest({name: 'accounting'}));
    const req = pick(accounting, allowedProps);
    return api({
      method: 'patch',
      url: `/projects/${projectId}/accounting`,
      data: {
        ...req,
        participantsCount: participantsCount && parseInt(participantsCount),
        cardsCount: cardsCount && parseInt(cardsCount)
      }
    })
    .then(() => {
      dispatch(fetchAccounting(projectId, {loader: false}));
    })
    .catch(handleError(dispatch));
  }
}

export function commentAccounting(projectId, prop, data) {
  return (dispatch) => {
    dispatch(createRequest({name: 'CommentAccounting'}));
    return api({
      method: 'post',
      url: `/projects/${projectId}/accounting/${prop}/comments`,
      data
    })
    .then( () => {
      dispatch(fetchAccounting(projectId, {loader: false}));
    })
    .catch(handleError(dispatch));
  }
}
