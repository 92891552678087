import React from 'react';
import {labels, fieldsTypes} from 'modules/summary/fields';

export default ({data}) => {
  const {unfilled, unapproved, files, elementType} = data;
  return (
    <div className="Summary__object">
      {fieldsTypes[elementType].unfilled &&
        <div className="Summary__objectList">
          {unfilled.length > 0 &&
            <h4>
              Niewypełnione pola
              <span>{unfilled && unfilled.length}</span>
            </h4>
          ||
            <h4>Wszystkie pola wypełnione</h4>
          }
          <div>
            {unfilled && unfilled.map(d => {
              return (
                <div className="Summary__item" key={d}>
                  {labels[d]}
                </div>
              )
            })}
          </div>
        </div>
      }
      {fieldsTypes[elementType].unapproved &&
        <div className="Summary__objectList">
          {unapproved.length > 0 &&
            <h4>
              Niezatwierdzone pola
              <span>{unapproved && unapproved.length}</span>
            </h4>
          ||
            <h4>Wszystkie pola zatwierdzone</h4>
          }
          <div>
            {unapproved && unapproved.map(d => (
              <div className="Summary__item" key={d}>
                {labels[d]}
              </div>
            ))}
          </div>
        </div>
      }
      {fieldsTypes[elementType].files &&
        <div className="Summary__objectList">
          <h4>
            Pliki
            <span>{files && files.length}</span>
          </h4>
          <div>
            {files && files.map(d => (
              <div className="Summary__item" key={d}>
                {d}
              </div>
            ))}
          </div>
        </div>
      }
    </div>
  )
}