import React                from 'react';
import cx                   from 'classnames';
import Loader               from 'theme/Loader';
import Table, {
  AcceptCommentCell,
  Row
}                           from 'theme/Table';
import educatorRoles        from 'modules/educators/roles';
import commandRoles         from 'modules/commands/roles';
import Checkbox             from './Checkbox';

const headings = [
  {key: 'name', label: 'Imię i nazwisko'},
  {key: 'accountedDocuments', label: 'Dokumenty kierownika/wychowawcy'},
  {key: 'accountedMedicalCertificate', label: 'Zaświadczenie lekarskie o braku przeciwwskazań do pełnienia funkcji'},
  {key: 'accountedKRKCertificate', label: 'Zaświadczenie z KRK'},
  {key: 'accountedDDStatement', label: 'Oświadczenie DD'},
  {key: 'accountedZMStatement', label: 'Oświadczenie ZM'}
]

export default ({fetching, educatorStaff, comment, edit, disabled}) => {
  const roles = {
    ...educatorRoles,
    ...commandRoles
  }
  return (
    <div className="List__card">
      <Loader loading={fetching} />
      <div className={cx("List__cardContainer", {blur: fetching})}>
        {educatorStaff.length == 0
          ? <h4>W tym projekcie nie ma komendanta i / lub wychowawców.</h4>
          :
          <Table
            headings={headings}
            permissions={roles}
          >
            {educatorStaff.map( s => {
              const onChange = edit(s.id, s.func, true);
              return (
                <Row
                  key={s.id}
                  comment={comment(s.func)}
                >
                  <AcceptCommentCell
                    className="Accounting__name"
                    hideIcons
                  >
                    {s.firstName} {s.lastName}
                  </AcceptCommentCell>
                  <AcceptCommentCell data={s}>
                    <Checkbox
                      checked={s.accountedDocuments}
                      onChange={onChange}
                      name="accountedDocuments"
                      disabled={disabled}
                    />
                  </AcceptCommentCell>
                  <AcceptCommentCell
                    data={s}
                    commentsPosition="left"
                  >
                    <Checkbox
                      checked={s.accountedMedicalCertificate}
                      onChange={onChange}
                      name="accountedMedicalCertificate"
                      disabled={disabled}
                    />
                  </AcceptCommentCell>
                  <AcceptCommentCell
                    data={s}
                    commentsPosition="left"
                  >
                    <Checkbox
                      checked={s.accountedKRKCertificate}
                      onChange={onChange}
                      name="accountedKRKCertificate"
                      disabled={disabled}
                    />
                  </AcceptCommentCell>
                  <AcceptCommentCell
                    data={s}
                    commentsPosition="left"
                  >
                    <Checkbox
                      checked={s.accountedDDStatement}
                      onChange={onChange}
                      name="accountedDDStatement"
                      disabled={disabled}
                    />
                  </AcceptCommentCell>
                  <AcceptCommentCell
                    data={s}
                    commentsPosition="left"
                  >
                    <Checkbox
                      checked={s.accountedZMStatement}
                      onChange={onChange}
                      name="accountedZMStatement"
                      disabled={disabled}
                    />
                  </AcceptCommentCell>
                </Row>
              )
            })}
          </Table>
        }
      </div>
    </div>
  )
}