import React from 'react';
import cx from 'classnames';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import moment from 'moment';
import { closeComments, showToast } from 'modules/theme/actions'

import './Comments.sass';

const CommentForm = reduxForm({
  form: 'AddCommentForm',
  validate: (values) => ({
    comment: !values.comment
  })
})(
  ({submitting, onSubmit, pristine, reset, handleSubmit, close, showToast}) => {
    const submit = (values) => {
      return onSubmit(values)
      .then(() => {
        reset();
      })
      .catch(() => showToast({
        message: <div>Nie udało się wysłać komentarza. Spróbuj ponownie później.</div>,
        error: true
      }))
    }
    const handleKeyDown = (e) => e.keyCode == 27 && close();

    return (
      <form onSubmit={handleSubmit(submit)}>
        <Field
          name="comment"
          placeholder="Wpisz komentarz..."
          type="text"
          component="input"
          autoFocus
          onKeyDown={handleKeyDown}
        />
        <button
          type="submit"
          disabled={submitting || pristine}
        >
          {submitting
            ? <span className="fa fa-spin fa-spinner" />
            : 'Skomentuj'
          }
        </button>
      </form>
    )
  }
)


export default connect(
  ({data: {activeProject}}) => ({disabled: activeProject && activeProject.state == 'Accounted'}),
  { closeComments, showToast }
)(({ comments, className, closeComments, position, comment, hideArrow, disabled }) => {
    const close = (e) => {
      e.stopPropagation();
      closeComments();
    }

    return (
      <div className={cx("Comments", className, position)}>
        <div
          className="Comments__overlay"
          onClick={close}
        />
        <div className={cx("Comments__content", position, {hideArrow})}>
          <button
            onClick={close}
            className="Comments__close"
          ><span className="fa fa-times" /></button>
          <div className="Comments__comments">
            <div>
              {(!comments || !comments.length) &&
                <div>
                  <h5>Nie ma jeszcze żadnych komentarzy.</h5>
                </div>
              }
              {comments && comments.map( (comment, i) => (
                <div
                  className="Comments__row"
                  key={i}
                >
                  <div className="Comments__author">
                    <span>{comment.author}</span>
                    <span>{moment.utc(comment.createdAt, 'YYYY-MM-DDTHH:mm:ss.SSS').local().format('HH:mm DD.MM.YYYY')}</span>
                  </div>
                  <div className="Comments__text">
                    {comment.comment}
                  </div>
                </div>
              ))}
            </div>
          </div>
          {!disabled && comment &&
            <div className="Comments__new">
              <CommentForm
                onSubmit={comment}
                close={closeComments}
                showToast={showToast}
              />
            </div>
          }
        </div>
      </div>
    )
  }
)