import React from 'react';
import cx from 'classnames';
import PT from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { toggleMobileNav } from 'theme/actions';

import './Navbar.sass';

const staffPaths = [
  {
    name: 'Komendant',
    path: '/commands'
  },
  {
    name: 'Kwatermistrz',
    path: '/quartermasters'
  },
  {
    name: 'Wychowawcy',
    path: '/educators'
  },
  {
    name: 'Opieka medyczna',
    path: '/healthCarers'
  },
  {
    name: 'Ratownicy wodni',
    path: '/lifeGuards'
  },
  {
    name: 'Kucharze',
    path: '/cooks'
  },
  {
    name: 'Kierowcy',
    path: '/drivers'
  },
  {
    name: 'Podsumowanie',
    path: '/summary'
  }
]

const accountingPaths = [
  {
    name: 'Miejsca',
    path: '/places'
  },
  {
    name: 'Jednostki',
    path: '/units'
  },
  {
    name: 'Uczestnicy',
    path: '/participants'
  },
  {
    name: 'Kadra',
    path: '/staff'
  },
  {
    name: 'Finanse',
    path: '/financial'
  },
  {
    name: 'Dokumenty',
    path: '/documents'
  },
  {
    name: 'Dropbox',
    path: '/dropbox'
  }
]

const paths = [
  {
    name: 'Miejsca',
    path: '/places',
  },
  {
    name: 'Jednostki',
    path: '/units',
  },
  {
    name: 'Kadra',
    path: '/staff',
    dropdown: staffPaths,
    clickPath: '/summary'
  },
  {
    name: 'Uczestnicy',
    path: '/participants',
  },
  {
    name: 'Zatwierdzanie',
    path: '/approval',
  },
  {
    name: 'Rozliczanie',
    path: '/accounting',
    dropdown: accountingPaths,
    clickPath: '/places',
    disabled: (project) => project.state == 'Open'
  },
  {
    name: 'Podsumowanie',
    path: '/summary'
  }
]

const Component = ({ match, mobileNavVisible, toggleMobileNav, history, activeProject}) => (
  <div
    className={cx("Navbar", {visible: mobileNavVisible})}
    onClick={() => toggleMobileNav(false)}
  >
    <div className="Navbar__content">
      {paths.map( ({name, path, disabled, dropdown, clickPath}, i) => (
        !dropdown
        ?
          <Link
            key={i}
            className={cx(
              "Navbar__item",
              {active: match.path.indexOf(path) != -1},
              {disabled: disabled && disabled(activeProject)}
            )}
            to={`/projects/${match.params.projectId}${path}`}
          >
            {name}
          </Link>
        :
          <div
            key={i}
            className={cx(
              "Navbar__item",
              {active: match.path.indexOf(path) != -1},
              {disabled: disabled && disabled(activeProject)}
            )}
            onClick={() => clickPath && history.push(`/projects/${match.params.projectId}${path}${clickPath}`)}
          >
            {name}
            <div className="Navbar__dropdown">
              {dropdown.map( ({name, path: dropdownPath}, i) => (
                <Link
                  key={i}
                  className={cx(
                    "Navbar__dropdownItem",
                    {active: location.href.indexOf(`/projects/${match.params.projectId}${path}${dropdownPath}`) != -1})}
                  to={`/projects/${match.params.projectId}${path}${dropdownPath}`}
                  onClick={(e) => e.stopPropagation()}
                >
                  {name}
                </Link>
              ))}
            </div>
          </div>
      ))}
    </div>
  </div>
)

Component.propTypes = {
  mobileNavVisible: PT.bool.isRequired,
  toggleMobileNav: PT.func.isRequired
}

const mapStateToProps = ({mobileNavVisible, data: {activeProject}}) => {
  return { mobileNavVisible, activeProject: activeProject || {} }
}

export default withRouter(connect(
  mapStateToProps,
  { toggleMobileNav }
)(Component));
