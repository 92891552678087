import React from 'react';
import cx from 'classnames';
import { apiBase } from 'api';

import './DownloadButton.sass'


export default ({className, file, onlyIcon, children}) => (
  <a
    className={cx('DownloadButton', className)}
    href={`${apiBase}/files/${file.id || file.fileId}/download`}
    onClick={(e) => e.stopPropagation()}
  >
    {children ||
      <span>
        <span className="fa fa-download" />
        {!onlyIcon &&
          'Pobierz'
        }
      </span>
    }
  </a>
)
