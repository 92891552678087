import api from 'api';
import { requestSuccess, createRequest, handleError } from 'actions/requests';
import { pick, pluck, uniq as _uniq, reject, isUndefined, values } from 'underscore';


export const fetchFile = (id) => {
  return (dispatch) => {
    dispatch(createRequest());
    return api({
      method: 'get',
      url: `/files/${id}`
    })
    .then((response) => dispatch(requestSuccess(response, 'file')))
    .catch(handleError(dispatch));
  }
}

export const fetchManyFiles = (ids) => {
  return api({
    method: 'post',
    url: `/files`,
    data: ids
  })
}

export const fetchFiles = (...ids) => {
  const uniq = _uniq(reject(ids, isUndefined));
  const promises = uniq.map( id =>
    api({
      method: 'get',
      url: `/files/${id}`
    })
  );
  return Promise.all(promises)
  .then( responses => pluck(responses, 'data'))
}

export const addFile = (file) => {
  if (!file) return null;
  if (!file.newFile) return Promise.resolve(file);
  let fd = new FormData();
  fd.append('file', file.newFile);
  return api({
    method: 'post',
    url: '/files',
    data: fd,
    headers: {
      'Content-Type': null
    }
  })
  .then(response => response.data);
}

export const matchFiles = ({data: obj}, fields, name) => {
  return (dispatch) => {
    return fetchFiles(...pluck(values(pick(obj, fields)), 'fileId'))
    .then( files => {
      const objWithFiles = {data: {
        ...obj,
        ...fields.reduce( (a, b) => ({
          ...a,
          [b]: {
            ...obj[b],
            ...files.find( file => file && obj[b] && file.id == obj[b].fileId)
          }
        }), {})
      }};
      dispatch(requestSuccess(objWithFiles, name))
    })
  }
}

export const saveFiles = (obj, fields) => {
  const filesReq = fields.map( prop => addFile(obj[prop]));
  return Promise.all(filesReq)
  .then( files => {
    return fields.reduce(
      (a, b, i) => {
        if(!files[i]) {
          if(obj[b] && obj[b].comment)
            return {...a, [b]: {comment: obj[b].comment}};
          else return a;
        }
        const mapped = {fileId: files[i] && files[i].id, comment: obj[b] && obj[b].comment}
        return {...a, [b]: mapped}
      },
      {}
    )
  })
}