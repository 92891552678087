import { first, without, keys, rest, omit } from 'underscore';
import api from 'api';
import { requestSuccess, createRequest, handleError } from 'actions/requests';
import { TOGGLE_PARTICIPANT, TOGGLE_ALL_PARTICIPANTS} from 'types';

export function clearCachedParticipants() {
  return (dispatch) => {
    dispatch(requestSuccess([], 'participants'));
  }
}

export function fetchParticipants(projectId, opts = {loader: true}) {
  return (dispatch) => {
    opts && opts.loader && dispatch(createRequest({global: true, name: 'FetchParticipants'}));
    return api({
      method: 'get',
      url: `/projects/${projectId}/participants`
    })
    .then((response) => {
			dispatch(requestSuccess(response, 'participants', opts.id));
			return response.data;
		})
    .catch(handleError(dispatch));
  }
}

function addParticipant(projectId, participant) {
  return api({
    method: 'post',
    url: `/projects/${projectId}/participants`,
    data: omit(participant, ['id', 'meta'])
  })
}

function addManyParticipants(projectId, participants) {  //undefined, []
  return new Promise( (resolve, reject) => {
    if(participants.length == 0) return resolve();
    else {
      const newParticipant = first(participants);
      addParticipant(projectId, newParticipant)
      .then(
        () => {
          addManyParticipants(projectId, rest(participants))
          .then(resolve);
        },
        reject
      )
      .catch(reject);
    }
  })
}

export function addParticipants({projectId}, participants = []) {
  return (dispatch) => {
    dispatch(createRequest({name: 'AddParticipant'}));
    return addManyParticipants(projectId, participants)
    .then(() => {
      dispatch(fetchParticipants(projectId))
      dispatch(requestSuccess({name: 'AddParticipant'}));
    })
  }
}

export function editParticipant({projectId, participantId}, participant) {
  return (dispatch) => {
    dispatch(createRequest({
      name: 'EditParticipant',
      id: participantId,
      prop: first(without(keys(participant), 'id'))
    }));
    return api({
      method: 'patch',
      url: `/projects/${projectId}/participants/${participantId}`,
      data: participant
    })
    .then(() => dispatch(fetchParticipants(projectId, {loader: false, id: participantId})))
    .catch(handleError(dispatch, participantId));
  }
}

export function deleteParticipant(projectId, id) {
  return (dispatch) => {
    dispatch(createRequest({name: 'DeleteParticipant', id}));
    return api({
      method: 'delete',
      url: `/projects/${projectId}/participants/${id}`
    })
    .then(() => dispatch(fetchParticipants(projectId, {loader: false, id})))
    .catch(handleError(dispatch, id));
  }
}

export function toggleAcceptParticipant(projectId, participant, state, ...props) {
  return (dispatch) => {
    dispatch(createRequest({name: 'AcceptParticipant', id: participant.id}));
    const prom = props.map( prop => prop != 'check' &&
    api({
      method: 'put',
      url: `/projects/${projectId}/participants/${participant.id}/${prop}/state`,
      data: {state}
    }))
    return Promise.all(prom)
    .then( () => dispatch(fetchParticipants(projectId, {loader: false, id: participant.id})))
    .catch(handleError(dispatch, participant.id));
  }
}

export function toggleParticipant(participant) {
  return {type: TOGGLE_PARTICIPANT, participant}
}

export function toggleAllParticipants(participants) {
  return {type: TOGGLE_ALL_PARTICIPANTS, participants}
}

export function commentParticipant(projectId, participant, prop, data) {
  return (dispatch) => {
    dispatch(createRequest({name: 'CommentParticipant', id: participant.id}));
    return api({
      method: 'post',
      url: `/projects/${projectId}/participants/${participant.id}/${prop}/comments`,
      data
    })
    .then( () => dispatch(fetchParticipants(projectId, {loader: false, id: participant.id})))
    .catch(handleError(dispatch, participant.id));
  }
}
