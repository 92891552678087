import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { reduxForm, Field, formValueSelector} from 'redux-form';
import Form, { Input, Datepicker, FileInput, Search, Group, SaveButton } from 'theme/Form';
import Loader from 'theme/Loader';
import roles from 'modules/commands/roles';

import 'react-datepicker/dist/react-datepicker.css';

const rankOptions = [
  {label: 'Ochotniczka / Młodzik', value: 'Och'},
  {label: 'Tropicielka / Wywiadowca', value: 'Trop'},
  {label: 'Samarytanka / Ćwik', value: 'Sam'},
  {label: 'Wędrowniczka / Harcerz Orli', value: 'Wed'},
  {label: 'Harcerka / Harcerz Rzeczypospoitej', value: 'HR'}
]

const instructorRankOptions = [
  {label: 'Przewodnik', value: 'Pwd'},
  {label: 'Podharcmistrz', value: 'Phm'},
  {label: 'Harcmistrz', value: 'Hm'},
  {label: 'Brak', value: 'None'}
]


export default withRouter(connect(
  (state) => ({formValues: formValueSelector('CommandForm')(state, 'leaderCertificate', 'internshipCertificate', 'phmInstructorCertificate')})
)(reduxForm({
  form: 'CommandForm',
  enableReinitialize: true
})(
  ({handleSubmit, pristine, submitting, match, loading, user, formValues, disabled}) => {
    const {projectId} = match.params;

    const filledInternshipCertificate = Boolean(formValues.internshipCertificate && (formValues.internshipCertificate.fileId || formValues.internshipCertificate.newFile));
    const filledLeaderCertificate = Boolean(formValues.leaderCertificate && (formValues.leaderCertificate.fileId || formValues.leaderCertificate.newFile));
    const filledPhmInstructorCertificate = Boolean(formValues.phmInstructorCertificate && (formValues.phmInstructorCertificate.fileId || formValues.phmInstructorCertificate.newFile));

    return (
      <Form
        className="Details__form"
        onSubmit={handleSubmit}
      >
        <div className="Details__row">
          <div className="Details__card">
            <Loader loading={loading} />
            <div className={cx("Details__cardContainer", {blur: loading})}>
              <Field
                component={Input}
                name="firstName"
                label="Imię"
                disabled={disabled || !user.isAdmin && !roles.firstName.filledBy.includes(user.role.name)}
              />
              <Field
                component={Input}
                name="lastName"
                label="Nazwisko"
                disabled={disabled || !user.isAdmin && !roles.lastName.filledBy.includes(user.role.name)}
              />
              <Field
                component={Input}
                name="PESEL"
                label="Pesel"
                disabled={disabled || !user.isAdmin && !roles.PESEL.filledBy.includes(user.role.name)}
              />
              <Field
                component={Input}
                name="phone"
                label="Telefon"
                disabled={disabled || !user.isAdmin && !roles.phone.filledBy.includes(user.role.name)}
              />
              <Field
                component={Input}
                name="email"
                label="Email"
                disabled={disabled || !user.isAdmin && !roles.email.filledBy.includes(user.role.name)}
              />
              <h4>Adres zamieszkania</h4>
              <Field
                component={Input}
                name="address"
                label="Ulica i numer domu"
                disabled={disabled || !user.isAdmin && !roles.address.filledBy.includes(user.role.name)}
              />
              <div className="Details__row">
                <Field
                  component={Input}
                  name="zipCode"
                  label="Kod pocztowy"
                  className="Details__code"
                  disabled={disabled || !user.isAdmin && !roles.zipCode.filledBy.includes(user.role.name)}
                />
                <Field
                  component={Input}
                  name="city"
                  label="Miejscowość"
                  disabled={disabled || !user.isAdmin && !roles.city.filledBy.includes(user.role.name)}
                />
              </div>
              <h4>Dane obozowe</h4>
              <Field
                component={Search}
                name="rank"
                label="Stopień harcerski"
                options={rankOptions}
                disabled={disabled || !user.isAdmin && !roles.rank.filledBy.includes(user.role.name)}
              />
              <Field
                component={Search}
                name="instructorRank"
                label="Stopień instruktorski"
                options={instructorRankOptions}
                disabled={disabled || !user.isAdmin && !roles.instructorRank.filledBy.includes(user.role.name)}
              />
              <Group>
                <div className="Details__row">
                  <span>Termin pełnienia funkcji</span>
                  <Field
                    component={Datepicker}
                    name="from"
                    className="Details__formDatepicker"
                    disabled={disabled || !user.isAdmin && !roles.from.filledBy.includes(user.role.name)}
                  />
                  <Field
                    component={Datepicker}
                    name="to"
                    className="Details__formDatepicker"
                    disabled={disabled || !user.isAdmin && !roles.to.filledBy.includes(user.role.name)}
                  />
                </div>
              </Group>
            </div>
          </div>
          <div className="Details__card">
            <Loader loading={loading} />
            <div className={cx("Details__cardContainer", {blur: loading})}>
              <Field
                component={FileInput}
                name="leaderCertificate"
                label="Zaświadczenie z kursu kierowników"
                info="Załącz plik"
                disabled={
                  disabled ||
                  !user.isAdmin && !roles.leaderCertificate.filledBy.includes(user.role.name) ||
                  filledPhmInstructorCertificate
                }
              />
              <Field
                component={FileInput}
                name="internshipCertificate"
                label="Zaświadczenie o 3-letnim stazu wychowawczym"
                info="Załącz plik"
                disabled={
                  disabled ||
                  !user.isAdmin && !roles.internshipCertificate.filledBy.includes(user.role.name) ||
                  filledPhmInstructorCertificate
                }
              />
              <Field
                component={FileInput}
                className="Details__phm"
                name="phmInstructorCertificate"
                label="Zaświadczenie o byciu czynnym instruktorem ZHR w stopniu conajmniej phm"
                info="Załącz plik"
                disabled={
                  disabled ||
                  !user.isAdmin && !roles.phmInstructorCertificate.filledBy.includes(user.role.name) ||
                  filledLeaderCertificate && filledInternshipCertificate
                }
              />
              <Field
                component={FileInput}
                name="KRKCertificate"
                label="Zaświadczenie z KRK"
                info="Załącz plik"
                disabled={disabled || !user.isAdmin && !roles.KRKCertificate.filledBy.includes(user.role.name)}
              />
              <Field
                component={FileInput}
                name="medicalCertificate"
                label="Zaświadczenie lekarskie o braku przeciwskazań do pełnienia funkcji"
                info="Załącz plik"
                disabled={disabled || !user.isAdmin && !roles.medicalCertificate.filledBy.includes(user.role.name)}
              />
              <Field
                component={FileInput}
                name="DDStatement"
                label="Oświadczenie DD"
                info="Załącz plik - oryginał należy donieść do Biura Okręgu/Obwodu"
                disabled={disabled || !user.isAdmin && !roles.DDStatement.filledBy.includes(user.role.name)}
              />
              <Field
                component={FileInput}
                name="ZMStatement"
                label="Oświadczenie ZM"
                info="Załącz plik - oryginał należy donieść do Biura Okręgu/Obwodu"
                disabled={disabled || !user.isAdmin && !roles.ZMStatement.filledBy.includes(user.role.name)}
              />
              <h5>Wypełnia Obwód/Okręg</h5>
              <Field
                component={Input}
                name="paidDues"
                label="Opłacone składki instruktorskie"
                type="checkbox"
                disabled={disabled || !user.isAdmin && !roles.paidDues.filledBy.includes(user.role.name)}
              />
            </div>
          </div>
          {!disabled &&
            <div className="Details__btn">
              <Link to={`/projects/${projectId}/staff/commands`}>Anuluj</Link>
              <SaveButton
                disabled={pristine || submitting}
                saving={submitting}
              >Zapisz</SaveButton>
            </div>
          }
        </div>
      </Form>
    )
  }))
)
