import React, { Component }           from 'react';
import PT                             from 'prop-types';
import cx                             from 'classnames';
import { connect }                    from 'react-redux'
import { withRouter }                 from 'react-router'
import { Link }                       from 'react-router-dom';
import {
        fetchUnits,
        deleteUnit,
        toggleAcceptUnit,
        commentUnit }                 from 'modules/units/actions';
import {
        closeModal,
        showToast }                   from 'theme/actions';
import Header                         from 'theme/Header';
import Navbar                         from 'theme/Navbar';
import Table,
       {
        AcceptCommentCell,
        Row,
        ActionsCell,
        openModal,
        accept,
        comment }                     from 'theme/Table';
import Loader                         from 'theme/Loader';
import Modal                          from 'theme/Modal';
import Footer                         from 'theme/Footer';
import DownloadButton                 from 'theme/DownloadButton';

import 'theme/common-views.sass';

import roles from 'modules/units/roles';


const HEADINGS = [
  {key: 'name', label: 'Nazwa'},
  {key: 'schedule', label: 'Plan pracy'},
  {key: 'dues', label: 'Zapłacone składki'},
  {key: 'financialRecord', label: 'Złożona dokumentacja finansowa'},
  {key: 'meta', label: ''}
]


class UnitsList extends Component {

    componentDidMount() {
      this.props.fetchUnits(this.props.match.params.projectId);

      this.roles = Object.keys(roles)
      .map( key => roles[key].filledBy)
      .reduce( (a, b) => a.concat(b));

      const rolesCount = this.roles
      .reduce( (sum, role) => ({...sum, [role]: (sum[role] || 0) + 1}), {});

      this.deleteRole = Object.keys(rolesCount)
      .reduce( (max, role) => rolesCount[max] >= rolesCount[role] ? max : role);
    }

    render() {
      const {
        isFetching,
        selectedUnit,
        showToast,
        loadingActions,
        closeModal,
        units,
        toggleAcceptUnit,
        commentUnit,
        user,
        activeProject
      } = this.props;
      const projectId = this.props.match.params.projectId;
      const deleteUnit = (...args) => () => {
        closeModal();
        this.props.deleteUnit(...args)
        .catch(() => {
          const message = (
            <div>
              Nie udało się usunąć jednostki. Spróbuj ponownie później
            </div>
          )
          showToast({message, error: true});
        })
      };
      const editable = activeProject.state == 'Open';

      const canEdit = user.isAdmin || this.roles && this.roles.includes(user.role.name);
      const canDelete = editable && (user.isAdmin || this.deleteRole == user.role.name);
      const canDoActions = canDelete || canEdit;
      const headings = canDoActions ? HEADINGS : HEADINGS.slice(0, -1);

      return (
        <div className="List">
          <Header />
          <Navbar />
          <div className="List__container">

            <div className="List__heading">
              <div>
                <h3>Jednostki</h3>
                <p>Jednostki uczestniczące w obozie</p>
              </div>
              {canEdit && editable && user.role.name != 'Office' &&
                <Link
                  to={`/projects/${projectId}/units/new`}
                  className="List__add"
                >Dodaj</Link>
              }
            </div>


            <div className="List__card">
              <Loader loading={isFetching} />
              <div className={cx("List__cardContainer", {blur: isFetching})}>
                {units.length == 0
                  ? <h4>Do tego projektu nie dodano jeszcze żadnych jednostek.</h4>
                  :
                  <Table
                    headings={headings}
                    accept={editable && accept(toggleAcceptUnit, projectId, HEADINGS)}
                    comment={comment(commentUnit, projectId)}
                    permissions={roles}
                  >
                    {units.map( unit => {
                      const loadingUnit = loadingActions[unit.id] && ['AcceptUnit', 'DeleteUnit'].includes(loadingActions[unit.id].name);
                      return (
                        <Row
                          key={unit.id}
                          loading={loadingUnit}
                        >
                          <AcceptCommentCell data={unit}>
                            {unit.name}
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={unit}
                            className="List__schedule"
                          >
                            {unit.schedule && unit.schedule.fileId &&
                              <DownloadButton file={unit.schedule} />
                            }
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={unit}
                            className="List__dues"
                          >
                            <span
                              className={cx(
                                "List__check",
                                {"fa fa-check": unit.dues}
                              )}
                            />
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={unit}
                            className="List__record"
                          >
                            <span
                              className={cx(
                                "List__check",
                                {"fa fa-check": unit.financialRecord}
                              )}
                            />
                          </AcceptCommentCell>
                          {canDoActions &&
                            <ActionsCell
                              data={unit}
                              del={canDelete && openModal('deleteUnit')}
                              editPath={canEdit && `/projects/${projectId}/units/${unit.id}`}
                              acceptable={editable && user.isAdmin}
                            />
                          }
                        </Row>
                      )
                    })}
                  </Table>
                }
              </div>
            </div>
          </div>
          <Modal
            className="List__modal"
            header={<h4>Uwaga!</h4>}
          >
            <p>
              Czy na pewno chcesz usunąć jednostkę <b>{selectedUnit.name}</b>?
            </p>
            <div className="List__modalActions">
              <button
                className="List__modalDiscard"
                onClick={closeModal}
              >Anuluj</button>
              <button
                className="List__modalOk"
                onClick={deleteUnit(projectId, selectedUnit.id)}
              >Usuń</button>
            </div>
          </Modal>
          <Footer />
        </div>
      )
    }
}

UnitsList.propTypes = {
  units: PT.arrayOf(PT.shape({
    id: PT.string
  })),
  fetchUnits: PT.func,
  isFetching: PT.bool,
  selectedUnit: PT.shape({
    id: PT.string,
    name: PT.string
  }),
  deleteUnit: PT.func.isRequired,
  closeModal: PT.func.isRequired,
  showToast: PT.func.isRequired
}

const mapStateToProps = ({data: {units, activeProject}, isFetching, modal, auth}) => ({
  units: units || [],
  isFetching: isFetching.global,
  loadingActions: isFetching.objects,
  selectedUnit: modal.payload || {},
  user: auth.user,
  activeProject: activeProject || {}
})

export default withRouter(connect(
  mapStateToProps,
  { fetchUnits, deleteUnit, showToast, closeModal, toggleAcceptUnit, commentUnit }
)(UnitsList))
