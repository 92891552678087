import api, { pickAllowedProps } from 'api';
import { push } from 'react-router-redux';
import { pick, omit, intersection } from 'underscore';
import { requestSuccess, createRequest, handleError } from 'actions/requests';
import { saveFiles, matchFiles } from 'modules/files/actions';
import roles from './roles';

const FILE_FIELDS = ['registration', 'leaseContract'];


export function fetchPlaces(projectId, opts = {loader: true}) {
  return (dispatch) => {
    opts && opts.loader && dispatch(createRequest({name: 'places'}));
    return api({
      method: 'get',
      url: `/projects/${projectId}/places`
    })
    .then((response) => {
			dispatch(requestSuccess(response, 'places', opts.id));
			return response.data;
		})
    .catch(handleError(dispatch));
  }
}

export function fetchPlace(projectId, id) {
  return (dispatch) => {
    dispatch(createRequest());
    return api({
      method: 'get',
      url: `/projects/${projectId}/places/${id}`
    })
    .then((response) => dispatch(matchFiles(response, FILE_FIELDS, 'place')))
    .catch(handleError(dispatch));
  }
}

export function addPlace({projectId}, place) {
  const allowedProps = pickAllowedProps(roles);
  return (dispatch) => {
    dispatch(createRequest());
    return saveFiles(place, intersection(FILE_FIELDS, allowedProps))
    .then( filesObj => {
      const req = pick(omit(place, FILE_FIELDS), allowedProps);
      return api({
        method: 'post',
        url: `/projects/${projectId}/places`,
        data: {
          ...req,
          attendees: req.attendees && parseInt(req.attendees),
          ...filesObj
        }
      })
      .then((response) => {
        dispatch(matchFiles(response, FILE_FIELDS, 'place'));
        dispatch(push(`/projects/${projectId}/places`));
      })
    })
    .catch(handleError(dispatch));
  }
}

export function editPlace({projectId, placeId, disableRedirect}, place,) {
  const allowedProps = pickAllowedProps(roles);
  return (dispatch) => {
    dispatch(createRequest({name: 'editPlace'}));
    return saveFiles(place, intersection(FILE_FIELDS, allowedProps))
    .then( filesObj => {
      const req = pick(omit(place, FILE_FIELDS), allowedProps);
      return api({
        method: 'patch',
        url: `/projects/${projectId}/places/${placeId}`,
        data: {
          ...req,
          attendees: req.attendees && parseInt(req.attendees),
          ...filesObj
        }
      })
      .then((response) => {
        dispatch(matchFiles(response, FILE_FIELDS, 'place'));
        !disableRedirect && dispatch(push(`/projects/${projectId}/places`));
      })
    })
    .catch(handleError(dispatch));
  }
}

export function deletePlace(projectId, id) {
  return (dispatch) => {
    dispatch(createRequest({name: 'DeletePlace', id}));
    return api({
      method: 'delete',
      url: `/projects/${projectId}/places/${id}`
    })
    .then(() => dispatch(fetchPlaces(projectId, {loader: false, id})))
    .catch(handleError(dispatch, id));
  }
}

export function toggleAcceptPlace(projectId, place, state, ...props) {
  return (dispatch) => {
    dispatch(createRequest({name: 'AcceptPlace', id: place.id}));
    const prom = props.map( prop => api({
      method: 'put',
      url: `/projects/${projectId}/places/${place.id}/${prop}/state`,
      data: {state}
    }))
    return Promise.all(prom)
    .then( () => {
      dispatch(fetchPlaces(projectId, {loader: false, id: place.id}));
    })
    .catch(handleError(dispatch, place.id));
  }
}

export function commentPlace(projectId, place, prop, data) {
  return (dispatch) => {
    dispatch(createRequest({name: 'CommentPlace', id: place.id}));
    return api({
      method: 'post',
      url: `/projects/${projectId}/places/${place.id}/${prop}/comments`,
      data
    })
    .then( () => {
      dispatch(fetchPlaces(projectId, {loader: false, id: place.id}));
    })
    .catch(handleError(dispatch, place.id));
  }
}
