import api, { pickAllowedProps } from 'api';
import { pick, omit, intersection } from 'underscore';
import { push } from 'react-router-redux';
import { requestSuccess, createRequest, handleError } from 'actions/requests';
import { saveFiles, matchFiles } from 'modules/files/actions';
import roles from './roles';

const FILE_FIELDS = [
  'sanepidBook',
  'carrierTest',
  'voluntaryContract',
  'practicePermit',
  'contractStatement'
];

export function fetchHealthCarers(projectId, opts = {loader: true}) {
  return (dispatch) => {
    opts && opts.loader && dispatch(createRequest({global: true, name: 'healtCarers'}));
    return api({
      method: 'get',
      url: `/projects/${projectId}/healthCarers`
    })
    .then((response) => {
			dispatch(requestSuccess(response, 'healthCarers', opts.id));
			return response.data;
		})
    .catch(handleError(dispatch));
  }
}

export function fetchHealthCarer(projectId, id) {
  return (dispatch) => {
    dispatch(createRequest());
    return api({
      method: 'get',
      url: `/projects/${projectId}/healthCarers/${id}`
    })
    .then((response) => dispatch(matchFiles(response, FILE_FIELDS, 'healthCarer')))
    .catch(handleError(dispatch));
  }
}

export function addHealthCarer({projectId}, healthCarer) {
  const allowedProps = pickAllowedProps(roles);
  return (dispatch) => {
    dispatch(createRequest());
    return saveFiles(healthCarer, intersection(FILE_FIELDS, allowedProps))
    .then( filesObj => {
      const req = pick(omit(healthCarer, FILE_FIELDS), allowedProps);
      return api({
        method: 'post',
        url: `/projects/${projectId}/healthCarers`,
        data: {
          ...req,
          ...filesObj
        }
      })
      .then((response) => {
        dispatch(matchFiles(response, FILE_FIELDS, 'healthCarer'));
        dispatch(push(`/projects/${projectId}/staff/healthCarers`));
      })
    })
    .catch(handleError(dispatch));
  }
}

export function editHealthCarer({projectId, id, disableRedirect}, healthCarer) {
  const allowedProps = pickAllowedProps(roles);
  return (dispatch) => {
    dispatch(createRequest({name: 'editHealthCarer'}));
    return saveFiles(healthCarer, intersection(FILE_FIELDS, allowedProps))
    .then( filesObj => {
      const req = pick(omit(healthCarer, FILE_FIELDS), allowedProps);
      return api({
        method: 'patch',
        url: `/projects/${projectId}/healthCarers/${id}`,
        data: {
          ...req,
          ...filesObj
        }
      })
      .then((response) => {
        dispatch(matchFiles(response, FILE_FIELDS, 'healthCarer'));
        !disableRedirect && dispatch(push(`/projects/${projectId}/staff/healthCarers`));
      })
    })
    .catch(handleError(dispatch));
  }
}

export function deleteHealthCarer(projectId, id) {
  return (dispatch) => {
    dispatch(createRequest({name: 'DeleteHealthCarer', id}));
    return api({
      method: 'delete',
      url: `/projects/${projectId}/healthCarers/${id}`
    })
    .then(() => dispatch(fetchHealthCarers(projectId, {loader: false, id})))
    .catch(handleError(dispatch, id));
  }
}

export function toggleAcceptHealthCarer(projectId, healthCarer, state, ...props) {
  return (dispatch) => {
    dispatch(createRequest({name: 'AcceptHealthCarer', id: healthCarer.id}));
    const prom = props.map( prop => api({
      method: 'put',
      url: `/projects/${projectId}/healthCarers/${healthCarer.id}/${prop}/state`,
      data: {state}
    }))
    return Promise.all(prom)
    .then( () => dispatch(fetchHealthCarers(projectId, {loader: false, id: healthCarer.id})))
    .catch(handleError(dispatch, healthCarer.id));
  }
}

export function commentHealthCarer(projectId, healthCarer, prop, data) {
  return (dispatch) => {
    dispatch(createRequest({name: 'CommentHealthCarer', id: healthCarer.id}));
    return api({
      method: 'post',
      url: `/projects/${projectId}/healthCarers/${healthCarer.id}/${prop}/comments`,
      data
    })
    .then( () => dispatch(fetchHealthCarers(projectId, {loader: false, id: healthCarer.id })))
    .catch(handleError(dispatch, healthCarer.id));
  }
}
