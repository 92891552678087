import React from 'react';
import { withRouter } from 'react-router';
import {Link} from 'react-router-dom';

import './Footer.sass';

const paths = [
  {
    name: 'Miejsca',
    path: '/places',
  },
  {
    name: 'Jednostki',
    path: '/units',
  },
  {
    name: 'Kadra',
    path: '/staff/summary',
  },
  {
    name: 'Uczestnicy',
    path: '/participants',
  },
  {
    name: 'Zatwierdzanie',
    path: '/approval',
  },
  {
    name: 'Rozliczanie',
    path: '/accounting/places',
  },
  {
    name: 'Podsumowanie',
    path: '/summary'
  }
]

const headerPaths = [
  {
    name: 'Projekty',
    path: '/projects',
  },
  {
    name: 'Użytkownicy',
    path: '/users',
  }
]

export default withRouter(
  ({match}) => {
    const {projectId} = match.params;
    return (
      <div className="Footer">
        <div className="Footer__content">
          <div className="Footer__nav">
            {headerPaths.map(p => (
              <Link to={p.path} key={p.path}>
                {p.name}
              </Link>
            ))}
          </div>
          {projectId &&
            <div className="Footer__nav">
              {paths.map(p => (
                <Link to={`/projects/${projectId}${p.path}`} key={p.path}>
                  {p.name}
                </Link>
              ))}
            </div>
          }
          <div className="Footer__logo zhr">
            <img src="/assets/halihaz_white.png" alt="Okręg Mazowiecki ZHR"/>
          </div>
          <div className="Footer__logo">
            <p>Projekt współfinansuje m. st. Warszawa</p>
            <img src="/assets/logo_wspolfinansowanie.png" alt="Projekt współfinansuje m. st. Warszawa" />
          </div>
        </div>
      </div>
    )
  }
)
