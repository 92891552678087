import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import { reduxForm, Field, formValueSelector} from 'redux-form';
import Form, { Input, Datepicker, FileInput, Group, SaveButton } from 'theme/Form';
import Loader from 'theme/Loader';
import roles from 'modules/lifeGuards/roles';

import 'react-datepicker/dist/react-datepicker.css';


export default withRouter(connect(
  (state) => ({formValues: formValueSelector('LifeGuardForm')(state, 'voluntaryContract', 'contractStatement')})
)(reduxForm({
  form: 'LifeGuardForm',
  enableReinitialize: true
})(
  ({handleSubmit, pristine, submitting, match, loading, user, formValues, disabled}) => {
    const {projectId} = match.params;

    const filledVoluntaryContract = Boolean(formValues.voluntaryContract && (formValues.voluntaryContract.fileId || formValues.voluntaryContract.newFile));
    const filledContractStatement = Boolean(formValues.contractStatement && (formValues.contractStatement.fileId || formValues.contractStatement.newFile));

    return (
      <Form
        className="Details__form"
        onSubmit={handleSubmit}
      >
        <div className="Details__row">
          <div className="Details__card">
            <Loader loading={loading} />
            <div className={cx("Details__cardContainer", {blur: loading})}>
              <Field
                component={Input}
                name="firstName"
                label="Imię"
                disabled={disabled || !user.isAdmin && !roles.firstName.filledBy.includes(user.role.name)}
              />
              <Field
                component={Input}
                name="lastName"
                label="Nazwisko"
                disabled={disabled || !user.isAdmin && !roles.lastName.filledBy.includes(user.role.name)}
              />
              <Group>
                <div className="Details__row">
                  <span>Termin pełnienia funkcji</span>
                  <Field
                    component={Datepicker}
                    name="from"
                    className="Details__formDatepicker"
                    disabled={disabled || !user.isAdmin && !roles.from.filledBy.includes(user.role.name)}
                  />
                  <Field
                    component={Datepicker}
                    name="to"
                    className="Details__formDatepicker"
                    disabled={disabled || !user.isAdmin && !roles.to.filledBy.includes(user.role.name)}
                  />
                </div>
              </Group>
            </div>
          </div>
          <div className="Details__card">
            <Loader loading={loading} />
            <div className={cx("Details__cardContainer", {blur: loading})}>
              <Field
                component={FileInput}
                name="woprId"
                label="Poświadczenie uprawnień ratownika wodnego (np. legitymacja ratownika WOPR)"
                disabled={disabled || !user.isAdmin && !roles.woprId.filledBy.includes(user.role.name)}
                info="Załącz plik"
              />
              <Field
                component={FileInput}
                name="voluntaryContract"
                label="Umowa wolontariacka"
                disabled={
                  disabled ||
                  !user.isAdmin && !roles.voluntaryContract.filledBy.includes(user.role.name) ||
                  filledContractStatement
                }
                info="Załącz plik"
              />
              <Field
                component={FileInput}
                name="contractStatement"
                label="Oświadczenie do umowy zlecenie"
                disabled={
                  disabled ||
                  !user.isAdmin && !roles.contractStatement.filledBy.includes(user.role.name) ||
                  filledVoluntaryContract
                }
                info="Załącz plik - oryginał należy donieść do Biura Okręgu"
              />
            </div>
          </div>
          {!disabled &&
            <div className="Details__btn">
              <Link to={`/projects/${projectId}/staff/lifeGuards`}>Anuluj</Link>
              <SaveButton
                disabled={pristine || submitting}
                saving={submitting}
              >Zapisz</SaveButton>
            </div>
          }
        </div>
      </Form>
    )
  }))
)
