import api from 'api';
import { push } from 'react-router-redux';
import { property, omit } from 'underscore';
import { requestSuccess, createRequest, handleError } from 'actions/requests';

export function fetchProjects(opts = {loader: true}) {
  return (dispatch) => {
    opts && opts.loader && dispatch(createRequest({global: true}));
    return api({
      method: 'get',
      url: '/projects'
    })
    .then((response) => {
			dispatch(requestSuccess(response, 'projects', opts.id));
			return response.data;
		})
    .catch(handleError(dispatch));
  }
}

export function fetchProject(id) {
  return (dispatch) => {
    dispatch(createRequest());
    return api({
      method: 'get',
      url: `/projects/${id}`
    })
    .then((response) => {
      dispatch(requestSuccess(response, 'project'));
      return response.data;
    })
    .catch(handleError(dispatch));
  }
}

export function addProject(project) {
  return (dispatch) => {
    dispatch(createRequest());
    return api({
      method: 'post',
      url: '/projects',
      data: project
    })
    .then((response) => {
      return api({
        method: 'patch',
        url: `/projects/${response.data.id}/description`,
        data: property('description')(project) || {}
      })
      .then( description => {
        dispatch(requestSuccess({...response, description}, 'project'))
        dispatch(push(`/projects`));
      })
    })
    .catch(handleError(dispatch));
  }
}

export function editProject(project, id) {
  return (dispatch) => {
    dispatch(createRequest());
    return api({
      method: 'patch',
      url: `/projects/${id}`,
      data: omit(project, 'description')
    })
    .then((general) => {
      if(general.data.state == 'Open') {
        api({
          method: 'patch',
          url: `/projects/${id}/description`,
          data: property('description')(project) || {}
        })
        .then((description) => {
          dispatch(requestSuccess({...general, description}, 'project'));
          dispatch(push(`/projects`));
        })
      }
      else {
        dispatch(requestSuccess(general, 'project'));
        dispatch(push(`/projects`));
      }
    })
    .catch(handleError(dispatch));
  }
}

export function changeState(state, id) {
  return (dispatch) => {
    dispatch(createRequest());
    return api({
      method: 'patch',
      url: `/projects/${id}`,
      data: {state}
    })
    .then(() => dispatch(fetchProject(id)))
    .catch(handleError(dispatch, id));
  }
}

export function deleteProject(id) {
  return (dispatch) => {
    dispatch(createRequest({id, name: 'deleteProject'}));
    return api({
      method: 'delete',
      url: `/projects/${id}`
    })
    .then(() => dispatch(fetchProjects({loader: false, id})))
    .catch(handleError(dispatch, id));
  }
}

export function toggleAcceptProject(projectId, state, ...props) {
  return (dispatch) => {
    dispatch(createRequest({name: 'AcceptProject', id: projectId}));
    const prom = props.map( prop => api({
      method: 'put',
      url: `/projects/${projectId}/description/${prop}/state`,
      data: {state}
    }))
    return Promise.all(prom)
    .then( () => dispatch(fetchProjects(projectId, {loader: false, id: projectId})))
    .catch(handleError(dispatch, projectId));
  }
}

export function commentProject(projectId, prop, data) {
  return (dispatch) => {
    dispatch(createRequest({name: 'CommentProject', id: projectId}));
    return api({
      method: 'post',
      url: `/projects/${projectId}/description/${prop}/comments`,
      data
    })
    .then( () => dispatch(fetchProjects(projectId, {loader: false, id: projectId})))
    .catch(handleError(dispatch, projectId));
  }
}
