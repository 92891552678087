import { push } from 'react-router-redux';
import api from 'api';
import { saveToken, deleteAuth, saveRole } from 'localStorage';
import { LOGGED_SUCCESSFULLY, LOGGED_OUT, LOGIN_ATTEMPT } from 'types';
import { requestSuccess, createRequest, handleError } from 'actions/requests';

export function loginSuccess(auth) {
  return { auth, type: LOGGED_SUCCESSFULLY };
}

export function logoutSuccess() {
  return {type: LOGGED_OUT};
}

export function logout(path = '/') {
  return (dispatch) => {
    dispatch(logoutSuccess(deleteAuth()));
    dispatch(push(path));
  }
}

export function login(data) {
  return (dispatch) => {
    dispatch({type: LOGIN_ATTEMPT});
    return api({
      method: 'post',
      url: '/auth/session',
      data
    })
    .then((response) => dispatch(loginSuccess(saveToken(response.data))))
    .catch(handleError(dispatch));
  }
}

export function requestReset(data) {
  return (dispatch) => {
    dispatch(createRequest());
    return api({
      method: 'post',
      url: '/auth/password',
      data: {
        ...data,
        resetUrl: `${location.origin}/reset`
      }
    })
    .then( () => dispatch(requestSuccess()))
    .catch(handleError(dispatch));
  }
}

export function reset(data) {
  return (dispatch) => {
    dispatch(createRequest());
    return api({
      method: 'patch',
      url: '/auth/password',
      data
    })
    .then( () => dispatch(requestSuccess()))
    .catch(handleError(dispatch));
  }
}

export function getRole(projectId, user) {
  return (dispatch) => {
    dispatch(createRequest({name: 'role', projectId}));
    return api({
      method: 'get',
      url: `/projects/${projectId}`
    })
    .then( response => {
      const userInProject = response.data.users && response.data.users.find(u => u.userId == user.id);
      const role = userInProject && userInProject.role;
      dispatch(loginSuccess(saveRole({name: role, projectId})));
      dispatch(requestSuccess(response, 'activeProject', projectId));
    })
    .catch(handleError(dispatch))
    .catch( error => {
      if(error && error.status == 404)
        dispatch(push("/projects"));
    });
  }
}
