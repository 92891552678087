import  React                       from 'react';
import * as d3                      from 'd3';

const RING_WIDTH  = 10;
const WIDTH       = 150;
const HEIGHT      = 150;
const RADIUS      = Math.min(WIDTH, HEIGHT / 2);

export default class extends React.Component {
  componentDidMount () {
    const {data} = this.props;
    this.drawChart(data, this.chart);
  }

  drawChart (data, elem) {
    d3.select(elem)
    .select('svg')
    .remove();

    const svg = d3.select(elem)
    .append('svg')
      .attr('width', WIDTH)
      .attr('height', HEIGHT)
    .append('g')
      .attr('transform', `translate(${WIDTH/2}, ${HEIGHT/2})`);

    const arc = d3.arc()
    .innerRadius(RADIUS - RING_WIDTH)
    .outerRadius(RADIUS);

    const pie = d3.pie()
    .value(d => d.value)
    .sort(null);

    const g = svg.selectAll('path')
      .data(pie(data))
      .enter()
    .append('g')
      .attr('class', 'Summary__arc')

    g.append('path')
      .attr('d', arc)
      .attr('class', d => d.data.key)

    const label = data.find(d => d.label);

    svg.append('text')
      .text(`${Math.round(label.value * 100)}%`)
      .attr('text-anchor', 'middle')
      .attr('class', 'Summary__chartLabel')
      .attr('dy', 10)

  }

  render () {
    const {data} = this.props;
    const {drawChart, chart} = this;
    data && drawChart(data, chart);
    return (
      <div className="Summary__chart" ref={c => this.chart = c} />
    )
  }
}
