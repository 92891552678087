import React, { Component }       from 'react';
import PT                         from 'prop-types';
import cx                         from 'classnames';
import { connect }                from 'react-redux'
import { withRouter }             from 'react-router'
import _                          from 'underscore';
import { Link }                   from 'react-router-dom';
import moment                     from 'moment';
import {
        fetchQuartermasters,
        deleteQuartermaster,
        toggleAcceptQuartermaster,
        commentQuartermaster }    from 'modules/quartermasters/actions';
import {closeModal, showToast }   from 'theme/actions';
import Header                     from 'theme/Header';
import Navbar                     from 'theme/Navbar';
import Table,
       {
        AcceptCommentCell,
        Row,
        ActionsCell,
        openModal,
        accept,
        comment }                 from 'theme/Table';
import Loader                     from 'theme/Loader';
import Modal                      from 'theme/Modal';
import DownloadButton             from 'theme/DownloadButton';
import Footer                     from 'theme/Footer';
import roles                      from 'modules/quartermasters/roles';


import 'theme/common-views.sass';


const rankOptions = {
  Och: 'Ochotniczka / Młodzik',
  Trop: 'Tropicielka / Wywiadowca',
  Sam: 'Samarytanka / Ćwik',
  Wed: 'Wędrowniczka / Harcerz Orli',
  HR: 'Harcerka / Harcerz Rzeczypospoitej'
}

const instructorRankOptions = {
  Pwd: 'Przew.',
  Phm: 'Podharcm.',
  Hm: 'Harcm.',
  None: 'Brak'
}

const certsProps = [
  {key: 'sanepidBook', label: 'Książeczka sanepidowska'},
  {key: 'carrierTest', label: 'Badania na nosicielstwo'},
  {key: 'voluntaryContract', label: 'Umowa wolotariacka'},
  {key: 'ZMStatement', label: 'Oświadczenie ZM'}
];

const HEADINGS = [
  {key: 'lastName', label: 'Nazwisko'},
  {key: 'firstName', label: 'Imię'},
  {key: 'from', label: 'Od'},
  {key: 'to', label: 'Do'},
  {key: 'rank', label: 'St. harcerski'},
  {key: 'instructorRank', label: 'St. instruktorski'},
  {key: 'paidDues', label: 'Opłacone składki'},
  ...certsProps,
  {key: 'meta', label: ''}
]


class StaffList extends Component {

    componentDidMount() {
      this.props.fetchQuartermasters(this.props.match.params.projectId);
      this.roles = Object.keys(roles)
      .map( key => roles[key].filledBy)
      .reduce( (a, b) => a.concat(b));

      const rolesCount = this.roles
      .reduce( (sum, role) => ({...sum, [role]: (sum[role] || 0) + 1}), {});

      this.deleteRole = Object.keys(rolesCount)
      .reduce( (max, role) => rolesCount[max] >= rolesCount[role] ? max : role);
    }

    render() {
      const {
        isFetching,
        selectedQuartermaster,
        showToast,
        loadingActions,
        closeModal,
        quartermasters,
        toggleAcceptQuartermaster,
        commentQuartermaster,
        user,
        activeProject
      } = this.props;
      const projectId = this.props.match.params.projectId;
      const deleteQuartermaster = (...args) => () => {
        this.props.deleteQuartermaster(...args)
        .then(closeModal)
        .catch(() => {
          const message = (
            <div>
              Nie udało się usunąć kwatermistrza. Spróbuj ponownie później
            </div>
          )
          showToast({message, error: true});
        })
      };

      const editable = activeProject.state == 'Open';

      const canEdit = user.isAdmin || this.roles && this.roles.includes(user.role.name);
      const canDelete = editable && (user.isAdmin || this.deleteRole == user.role.name);

      return (
        <div className="List">
          <Header />
          <Navbar />
          <div className="List__container">

            <div className="List__heading">
              <h3>Kwatermistrz</h3>
              {canEdit && editable && user.role.name != 'Office' &&
                <Link
                  to={`/projects/${projectId}/staff/quartermasters/new`}
                  className="List__add"
                >Dodaj</Link>
              }
            </div>


            <div className="List__card">
              <Loader loading={isFetching} />
              <div className={cx("List__cardContainer", {blur: isFetching})}>
                {quartermasters.length == 0
                  ? <h4>Do tego projektu nie dodano jeszcze żadnych kwatermistrzów.</h4>
                  :
                  <Table
                    headings={HEADINGS}
                    accept={editable && accept(toggleAcceptQuartermaster, projectId, HEADINGS, _.pluck(certsProps, 'key'))}
                    comment={comment(commentQuartermaster, projectId)}
                    permissions={roles}
                  >
                    {quartermasters && quartermasters.map( quartermaster => {
                      const loading = loadingActions[quartermaster.id] && ['AcceptQuartermaster', 'DeleteQuartermaster'].includes(loadingActions[quartermaster.id].name);
                      return (
                        <Row
                          key={quartermaster.id}
                          loading={loading}
                        >
                          <AcceptCommentCell data={quartermaster}>
                            {quartermaster.lastName}
                          </AcceptCommentCell>
                          <AcceptCommentCell data={quartermaster}>
                            {quartermaster.firstName}
                          </AcceptCommentCell>
                          <AcceptCommentCell data={quartermaster}>
                            {quartermaster.from && moment(quartermaster.from).format('DD.MM.YYYY')}
                          </AcceptCommentCell>
                          <AcceptCommentCell data={quartermaster}>
                            {quartermaster.to && moment(quartermaster.to).format('DD.MM.YYYY')}
                          </AcceptCommentCell>
                          <AcceptCommentCell data={quartermaster}>
                            {rankOptions[quartermaster.rank]}
                          </AcceptCommentCell>
                          <AcceptCommentCell data={quartermaster}>
                            {instructorRankOptions[quartermaster.instructorRank]}
                          </AcceptCommentCell>
                          <AcceptCommentCell className="List__dues" data={quartermaster}>
                            <span
                              className={cx(
                                "List__check",
                                {"fa fa-check": quartermaster.paidDues}
                              )}
                            />
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={quartermaster}
                            commentsPosition="left"
                          >
                            {quartermaster.sanepidBook && quartermaster.sanepidBook.fileId &&
                              <DownloadButton onlyIcon file={quartermaster.sanepidBook} />
                            }
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={quartermaster}
                            commentsPosition="left"
                          >
                            {quartermaster.carrierTest && quartermaster.carrierTest.fileId &&
                              <DownloadButton onlyIcon file={quartermaster.carrierTest} />
                            }
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={quartermaster}
                            commentsPosition="left"
                          >
                            {quartermaster.voluntaryContract && quartermaster.voluntaryContract.fileId &&
                              <DownloadButton onlyIcon file={quartermaster.voluntaryContract} />
                            }
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={quartermaster}
                            commentsPosition="left"
                          >
                            {quartermaster.ZMStatement && quartermaster.ZMStatement.fileId &&
                              <DownloadButton onlyIcon file={quartermaster.ZMStatement} />
                            }
                          </AcceptCommentCell>
                          <ActionsCell
                            data={quartermaster}
                            del={canDelete && openModal('deleteQuartermaster')}
                            editPath={`/projects/${projectId}/staff/quartermasters/${quartermaster.id}`}
                            acceptable={editable && user.isAdmin}
                          />
                        </Row>
                      )
                    })}
                  </Table>
                }
              </div>
            </div>
          </div>
          <Modal
            className="List__modal"
            header={<h4>Uwaga!</h4>}
          >
            <p>
              Czy na pewno chcesz usunąć kwatermistrza <b>{selectedQuartermaster.firstName} {selectedQuartermaster.lastName}</b>?
            </p>
            <div className="List__modalActions">
              <button
                className="List__modalDiscard"
                onClick={closeModal}
              >Anuluj</button>
              <button
                className="List__modalOk"
                onClick={deleteQuartermaster(projectId, selectedQuartermaster.id)}
              >Usuń</button>
            </div>
          </Modal>
          <Footer />
        </div>
      )
    }
}

StaffList.propTypes = {
  quartermasters: PT.arrayOf(PT.shape({
    id: PT.string
  })),
  fetchQuartermasters: PT.func,
  isFetching: PT.bool,
  selectedQuartermaster: PT.shape({
    id: PT.string,
    name: PT.string
  }),
  deleteQuartermaster: PT.func.isRequired,
  closeModal: PT.func.isRequired,
  showToast: PT.func.isRequired
}

const mapStateToProps = ({data: {quartermasters, activeProject}, isFetching, modal, auth}) => ({
  quartermasters: quartermasters || [],
  isFetching: isFetching.global,
  selectedQuartermaster: modal.payload || {},
  loadingActions: isFetching.objects,
  user: auth.user,
  activeProject: activeProject || {}
})

export default withRouter(connect(
  mapStateToProps,
  { fetchQuartermasters, deleteQuartermaster, showToast, closeModal, toggleAcceptQuartermaster, commentQuartermaster }
)(StaffList))
