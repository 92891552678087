import React from 'react';
import {render as ReactDOMRender} from 'react-dom';
import { AppContainer } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import App from 'modules/main'
import store from 'store';
import history from 'appHistory';
import 'moment/locale/pl';
import createRoutes from './routes';

const routes = createRoutes(store);

ReactDOMRender(
  <AppContainer>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App>
          {routes}
        </App>
      </ConnectedRouter>
    </Provider>
  </AppContainer>,
  document.getElementById('app')
);

if (module.hot) {
  module.hot.accept('./modules/main', () => {
    const NextApp = require('./modules/main').default; // eslint-disable-line global-require

    ReactDOMRender(
      <AppContainer>
        <Provider store={store}>
          <NextApp />
        </Provider>
      </AppContainer>,
      document.getElementById('app')
    );
  });
}
