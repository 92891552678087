import React, { Component } from 'react';
import PT from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import _ from 'underscore';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { fetchHealthCarers, deleteHealthCarer, toggleAcceptHealthCarer, commentHealthCarer } from 'modules/healthCarers/actions';
import { closeModal, showToast } from 'theme/actions';
import Header from 'theme/Header';
import Navbar from 'theme/Navbar';
import Table, { AcceptCommentCell, Row, ActionsCell, openModal, accept, comment } from 'theme/Table';
import Loader from 'theme/Loader';
import Modal from 'theme/Modal';
import DownloadButton from 'theme/DownloadButton';
import Footer from 'theme/Footer';
import roles from 'modules/healthCarers/roles';

import 'theme/common-views.sass';


const certsProps = [
  {key: 'sanepidBook', label: 'Książeczka sanepidowska'},
  {key: 'carrierTest', label: 'Badania na nosicielstwo'},
  {key: 'voluntaryContract', label: 'Umowa wolotariacka'},
  {key: 'practicePermit', label: 'Poświadcznia prawa wykonywania zawodu'},
  {key: 'contractStatement', label: 'Oświadczenie do umowy zlecenie'}
];

const HEADINGS = [
  {key: 'lastName', label: 'Nazwisko'},
  {key: 'firstName', label: 'Imię'},
  {key: 'from', label: 'Od'},
  {key: 'to', label: 'Do'},
  {key: 'type', label: 'Rodzaj'},
  ...certsProps,
  {key: 'meta', label: ''}
]

const TYPES = {
  Nurse: 'Pielęgniarka',
  Paramedic: 'Ratownik medyczny',
  Doctor: 'Lekarz'
}


class HealthCarers extends Component {

    componentDidMount() {
      this.props.fetchHealthCarers(this.props.match.params.projectId);
      this.roles = Object.keys(roles)
      .map( key => roles[key].filledBy)
      .reduce( (a, b) => a.concat(b));

      const rolesCount = this.roles
      .reduce( (sum, role) => ({...sum, [role]: (sum[role] || 0) + 1}), {});

      this.deleteRole = Object.keys(rolesCount)
      .reduce( (max, role) => rolesCount[max] >= rolesCount[role] ? max : role);
    }

    render() {
      const {isFetching, selectedHealthCarer, showToast, loadingActions, closeModal, healthCarers, toggleAcceptHealthCarer, commentHealthCarer, user, activeProject} = this.props;
      const projectId = this.props.match.params.projectId;
      const deleteHealthCarer = (...args) => () => {
        this.props.deleteHealthCarer(...args)
        .then(closeModal)
        .catch(() => {
          const message = (
            <div>
              Nie udało się usunąć opiekuna. Spróbuj ponownie później
            </div>
          )
          showToast({message, error: true});
        })
      };

      const editable = activeProject.state == 'Open';

      const canEdit = user.isAdmin || this.roles && this.roles.includes(user.role.name);
      const canDelete = editable && (user.isAdmin || this.deleteRole == user.role.name);

      return (
        <div className="List">
          <Header />
          <Navbar />
          <div className="List__container">

            <div className="List__heading">
              <h3>Opieka medyczna</h3>
              {canEdit && editable &&
                <Link
                  to={`/projects/${projectId}/staff/healthCarers/new`}
                  className="List__add"
                >Dodaj</Link>
              }
            </div>


            <div className="List__card">
              <Loader loading={isFetching} />
              <div className={cx("List__cardContainer", {blur: isFetching})}>
                {healthCarers.length == 0
                  ? <h4>Do tego projektu nie dodano jeszcze żadnych opiekunów.</h4>
                  :
                  <Table
                    headings={HEADINGS}
                    accept={editable && accept(toggleAcceptHealthCarer, projectId, HEADINGS, _.pluck(certsProps, 'key'))}
                    comment={comment(commentHealthCarer, projectId)}
                    permissions={roles}
                  >
                    {healthCarers && healthCarers.map( healthCarer => {
                      const loading = loadingActions[healthCarer.id] && ['AcceptHealthCarer', 'DeleteHealthCarer'].includes(loadingActions[healthCarer.id].name);
                      return (
                        <Row
                          key={healthCarer.id}
                          loading={loading}
                        >
                          <AcceptCommentCell data={healthCarer}>
                            {healthCarer.lastName}
                          </AcceptCommentCell>
                          <AcceptCommentCell data={healthCarer}>
                            {healthCarer.firstName}
                          </AcceptCommentCell>
                          <AcceptCommentCell data={healthCarer}>
                            {healthCarer.from && moment(healthCarer.from).format('DD.MM.YYYY')}
                          </AcceptCommentCell>
                          <AcceptCommentCell data={healthCarer}>
                            {healthCarer.to && moment(healthCarer.to).format('DD.MM.YYYY')}
                          </AcceptCommentCell>
                          <AcceptCommentCell data={healthCarer}>
                            {TYPES[healthCarer.type]}
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={healthCarer}
                            commentsPosition="left"
                          >
                            {healthCarer.sanepidBook && healthCarer.sanepidBook.fileId &&
                              <DownloadButton onlyIcon file={healthCarer.sanepidBook} />
                            }
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={healthCarer}
                            commentsPosition="left"
                          >
                            {healthCarer.carrierTest && healthCarer.carrierTest.fileId &&
                              <DownloadButton onlyIcon file={healthCarer.carrierTest} />
                            }
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={healthCarer}
                            commentsPosition="left"
                          >
                            {healthCarer.voluntaryContract && healthCarer.voluntaryContract.fileId &&
                              <DownloadButton onlyIcon file={healthCarer.voluntaryContract} />
                            }
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={healthCarer}
                            commentsPosition="left"
                          >
                            {healthCarer.practicePermit && healthCarer.practicePermit.fileId &&
                              <DownloadButton onlyIcon file={healthCarer.practicePermit} />
                            }
                          </AcceptCommentCell>
                          <AcceptCommentCell
                            data={healthCarer}
                            commentsPosition="left"
                          >
                            {healthCarer.contractStatement && healthCarer.contractStatement.fileId &&
                              <DownloadButton onlyIcon file={healthCarer.contractStatement} />
                            }
                          </AcceptCommentCell>
                          <ActionsCell
                            data={healthCarer}
                            del={canDelete && openModal('deleteHealthCarer')}
                            editPath={`/projects/${projectId}/staff/healthCarers/${healthCarer.id}`}
                            acceptable={editable && user.isAdmin}
                          />
                        </Row>
                      )
                    })}
                  </Table>
                }
              </div>
            </div>
          </div>
          <Modal
            className="List__modal"
            header={<h4>Uwaga!</h4>}
          >
            <p>
              Czy na pewno chcesz usunąć opiekuna <b>{selectedHealthCarer.firstName} {selectedHealthCarer.lastName}</b>?
            </p>
            <div className="List__modalActions">
              <button
                className="List__modalDiscard"
                onClick={closeModal}
              >Anuluj</button>
              <button
                className="List__modalOk"
                onClick={deleteHealthCarer(projectId, selectedHealthCarer.id)}
              >Usuń</button>
            </div>
          </Modal>
          <Footer />
        </div>
      )
    }
}

HealthCarers.propTypes = {
  healthCarers: PT.arrayOf(PT.shape({
    id: PT.string
  })),
  fetchHealthCarers: PT.func,
  isFetching: PT.bool,
  selectedHealthCarer: PT.shape({
    id: PT.string,
    name: PT.string
  }),
  deleteHealthCarer: PT.func.isRequired,
  closeModal: PT.func.isRequired,
  showToast: PT.func.isRequired
}

const mapStateToProps = ({data: {healthCarers, activeProject}, isFetching, modal, auth: {user}}) => ({
  healthCarers: healthCarers || [],
  isFetching: isFetching.global,
  selectedHealthCarer: modal.payload || {},
  loadingActions: isFetching.objects,
  user,
  activeProject: activeProject || {}
})

export default withRouter(connect(
  mapStateToProps,
  { fetchHealthCarers, deleteHealthCarer, showToast, closeModal, toggleAcceptHealthCarer, commentHealthCarer }
)(HealthCarers))
